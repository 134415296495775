import React from "react";
import styled from "styled-components";
import { lightThemeColors } from "../../assets/styles/colors";
import { ReactComponent as SuccessCheck } from "../../assets/icons/generic/SuccessCheck.svg";
import { ReactComponent as CheckIcon } from "../../assets/icons/generic/CheckSvg.svg";
import PrimaryButton from "../Generic/PrimaryButton";

const ListElement = ({ text, color }) => {
  return (
    <ListElementWrapper color={color}>
      <SuccessCheck />
      <p>{text}</p>
    </ListElementWrapper>
  );
};

const SubscriptionPlanCard = ({
  plan,
  handleUpgradeSubscription,
  currentPlan,
  handleContactSales,
}) => {
  let planDetails = {
    planName: "...",
    planRange: ".......",
    planMonthlyActiveUsers: [],
    price: ".....",
    planBg: lightThemeColors.planCardBg,
    titleColor: lightThemeColors.darkColorFaded,
    subtitleColor: "",
  };

  const ScaleUpPlan = {
    planName: "Scale Up",
    planRange:
      "For teams or organizations looking to implement deep linking, link attribution, and referral systems in their web and mobile apps",
    planMonthlyActiveUsers: [
      "Unlimited links",
      "Deep linking",
      "Attribution",
      "Referrals",
      "iOS, Android, Web SDKs",
      "Integration support",
    ],
    planFeatures: [
      "Unlimited MUA, charged monthly",
      "No charges until 50.000 MUA",
    ],
    price: "$2",
    priceDescription: "PER MONTH / 1.000 MUA",
    mua: "1.000",
    actionBtnText: "Get started for free",
  };

  const enterprisePlan = {
    planName: "Enterprise",
    planRange:
      "For companies requiring advanced integration, on-premise deployment, compliance, and support.",
    planMonthlyActiveUsers: [
      "Unlimited links",
      "Deep linking",
      "Attribution",
      "Referrals",
      "iOS, Android, Web SDKs",
      "Integration support",
      "Custom domains",
      "Priority support",
      "Custom deployment",
      "SSO",
    ],
    planFeatures: ["Unlimited MUA", "No charges until 50.000 MUA"],
    price: "Custom",
    priceDescription: "PURPOSE-BUILD FOR BUSINESS",
    mua: "1.000",
    actionBtnText: "Contact Sales",
    planBg: lightThemeColors.darkColor,
    titleColor: lightThemeColors.background,
    subtitleColor: "rgba(255, 255, 255, 0.7)",
  };

  const handleButtonClick = () => {
    switch (plan) {
      case "scale_up":
        handleUpgradeSubscription();
        break;
      case "enterprise":
        handleContactSales();
        break;
      default:
        break;
    }
  };

  switch (plan) {
    case "scale_up":
      planDetails = ScaleUpPlan;
      break;
    case "enterprise":
      planDetails = enterprisePlan;
      break;

    default:
      break;
  }

  return (
    <Card cardBg={planDetails.planBg}>
      <PlanTypeWrapper
        titlecolor={planDetails.titleColor}
        subtitleColor={planDetails.subtitleColor}
      >
        <h1>{planDetails.planName}</h1>
        <p>{planDetails.planRange}</p>
      </PlanTypeWrapper>
      <PriceWrapper>
        <Price color={planDetails.titleColor}>{planDetails.price}</Price>
        <PriceDescription color={planDetails.titleColor}>
          {planDetails.priceDescription}
        </PriceDescription>
      </PriceWrapper>
      <PrimaryButton
        bgVariant={currentPlan ? lightThemeColors.validGreen : null}
        styled={
          plan === "enterprise"
            ? {
              width: "100%",
              padding: "16px",
              background: lightThemeColors.background,
              color: lightThemeColors.darkColor,
              fontSize: "16px",
            }
            : {
              width: "100%",
              padding: "16px",
              fontSize: "16px",
            }
        }
        icon={currentPlan ? <CheckIcon /> : null}
        text={currentPlan ? "Current Plan" : planDetails.actionBtnText}
        onClick={currentPlan ? () => { } : () => handleButtonClick()}
      />
      <Group>
        <GroupTitle color={planDetails.titleColor}>
          Monthly active users
        </GroupTitle>
        <CheckList>
          {planDetails.planFeatures.map((item, index) => (
            <ListElement
              text={item}
              key={index}
              color={planDetails.titleColor}
            />
          ))}
        </CheckList>
      </Group>
      <Group>
        <GroupTitle color={planDetails.titleColor}>Features</GroupTitle>
        <CheckList>
          {planDetails.planMonthlyActiveUsers.map((item, index) => (
            <ListElement
              text={item}
              key={index}
              color={planDetails.titleColor}
            />
          ))}
        </CheckList>
      </Group>
    </Card>
  );
};

export default SubscriptionPlanCard;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  border-radius: 10px;
  padding: 30px 25px;
  background: ${(props) =>
    props.cardBg ? props.cardBg : lightThemeColors.planCardBg};
`;
const PlanTypeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  h1 {
    color: ${(props) =>
    props.titlecolor ? props.titlecolor : lightThemeColors.darkColor};
    font-size: 30px;
    font-weight: 800;
    line-height: 46px;
    letter-spacing: 0.02em;
    text-align: left;
  }
  p {
    color: ${(props) =>
    props.subtitleColor ? props.subtitleColor : "rgba(48, 44, 44, 0.7)"};
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.02em;
    text-align: left;
  }
`;
const Price = styled.h1`
  color: ${(props) =>
    props.color ? props.color : lightThemeColors.darkColorFaded};
  font-size: 40px;
  font-weight: 600;
  line-height: 50px;
  letter-spacing: 0.02em;
  text-align: left;
`;
const PriceDescription = styled.h1`
  color: ${(props) =>
    props.color ? props.color : lightThemeColors.darkColorFaded};
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: left;
`;
const CheckList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;
const ListElementWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  p {
    color: ${(props) =>
    props.color ? props.color : lightThemeColors.darkColor};
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
  }
  svg {
    color: ${(props) =>
    props.color ? props.color : lightThemeColors.darkColor};
    width: 20px;
    height: 10px;
  }
`;
const Group = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
const PriceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
const GroupTitle = styled.p`
  font-family: Lato;
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
  color: ${(props) =>
    props.color ? props.color : lightThemeColors.darkColorFaded};
`;
