import {
    createContext,
    useState,
    useContext,
    useCallback,
    useRef,
} from "react";
import { getEventsForOverviewAPICall, getEventsForPaymentScreenAPICall, getEventsForSearchParamsAPICall, getEventsSortedByParamsAPICall, getMetricsValuesForEventsAPICall } from "../api/events/eventsService";
import { getVisitorsForSearchParamsAPICall } from "../api/visitors/visitorsService";

export const EventContext = createContext({
    getEventsForSearchParams: () => { },
    getEventsSortedByParams: () => { },
    setEvents: () => { },
    events: [],
    eventsRef: [],
    getMetricValuesForEvents: () => { },
    getEventsForOverview: () => { },
    getEventsForPaymentScreen: () => { },
    getVisitorsForSearchParams: () => { }
});

const EventContextProvider = ({ children }) => {
    const [events, setEvents] = useState();
    const eventsRef = useRef([]);

    const getEventsForSearchParams = useCallback(async (projectId, data, onSuccess, onError) => {
        getEventsForSearchParamsAPICall(
            projectId,
            data,
            function (response) {
                onSuccess(response);
            },
            onError
        );
    });

    const getEventsSortedByParams = useCallback(async (project_id, data, onSuccess, onError) => {
        getEventsSortedByParamsAPICall(
            project_id,
            data,
            function (response) {
                onSuccess(response);
            },
            onError
        );
    });

    const getMetricValuesForEvents = useCallback(async (project_id, onSuccess, onError) => {
        getMetricsValuesForEventsAPICall(
            project_id,
            function (response) {
                onSuccess(response);
            },
            onError
        );
    });

    const getEventsForOverview = useCallback(async (project_id, data, onSuccess, onError) => {
        getEventsForOverviewAPICall(
            project_id,
            data,
            function (response) {
                onSuccess(response);
            },
            onError
        );
    });
    const getEventsForPaymentScreen = useCallback(async (project_id, data, onSuccess, onError) => {
        getEventsForPaymentScreenAPICall(
            project_id,
            data,
            function (response) {
                onSuccess(response);
            },
            onError
        );
    });

    const getVisitorsForSearchParams = useCallback(async (project_id, data, onSuccess, onError) => {
        getVisitorsForSearchParamsAPICall(
            project_id,
            data,
            function (response) {
                onSuccess(response);
            },
            onError
        );
    });



    return (
        <EventContext.Provider
            value={{
                getEventsForSearchParams,
                getEventsSortedByParams,
                setEvents,
                events,
                eventsRef,
                getMetricValuesForEvents,
                getEventsForOverview,
                getVisitorsForSearchParams,
                getEventsForPaymentScreen
            }}
        >
            {children}
        </EventContext.Provider>
    );
};

export const useEventContext = () => useContext(EventContext);

export default EventContextProvider;
