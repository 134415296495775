import React from "react";
import { ReactComponent as SquareArrow } from "../../assets/icons/generic/SquareArrow.svg";
import styled from "styled-components";
import { lightThemeColors } from "../../assets/styles/colors";

const LableWithArrow = ({
  text,
  reverse,
  arrowDirection,
  textInside,
  mlAuto,
}) => {
  let arrowStyle = {};

  switch (arrowDirection) {
    case "down":
      arrowStyle = {
        transform: "rotate(270deg)",
      };
      break;

    case "right":
      arrowStyle = {
        transform: "scaleX(-1)",
      };
      break;

    default:
      break;
  }

  return (
    <Wrapper reverse={reverse} mlAuto={mlAuto}>
      {reverse ? (
        <>
          <ArrowWrapper style={arrowStyle}>
            {textInside ? <p>{textInside}</p> : <SquareArrow />}
          </ArrowWrapper>
          <p>{text}</p>
        </>
      ) : (
        <>
          <p>{text}</p>
          <ArrowWrapper style={arrowStyle}>
            {textInside ? <p>{textInside}</p> : <SquareArrow />}
          </ArrowWrapper>
        </>
      )}
    </Wrapper>
  );
};

export default LableWithArrow;

const Wrapper = styled.div`
  display: flex;

  align-items: center;
  gap: 10px;
  margin-left: ${(props) => (props.mlAuto ? "auto" : "initial")};
  p {
    color: ${lightThemeColors.primaryColor};
    font-size: 13px;
    font-weight: 500;
    line-height: 18px;
    text-align: left;
  }
`;
const ArrowWrapper = styled.div`
  min-height: 40px;
  max-width: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
`;
