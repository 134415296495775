import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { lightThemeColors } from "../../assets/styles/colors";
import CustomInput from "./CustomInput";
import CustomBigSubmitButton from "./CustomBigSubmitButton";
import { useNavigate } from "react-router-dom";
import { useUserContext } from "../../context/useUserContext";
import { showGenericError } from "../../helper/Notifications";
import { isEmailValid } from "../../helper/ValidatorsHelper";

const SendLinkcard = ({ setIsLoading }) => {
  const { resetPassword } = useUserContext();
  const navigate = useNavigate();
  const initialValues = {
    email: "",
  };
  const [linkSent, setLinkSent] = useState(false);

  const [inputValues, setInputvalues] = useState(initialValues);
  const [emailValid, setEmailValid] = useState(true);
  const [sendLinkEnabled, setSendLinkEnable] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputvalues({
      ...inputValues,
      [name]: value,
    });
  };

  const handleSendLinkBtn = () => {
    if (!isEmailValid(inputValues.email)) {
      setEmailValid(false);
      // return;
    } else {
      setEmailValid(true);
    }

    if (isEmailValid(inputValues.email)) {
      setIsLoading(true);
      resetPassword(
        inputValues.email,
        () => {
          setIsLoading(false);
          setLinkSent(true);
        },
        () => {
          setIsLoading(false);
          showGenericError();
        }
      );
    }
  };

  const handleCreateAccountBtn = () => {
    navigate(`/register`);
  };

  useEffect(() => {
    if (inputValues.email.length > 0) {
      setSendLinkEnable(true);
    } else {
      setSendLinkEnable(false);
    }
  }, [inputValues]);

  return (
    <Card>
      <TitleContainer>
        <h2>{linkSent ? " Check your e-mail!" : "Reset password"}</h2>
      </TitleContainer>
      <Subtitle>
        {linkSent ? (
          <p>We have sent a password recover instructions to your email. </p>
        ) : (
          <p>
            Enter the email address associated with your account and we’ll send
            you a link to reset your password.
          </p>
        )}
      </Subtitle>
      <InputsWrapper>
        <CustomInput
          label={"Email"}
          placeholder="Enter your e-mail adress"
          name={"email"}
          value={inputValues.email}
          onChange={handleInputChange}
          error={!emailValid}
          errorText={"Invalid email"}
          readOnly={linkSent}
        />
      </InputsWrapper>
      {linkSent && (
        <ForgotPwd>
          <p onClick={handleSendLinkBtn}>Did not receive the e-mail ?</p>
        </ForgotPwd>
      )}

      <SbmitWrapper>
        <CustomBigSubmitButton
          disabled={!sendLinkEnabled}
          text={linkSent ? "Send Again" : "Send Link"}
          onClick={handleSendLinkBtn}
        />
        <p>
          Not a member?{" "}
          <span onClick={() => handleCreateAccountBtn()}>
            Create an account
          </span>
        </p>
      </SbmitWrapper>
    </Card>
  );
};

export default SendLinkcard;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  padding: 50px;
  align-items: center;
  width: 530px;
  background: ${lightThemeColors.background};
  min-height: 400px;
  gap: 35px;
  border-radius: 10px;
  margin-left: auto;
  margin-right: auto;
  z-index:1;
      @media(max-width:650px){
  width:100%;
  min-width: unset;
  }
`;
const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 20px;
  width: 100%;
  h1 {
    color: ${lightThemeColors.darkColorFaded};
    font-weight: 900;
    font-size: 25px;
  }
  h2 {
    color: ${lightThemeColors.darkColorFaded};
    font-weight: 700;
    font-size: 25px;
    line-height: 38px;
  }
`;
const InputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
`;

const ForgotPwd = styled.div`
  width: 100%;
  margin-top: -25px;
  p {
    width: fit-content;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    color: ${lightThemeColors.primaryColor};
    cursor: pointer;
  }
`;
const SbmitWrapper = styled.div`
  margin-top: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  p {
    color: ${lightThemeColors.darkColorFaded};
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
  }
  span {
    margin-left: 4px;
    font-weight: 700;
    color: ${lightThemeColors.primaryColor};
    cursor: pointer;
  }
`;
const Subtitle = styled.div`
  display: flex;
  margin-top: -10px;
  p {
    color: ${lightThemeColors.darkColor};
    opacity: 0.3;
    font-family: Lato;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
  }
`;
