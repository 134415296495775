import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { lightThemeColors } from "../../assets/styles/colors";

import {
  Outlet,
  createSearchParams,
  useLocation,
  useNavigate,
  useOutletContext,
  useSearchParams,
} from "react-router-dom";

import TestProdHeader from "../../components/Generic/TestProdHeader";
import SettingsOptionsLine from "./SettingsOptionsLine";
import { MEMBERS_OPTION, PLAN_OPTION } from "../../constants/OptionsConstants";

const SettingsPage = () => {
  const {
    projectType,
    setProjectType,
    setLoading,
    loading,
    addNewLinkModalOpen,
    setAddNewLinkModalOpen,
    createNewProjectModalOpen,
    setCreateNewProjectModalOpen,
    contactSaleModalOpen,
    setContactSaleModalOpen
  } = useOutletContext();

  const [selectedOption, setSelectedOption] = useState();
  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();
  const location = useLocation();

  const goTo = (path) => {
    navigate({
      pathname: path,
      search: createSearchParams(searchParams).toString(),
    });
  };

  const displaySelectedOption = () => {
    switch (selectedOption) {
      case PLAN_OPTION:
        goTo("/settings/subscription");
        break;
      case MEMBERS_OPTION:
        goTo("/settings/members");
        break;
      default:
        break;
    }
  };

  const initializeFromParams = () => {
    switch (location.pathname) {
      case "/settings/subscription":
        setSelectedOption(PLAN_OPTION);
        break;
      case "/settings/members":
        setSelectedOption(MEMBERS_OPTION);
        break;
      default:
        setSelectedOption(PLAN_OPTION);
        break;
    }
  };

  useEffect(() => {
    initializeFromParams();
  }, []);

  useEffect(() => {
    if (!selectedOption) {
      return;
    }
    displaySelectedOption();
  }, [selectedOption]);

  return (
    <Container>
      <TestProdHeader title={"Project settings"} hideTestSwitch />
      <PageContent>
        <SettingsOptionsLine
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
        />

        <Outlet
          context={{
            projectType,
            setProjectType,
            loading,
            setLoading,
            addNewLinkModalOpen,
            setAddNewLinkModalOpen,
            createNewProjectModalOpen,
            setCreateNewProjectModalOpen,
            contactSaleModalOpen,
            setContactSaleModalOpen
          }}
        />
      </PageContent>
    </Container>
  );
};

export default SettingsPage;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
overflow:hidden;
  h2 {
    width: 100%;
  }
`;

const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  overflow: auto;
  padding-bottom: 30px;
  padding: 0 50px;
  position: relative;
  height: 100%;
  padding-top: 20px;
`;
