import React, { useEffect, useRef, useState } from "react";
import { lightThemeColors } from "../../assets/styles/colors";
import styled, { keyframes } from "styled-components";
import { motion } from "framer-motion";
import { ReactComponent as CloseModalIcon } from "../../assets/icons/generic/CloseModalIcon.svg";
import CreateNewMessageModalOverview from "../../components/TemplaterComponents/CreateNewMessageModalOverview";
import CreateNewMessageModalContent from "../../components/TemplaterComponents/CreateNewmessageModalContent";
import CreateNewMessageModalDelivery from "../../components/TemplaterComponents/CreateNewMessageModalDelivery";
import { ALL_USERS, NEW_USERS, REMOVE_MESSAGE } from "../../constants/OptionsConstants";
import PrimaryButton from "../../components/Generic/PrimaryButton";
import { parseDeliverTo } from "../../helper/TemplateCreatorHelpers";
import { useNotificationsContext } from "../../context/useNotificationsContext";
import { showGenericError } from "../../helper/Notifications";
import { useOutletContext } from "react-router-dom";
import { useInstanceContext } from "../../context/useInstanceContext";
import SecondaryButton from "../../components/Generic/SecondaryButton";
import DeleteConfirmationChild from "../../components/Generic/DeleteConfirmationWithChild";

const CreateNewMessageModal = ({
  setIsModalOpen,
  handleAddContentButton,
  generatedHtml,
  setGeneratedHtml,
  setIsPreviewOpen,
  savedResultListRef,
  getMessages,
  savedContentListRef,
  readMessage,
  handleDownloadMessageContent,
  handleRemoveMessage,
  isRemoveConfirmOpen,
  setIsRemoveConfirmOpen,
  generatedHtmlPreview
}) => {
  const { createNotification } = useNotificationsContext();
  const { selectedProject } = useInstanceContext();
  const { setLoading } = useOutletContext();

  const ref = useRef();

  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [deliverTo, setDeliverTo] = useState(ALL_USERS);
  const [deliverPushNotification, setDeliverPushNotification] = useState("No");
  const [automaticallyDisplay, setAutomaticallyDisplay] = useState("No");
  const [selectedPlatforms, setSelectedPlatforms] = useState([]);


  const handleCreateNotification = (
    project_id,
    title,
    subtitle,
    platforms,
    deliverTo,
    auto_display,
    send_push,
    html
  ) => {
    let createObject = {
      project_id: project_id,
      title: title,
      subtitle: subtitle,
      platforms: platforms.map((item) => item.value),
      html: html,
    };



    if (deliverTo === ALL_USERS) {
      createObject.new_users = false;
      createObject.existing_users = true;

      if (send_push === "No") {
        createObject.send_push = false;
      } else {
        createObject.send_push = true;
      }

    }
    else {
      createObject.new_users = true;
      createObject.existing_users = false;
      createObject.send_push = false;
    }


    if (auto_display === "No") {
      createObject.auto_display = false;
    } else {
      createObject.auto_display = true;
    }

    setLoading(true);
    createNotification(
      createObject,
      (response) => {
        setLoading(false);
        setIsModalOpen(false);
        setGeneratedHtml(null);
        savedResultListRef.current = [];
        savedContentListRef.current = [];
        getMessages();
      },
      (error) => {
        showGenericError();
        setLoading(false);
      }
    );
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setGeneratedHtml(null);

    savedResultListRef.current = [];
    savedContentListRef.current = [];
  };

  useEffect(() => {
    if (readMessage) {
      setTitle(readMessage.title)
      setSubtitle(readMessage.subtitle)
      if (readMessage.send_push) {
        setDeliverPushNotification("Yes")
      } else {
        setDeliverPushNotification("No")
      }

      if (readMessage.auto_display) {
        setAutomaticallyDisplay("Yes")
      } else {
        setAutomaticallyDisplay("No")
      }
      setSelectedPlatforms(readMessage.target.platforms.map((item) => { return { label: item } }))
      handleDownloadMessageContent(readMessage)
    }
  }, [])

  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        handleCloseModal();
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, []);

  return (
    <FullscreenContainer>
      <InviteModal
        ref={ref}
        as={motion.div}
        initial={{ translateX: "100%" }}
        animate={{ translateX: "0" }}
        exit={{ translateX: "100%" }}
        transition={{ duration: 0.4 }}
      >
        <Line style={{ padding: "0 40px" }}>
          <HeadWrapper>
            {readMessage ?
              <h1>Message Details</h1> :
              <h1>Create New Message</h1>
            }
          </HeadWrapper>
          <CloneBtn
            onClick={() => handleCloseModal()}
            style={{ marginLeft: "auto" }}
          >
            <CloseModalIcon />
          </CloneBtn>
        </Line>
        <ConfigList>
          <CreateNewMessageModalOverview
            title={title}
            setTitle={setTitle}
            subtitle={subtitle}
            setSubtitle={setSubtitle}
            disabledActions={readMessage}
          />

          <CreateNewMessageModalContent
            handleAddContentButton={handleAddContentButton}
            generatedHtml={generatedHtml}
            setIsPreviewOpen={setIsPreviewOpen}
            readMessage={readMessage}
            generatedHtmlPreview={generatedHtmlPreview}
          />

          <CreateNewMessageModalDelivery
            deliverTo={deliverTo}
            setDeliverTo={setDeliverTo}
            deliverPushNotification={deliverPushNotification}
            setDeliverPushNotification={setDeliverPushNotification}
            automaticallyDisplay={automaticallyDisplay}
            setAutomaticallyDisplay={setAutomaticallyDisplay}
            selectedPlatforms={selectedPlatforms}
            setSelectedPlatforms={setSelectedPlatforms}
            readMessage={readMessage}
          />
          <Line>
            <Info>
              This message will be delivered to {parseDeliverTo(deliverTo)} one
              you press publish.
            </Info>
          </Line>
          {!readMessage &&
            <PrimaryButton
              disabled={
                !title ||
                !subtitle ||
                selectedPlatforms.length === 0 ||
                !generatedHtml
              }
              styled={{ width: "100%", padding: "12px 14px" }}
              text={"Publish message"}
              onClick={() =>
                handleCreateNotification(
                  selectedProject.id,
                  title,
                  subtitle,
                  selectedPlatforms,
                  deliverTo,
                  automaticallyDisplay,
                  deliverPushNotification,
                  generatedHtml
                )
              }
            />
          }

          {readMessage && readMessage.target.new_users &&

            <DeleteConfirmationChild
              action={handleRemoveMessage}
              item={readMessage}
              variant={REMOVE_MESSAGE}
              children={
                <SecondaryButton
                  variant={lightThemeColors.authentication.inputErrorColor}
                  styled={{ width: "100%", padding: "12px 14px" }}
                  text={"Delete message"}
                  onClick={() => setIsRemoveConfirmOpen(true)}
                />
              }
              open={isRemoveConfirmOpen}
              setOpen={setIsRemoveConfirmOpen}
            ></DeleteConfirmationChild>



          }
        </ConfigList>
      </InviteModal>
    </FullscreenContainer>
  );
};

export default CreateNewMessageModal;
const FullscreenContainer = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  z-index: 3;
`;
const expandAnimation = keyframes`
0% { transform:translateX(100%)}

100% {
   transform: translateX(0)
}

`;

const InviteModal = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background: ${lightThemeColors.background};
  max-width: 750px;
  width: 100%;
  height: 100%;
  margin-left: auto;
  padding: 40px 0;
  // animation: ${expandAnimation} 400ms linear;
  gap: 40px;
  overflow: auto;

  .control {
    border-radius: 5px;
  }
`;

const CloneBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  border: none;
  background: none;
  cursor: pointer;
`;
const HeadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  h1 {
    color: ${lightThemeColors.darkColorFaded};
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
  }
  p {
    color: ${lightThemeColors.darkColorFaded30};
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
  }
`;
const Line = styled.div`
  display: flex;
  gap: 30px;
  align-items: center;
`;
const ConfigList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow: auto;
  position: relative;
  height: 100%;
  padding: 0 40px 30px 40px;
`;
const LinkPathWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 60px;
  border-radius: 5px;
  overflow: hidden;
`;

const LinkWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 20px;
  background: ${lightThemeColors.sidebar.linkBg};
  width: 50%;
  min-height: 60px;
  p {
    overflow: hidden;
    white-space: wrap;
    text-overflow: ellipsis;
    color: ${lightThemeColors.primaryColor};
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
  }
`;
const PathWrapper = styled(LinkWrapper)`
  background: ${lightThemeColors.menuBackground};
  p {
    color: ${lightThemeColors.darkColor};
  }
`;
const Separator = styled.div`
  display: flex;
  height: 1px;
  min-height: 1px;
  width: 100%;
  background: ${lightThemeColors.darkColor};
  opacity: 0.1;
`;

const PathTitle = styled.div`
  h2 {
    font-size: 15px;
    font-weight: 600;
    color: ${lightThemeColors.darkColorFaded};
  }
`;

const Info = styled.div`
  background: ${lightThemeColors.sidebar.tagBg};
  color: ${lightThemeColors.darkColor};
  font-weight: 500;
  margin: auto;
  font-size: 14px;
  line-height: 21px;
  text-align: left;
  padding: 5px 10px;
  width: fit-content;
  border-radius: 4px;
`;
