import React, { useEffect } from "react";
import styled from "styled-components";
import PrimaryButton from "./PrimaryButton";
import { ReactComponent as PlusIcon } from "../../assets/icons/generic/plus.svg";


import { lightThemeColors } from "../../assets/styles/colors";
import { PRODUCTION, TEST } from "../../constants/OptionsConstants";
import { useOutletContext, useSearchParams } from "react-router-dom";
import AccountButton from "./AccountButton";
import { CustomSwitchSimple } from "./CustomSwitchSimple";

const TestProdHeader = ({ title, option, setOption, hideTestSwitch, customButton }) => {
  const { setAddNewLinkModalOpen } = useOutletContext();

  return (
    <HeaderWrapper>
      <TitleWrapper>
        <h1>{title}</h1>
      </TitleWrapper>
      <RightSide>
        {!hideTestSwitch && (
          <CustomSwitchSimple
            title={"Test mode"}
            defaultOption={PRODUCTION}
            secondaryOption={TEST}
            selectedOption={option}
            setSelectedOption={setOption}
          />
        )}
        <NewLinkContainer>
          {customButton ? customButton :
            <PrimaryButton
              icon={<PlusIcon />}
              text={"New Link"}
              onClick={() => setAddNewLinkModalOpen(true)}
            />
          }
        </NewLinkContainer>
        <AccountButton />
      </RightSide>
    </HeaderWrapper>
  );
};

export default TestProdHeader;
const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  background: ${lightThemeColors.background};
  padding: 15px 50px;
  gap: 30px;
  border-bottom: 1px solid ${lightThemeColors.menuBackground};
`;

const NewLinkContainer = styled.div``;

const RightSide = styled.div`
  margin-left: auto;
  display: flex;
  gap: 30px;
`;

const TitleWrapper = styled.div`
  padding: 0 0px;
  h1 {
    font-size: 25px;
    font-weight: 800;
    color: ${lightThemeColors.darkColor};
  }
`;
