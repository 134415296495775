export function openInNewTab(url) {
  if (url) {
    setTimeout(() => {
      window.open(url, "_blank");
    });
  } else {
    console.error("Invalid URL provided");
  }
}

export const mapKeyPairValues = (keyPairValue) => {
  if (!keyPairValue) {
    return [];
  }
  const newData = Object.keys(keyPairValue).map((element) => ({
    key: element,
    value: keyPairValue[element],
  }));
  return newData;
};

export const parseColorFromRgbaColorPicker = (color) => {
  let returnColor = `rgba(${color.r},${color.g},${color.b},${color.a})`;
  return returnColor;
};

export const parseSecondsInHoursMinutesSeconds = (value) => {
  if (!value) {
    return "00:00:00";
  }
  const newSessionTimeInSeconds = value;
  const hours = Math.floor(newSessionTimeInSeconds / 3600);
  const minutes = Math.floor((newSessionTimeInSeconds % 3600) / 60);
  const seconds = Math.floor(newSessionTimeInSeconds % 60);

  const formattedTime = `${String(hours).padStart(2, "0")}:${String(
    minutes
  ).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;

  return formattedTime;
};
