import React, { useEffect, useState } from "react";
import styled from "styled-components";
import SubcategoryTitleWrapper from "../../components/configurationComponents/SubcategoryTitleWrapper";
import { lightThemeColors } from "../../assets/styles/colors";
import InputFieldWithValidation from "../../components/Generic/InputFieldWithValidation";
import LableWithArrow from "../../components/configurationComponents/LableWithArrow";
import { CustomSwitchLeft } from "../../components/Generic/CustomSwitchLeft";
import { ReactComponent as AndroidIcon } from "../../assets/icons/generic/AndroidIconSmall.svg";
import LableWithOuterArrow from "../../components/configurationComponents/LabelWithOuterArrow";
import {
  ANDROID,
  CUSTOM,
  INFO,
  INSTORE,
  PHONE,
  SUCCESS,
  TABLET,
  WARNING,
} from "../../constants/OptionsConstants";
import SDKIntegrationCard from "../../components/configurationComponents/SDKIntegrationCard";
import { isCustomUrlValid } from "../../helper/ValidatorsHelper";



const AndroidSetup = ({
  setSubtitle,
  customUrl,
  setCustomUrl,
  androidStore,
  setAndroidStore,

  androidApp,
  setAndroidApp,

}) => {
  const noAppInstalledOptions = [
    {
      text: "Show App in Google Play",
      value: true,
    },
    {
      text: "Custom URL",
      value: false,
    },
  ];
  const handleCheckChange = (value) => {
    setAndroidStore(value);
  };


  var content = <></>;

  const noAppContent = (
    <>
      <SubcategoryContent>
        <InputFieldWithValidation
          title={"Android URL"}
          subtitle={
            "If left blank, users opening a generated link from Android will be redirected to this URL, otherwise, the fallback URL will be used."
          }
          style={{ maxWidth: "540px" }}
          placeholder={"Android Redirect URL"}
          inputName={"customUrl"}
          inputValue={customUrl}
          valid={customUrl && isCustomUrlValid(customUrl)}
          handleInputChange={(e) =>
            setCustomUrl(e.currentTarget.value.replace(/\s/g, ""))
          }
        />
      </SubcategoryContent>
    </>
  );

  const appContent = (
    <SubcategoryContent>
      <Line>
        <Title> Does the user have the app installed? </Title>
      </Line>
      <Line style={{ alignItems: "start" }}>
        <LableWithOuterArrow text={"Yes"} direction={"right"} />
        <ColumnGroup>
          <SDKIntegrationCard
            type={SUCCESS}
            text={"Redirected to the Android app"}
          />

          <UnsuccesfullContainer>
            <LableWithArrow
              reverse
              text={
                "If unsuccesful, go to the app store or use a custom link to download the app."
              }
              arrowDirection={"down"}
            />
          </UnsuccesfullContainer>
        </ColumnGroup>
      </Line>

      <Line style={{ alignItems: "start" }}>
        <LableWithOuterArrow text={"No"} direction={"right"} />
        <ColumnGroup style={{ marginTop: "8px" }}>
          <Line>
            {noAppInstalledOptions.map((item, index) => (
              <RadioGroup
                key={index}
                onClick={(e) => handleCheckChange(item.value)}
              >
                <input
                  type="radio"
                  checked={item.value === androidStore}
                  onChange={(e) => handleCheckChange(item.value)}
                />
                <span>{item.text}</span>
              </RadioGroup>
            ))}
          </Line>

          {androidStore === false && (
            <>
              <InputFieldWithValidation
                style={{ maxWidth: "540px" }}
                placeholder={
                  "Enter fallback URL for when the app is not installed..."
                }
                labelAction={() => alert("implement")}
                inputName={"customUrl"}
                inputValue={customUrl}
                valid={customUrl && isCustomUrlValid(customUrl)}
                handleInputChange={(e) =>
                  setCustomUrl(e.currentTarget.value.replace(/\s/g, ""))
                }
              />
            </>
          )}
          {androidStore == true && (
            <InfoText>
              If the app isn't installed, Google Play Store will open directly
              to your app for download. All data passed in the link will be
              available in the app once installed.
            </InfoText>
          )}
        </ColumnGroup>
      </Line>
    </SubcategoryContent>
  );

  const displayContent = () => {
    if (androidApp) {
      content = appContent;
    } else {
      content = noAppContent;
    }
    return content;
  };


  const handleSetHeaderSubtitle = (e) => {
    let element = document.getElementById("AndroidConfigId");
    const rect = element.getBoundingClientRect();

    if (rect.top - rect.height - 136 < 0) {
      setSubtitle("- Android Redirect");
    }
  };

  useEffect(() => {
    let element = document.getElementById("configListId");
    element.addEventListener("scroll", handleSetHeaderSubtitle);
    return () => {
      element.removeEventListener("scroll", handleSetHeaderSubtitle);
    };
  }, []);

  return (
    <Content>
      <Line>
        <SubcategoryContent>
          <SubcategoryTitleWrapper
            icon={<AndroidIcon />}
            text="Android Redirect"
            id={"AndroidConfigId"}
            colorType={"faded-dark"}
          />
        </SubcategoryContent>

        <Line style={{ marginLeft: "auto" }}>
          {!androidApp && (
            <LableWithArrow
              arrowDirection={"right"}
              text={"Toggle on to redirect to the Android mobile app"}
            />
          )}

          <CustomSwitchLeft
            text={androidApp ? "Android app" : null}
            selectedOption={androidApp}
            setSelectedOption={setAndroidApp}
          />
        </Line>
      </Line>

      {/* </StickyLine> */}
      {displayContent()}
    </Content>
  );
};

export default AndroidSetup;

const UnsuccesfullContainer = styled.div`
  margin-top: 20px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
`;
const Line = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;

const SubcategoryContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0 40px;
`;
const ColumnGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0px;
  width: 100%;
`;
const Title = styled.h1`
  color: ${lightThemeColors.primaryColor};
  margin-top: 10px;
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
  text-align: left;
`;
const RadioGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  cursor: pointer;
  span {
    color: ${lightThemeColors.darkColor};
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    margin-top: -5px;
  }

  margin-top: -3px;

  input {
  transform:scale(1.3);
    -webkit-transform: scale(1.3);
  transform-origin:10px 12px;

  }
`;

const InfoText = styled.p`
  color: ${lightThemeColors.inputSubtitleColor};
  margin-top: 15px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  max-width: 500px;
`;
