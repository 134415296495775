import React, { useEffect, useReducer, useRef, useState } from "react";
import styled, { keyframes } from "styled-components";
import { lightThemeColors } from "../../assets/styles/colors";

import { motion } from "framer-motion";
import SecondaryButton from "../../components/Generic/SecondaryButton";
import { useOutletContext } from "react-router-dom";

const RemoveAccountInfoModal = ({ isMoldaOpen, setIsModalOpen }) => {


    const ref = useRef();

    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            if (setIsModalOpen && ref.current && !ref.current.contains(e.target)) {
                setIsModalOpen(false);
            }
        };
        document.addEventListener("mousedown", checkIfClickedOutside);
        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, [setIsModalOpen]);

    useEffect(() => {
        const close = (e) => {
            if (e.keyCode === 27) {
                setIsModalOpen(false);
            }
        };
        window.addEventListener("keydown", close);
        return () => window.removeEventListener("keydown", close);
    }, []);

    return (
        <FullscreenContainer>
            <InviteModal
                ref={ref}
                as={motion.div}
                initial={{ translateX: "65vw" }}
                animate={{ translateX: "0" }}
                exit={{ translateX: "65vw" }}
                transition={{ duration: 0.4 }}
            >
                <Line>
                    <InfoText>
                        You have active projects associated with your account. Please either remove yourself from these projects or delete them before deleting your account. This ensures that all access is transferred to the appropriate individuals or that the projects are properly removed.
                    </InfoText>

                </Line>

                <Line style={{ marginLeft: "auto" }}>
                    <SecondaryButton
                        styled={{ padding: "12px", width: "100%" }}
                        text={"Cancel"}
                        variant={lightThemeColors.darkColorFaded30}
                        onClick={() => setIsModalOpen(false)}
                    />
                </Line>
            </InviteModal>
        </FullscreenContainer>
    );
};

export default RemoveAccountInfoModal;

const FullscreenContainer = styled.div`
  display: flex;
  align-items:center;
  justify-content:center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  z-index: 5;
`;

const InviteModal = styled.div`

  position: fixed;
  display: flex;
  flex-direction: column;
  background: ${lightThemeColors.background};
  max-width: 400px;
  max-height:600px;
margin-left:auto;
margin-right:auto;
width:100%;
  padding: 30px;
  border-radius:10px;
  gap: 20px;
  .control {
    border-radius: 5px;
  }
`;

const Line = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;


const InfoText = styled.p`
  color: ${lightThemeColors.darkColor};
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
`