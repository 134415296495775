import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { lightThemeColors } from "../../assets/styles/colors";
import { ReactComponent as BackIcon } from "../../assets/icons/generic/SquareArrow.svg";
import {
    BUTTON,
    CENTER,
    END,
    FIT,
    FULL,
    LARGE,
    MEDIUM,
    MENU,
    PILL,
    ROUND,
    SQUARE,
    START,
} from "../../constants/OptionsConstants";
import CustomInput from "../AuthenticationComponents/CustomInput";
import { parseColorFromRgbaColorPicker } from "../../helper/Utils";
import { SketchPicker } from "react-color";
import "react-contexify/dist/ReactContexify.css";
import { Item, Menu, useContextMenu } from "react-contexify";
import CustomEditorMenuButton from "./CustomEditorMenuButton";

const CustomEditorButtonMenu = ({
    setDisplayOption,
    setActiveElementIndex,
    resultList,
    activeElementIndex,
    handleItemChanges,
}) => {
    const currentButton = resultList.find(
        (item) => item.id === activeElementIndex
    );
    const currentProps = currentButton.content.props.state;

    const [size, setSize] = useState(currentProps?.size);
    const [position, setPosition] = useState(currentProps?.position);
    const [form, setForm] = useState(currentProps?.form);
    const [background, setBackground] = useState(currentProps?.background);
    const [textColor, setTextColor] = useState(currentProps?.textColor);
    const [paddingTop, setPaddingTop] = useState(currentProps?.paddingTop);
    const [paddingBottom, setPaddingBottom] = useState(
        currentProps?.paddingBottom
    );
    const [paddingLeft, setPaddingLeft] = useState(currentProps?.paddingLeft);
    const [paddingRight, setPaddingRight] = useState(currentProps?.paddingRight);
    const [text, setText] = useState(currentProps?.text);
    const [link, setLink] = useState(currentProps?.link);
    const [showItem, setShowItem] = useState("");

    const COLORS_MENU = "colors-menu";

    const { show } = useContextMenu({
        id: COLORS_MENU,
    });

    const labelStyle = {
        fontWeight: 500,
        color: lightThemeColors.inputSubtitleColor,

    }

    function displayMenu(e, showItem) {
        // put whatever custom logic you need
        // you can even decide to not display the Menu
        setShowItem(showItem);
        show({
            event: e,
        });
    }

    const handleBack = () => {
        setDisplayOption(MENU);
        setActiveElementIndex(null);
    };

    useEffect(() => {
        if (!currentButton || !currentProps || currentButton.type !== "Button") {
            return;
        }
        setSize(currentProps.size);
        setPosition(currentProps.position);
        setForm(currentProps.form);
        setTextColor(currentProps.textColor);
        setBackground(currentProps.background);
        setPaddingRight(currentProps.paddingRight);
        setPaddingLeft(currentProps.paddingLeft);
        setPaddingTop(currentProps.paddingTop);
        setPaddingBottom(currentProps.paddingBottom);
        setText(currentProps.text);
        setLink(currentProps.link);
    }, [currentButton.id]);

    useEffect(() => {
        handleItemChanges(currentButton.id, "size", size);
    }, [size]);

    useEffect(() => {
        handleItemChanges(currentButton.id, "position", position);
    }, [position]);

    useEffect(() => {
        handleItemChanges(currentButton.id, "form", form);
    }, [form]);

    useEffect(() => {
        handleItemChanges(currentButton.id, "background", background);
    }, [background]);

    useEffect(() => {
        handleItemChanges(currentButton.id, "textColor", textColor);
    }, [textColor]);

    useEffect(() => {
        handleItemChanges(currentButton.id, "paddingTop", paddingTop);
    }, [paddingTop]);

    useEffect(() => {
        handleItemChanges(currentButton.id, "paddingBottom", paddingBottom);
    }, [paddingBottom]);

    useEffect(() => {
        handleItemChanges(currentButton.id, "paddingLeft", paddingLeft);
    }, [paddingLeft]);

    useEffect(() => {
        handleItemChanges(currentButton.id, "paddingRight", paddingRight);
    }, [paddingRight]);

    useEffect(() => {
        handleItemChanges(currentButton.id, "text", text);
    }, [text]);

    useEffect(() => {
        handleItemChanges(currentButton.id, "link", link);
    }, [link]);

    return (
        <MenuCard>
            <MenuContent>
                <Line style={{ position: "relative" }}>
                    <CloseBtn onClick={() => handleBack()} >
                        <BackIcon />
                        Back
                    </CloseBtn>
                    <HeadWrapper>
                        <h1>{BUTTON}</h1>
                    </HeadWrapper>
                </Line>

                <Group>
                    <GroupDescription>Type</GroupDescription>
                    <Line>
                        <CustomEditorMenuButton
                            onClick={() => setForm(SQUARE)}
                            selected={form === SQUARE}
                        >
                            Square
                        </CustomEditorMenuButton>
                        <CustomEditorMenuButton
                            onClick={() => setForm(ROUND)}
                            selected={form === ROUND}
                        >
                            Round
                        </CustomEditorMenuButton>
                        <CustomEditorMenuButton
                            onClick={() => setForm(PILL)}
                            selected={form === PILL}
                        >
                            Pill
                        </CustomEditorMenuButton>
                    </Line>
                </Group>
                <Group>
                    <GroupDescription>Position</GroupDescription>
                    <Line>
                        <CustomEditorMenuButton
                            onClick={() => setPosition(START)}
                            selected={position === START}
                        >
                            Left
                        </CustomEditorMenuButton>
                        <CustomEditorMenuButton
                            onClick={() => setPosition(CENTER)}
                            selected={position === CENTER}
                        >
                            Center
                        </CustomEditorMenuButton>
                        <CustomEditorMenuButton
                            onClick={() => setPosition(END)}
                            selected={position === END}
                        >
                            Right
                        </CustomEditorMenuButton>
                    </Line>
                </Group>
                <Group>
                    <GroupDescription>Size</GroupDescription>
                    <Line>
                        <CustomEditorMenuButton
                            onClick={() => setSize(FIT)}
                            selected={size === FIT}
                        >
                            Fit
                        </CustomEditorMenuButton>
                        <CustomEditorMenuButton
                            selected={size === MEDIUM}
                            onClick={() => setSize(MEDIUM)}
                        >
                            Medium
                        </CustomEditorMenuButton>
                        <CustomEditorMenuButton
                            selected={size === FULL}
                            onClick={() => setSize(FULL)}
                        >
                            Full
                        </CustomEditorMenuButton>
                    </Line>
                </Group>

                <Line>
                    <CustomInput
                        labelStyle={labelStyle}
                        style={{ padding: "10px" }}
                        label={"Text"}
                        value={text}
                        onChange={(e) => setText(e.currentTarget.value)}
                        placeholder={"Placeholder"}
                    />
                </Line>

                <Line>
                    <CustomInput
                        labelStyle={labelStyle}
                        style={{ padding: "10px" }}
                        label={"Link"}
                        value={link}
                        onChange={(e) => setLink(e.currentTarget.value)}
                        placeholder={"Enter your link here"}
                    />
                </Line>
                <Line>
                    <Group>
                        <GroupDescription style={{ padding: 0 }}>
                            Text Color
                        </GroupDescription>
                        <ColorPickerContainer onClick={(e) => displayMenu(e, "textColor")}>
                            <ColorPickerInside style={{ background: textColor }} />
                        </ColorPickerContainer>
                    </Group>

                    <Group>
                        <GroupDescription style={{ padding: 0 }}>
                            BackgroundColor
                        </GroupDescription>

                        <ColorPickerContainer onClick={(e) => displayMenu(e, "background")}>
                            <ColorPickerInside style={{ background: background }} />
                        </ColorPickerContainer>
                    </Group>
                </Line>

                <Group>
                    <GroupDescription>Padding</GroupDescription>
                    <Line>
                        <CustomInput
                            labelStyle={labelStyle}
                            style={{ padding: "10px" }}
                            label={"Top"}
                            type={"number"}
                            value={paddingTop}
                            onChange={(e) => setPaddingTop(e.currentTarget.value)}
                        />

                        <CustomInput
                            labelStyle={labelStyle}
                            style={{ padding: "10px" }}
                            label={"Bottom"}
                            type={"number"}
                            value={paddingBottom}
                            onChange={(e) => setPaddingBottom(e.currentTarget.value)}
                        />
                    </Line>

                    <Line>
                        <CustomInput
                            style={{ padding: "10px" }}
                            labelStyle={labelStyle}
                            label={"Left"}
                            type={"number"}
                            value={paddingLeft}
                            onChange={(e) => setPaddingLeft(e.currentTarget.value)}
                        />

                        <CustomInput
                            labelStyle={labelStyle}
                            style={{ padding: "10px" }}
                            label={"Right"}
                            type={"number"}
                            value={paddingRight}
                            onChange={(e) => setPaddingRight(e.currentTarget.value)}
                        />
                    </Line>
                </Group>
            </MenuContent>

            <Menu id={COLORS_MENU}>
                {showItem === "textColor" && (
                    <Item closeOnClick={false} style={{ padding: 0 }}>
                        <SketchPicker
                            width="300px"
                            color={textColor}
                            onChange={(color) =>
                                setTextColor(parseColorFromRgbaColorPicker(color.rgb))
                            }
                        />
                    </Item>
                )}
                {showItem === "background" && (
                    <Item closeOnClick={false} style={{ padding: 0 }} data>
                        <SketchPicker
                            width="300px"
                            color={background}
                            onChange={(color) =>
                                setBackground(parseColorFromRgbaColorPicker(color.rgb))
                            }
                        />
                    </Item>
                )}
            </Menu>
        </MenuCard>
    );
};

export default CustomEditorButtonMenu;

const MenuCard = styled.div`
  max-width: 600px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  top: 0;
  background: ${lightThemeColors.menuBackground};
  z-index: 1;
  position: relative;

  .contexify {
    padding: 0 !important;
  }
  .contexify_itemContent {
    padding: 0 !important;
  }
  .contexify_itemContent:hover {
    background-color: transparent !important;
  }
`;

const HeadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: auto;
  h1 {
    color: ${lightThemeColors.darkColorFaded};
    font-size: 20px;
    font-weight: 700;
    line-height: 37.5px;
  }
  p {
    color: ${lightThemeColors.darkColorFaded30};
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
  }
`;

const CloseBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  border: none;
  background: none;
  cursor: pointer;
  gap:10px;
  color:${lightThemeColors.primaryColor};
  font-size:18px;
  line-height:22px;
  font-weight:500;
  position:absolute;
  top:calc(50% - 11px);
  left:40;
`;

const Line = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 0 40px;
  width: 100%;
`;
const Group = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`;

const MenuContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  gap: 20px;
  padding: 40px 0;
`;

const GroupDescription = styled.p`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: ${lightThemeColors.inputSubtitleColor};
  padding: 0px 40px;
  padding-top: 20px;
`;
const ColorPickerContainer = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50px;
  border: none;
  position: relative;
  cursor: pointer;
  padding: 3px;
  background: ${lightThemeColors.background};
  box-shadow: 0 2px 5px rgba(43, 58, 84, 0.2);
  margin-left: 5px;
`;

const ColorPickerInside = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  border-radius: 50px;
`;
