import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { lightThemeColors } from "../../assets/styles/colors";
import moment from "moment";
import SecondaryButton from "../Generic/SecondaryButton";
import PrimaryButton from "../Generic/PrimaryButton";
import { usePaymentsContext } from "../../context/usePaymentsContext";
import { useOutlet, useOutletContext } from "react-router-dom";
import { useInstanceContext } from "../../context/useInstanceContext";
import { showGenericError } from "../../helper/Notifications";
import AdminRoleRequired from "../../helper/AdminRoleRequired";

const DetailsElement = ({ elementDetails, elementTitle }) => {
  return (
    <ElementWrapper>
      <p>{elementTitle}</p>
      <h1>{elementDetails}</h1>
    </ElementWrapper>
  );
};
const BillingDetails = ({ setIsModalOpen }) => {



  const { getDashboardUrl, getCurrentUsage } = usePaymentsContext();
  const { selectedInstance } = useInstanceContext();
  const { setLoading } = useOutletContext();
  const [usage, setUsage] = useState(null)

  const handleGetDashboardUrl = () => {
    setLoading(true);
    getDashboardUrl(
      selectedInstance.id,
      (response) => {
        const dashboardUrl = response.data.url
        window.open(dashboardUrl)
        setLoading(false);
      },
      () => {
        showGenericError()
        setLoading(false);
      }
    )
  }

  const handleGetCurrentUsage = () => {
    setLoading(true)
    getCurrentUsage(
      selectedInstance.id,
      (response) => {
        setUsage(response.data)
        setLoading(false)
      },
      (error) => {
        setLoading(false)
        showGenericError();
        console.log(error)
      },
    )
  }

  const parseAmount = (amount) => {
    return (amount / 100).toFixed(2)
  }

  useEffect(() => {
    handleGetCurrentUsage()
  }, [selectedInstance])

  return (
    <Container>
      <TitleWrapper>
        <h1>Billing Details</h1>
      </TitleWrapper>

      <Line>
        <DetailsElement
          elementDetails={moment().format("MMMM DD, YYYY")}
          elementTitle={"Start Date"}
        />
        <DetailsElement
          elementDetails={usage ? `${moment(usage?.next_payment_attempt * 1000).format("MMM DD, YYYY")}` : `-`}
          elementTitle={"Next Payment"}
        />
        <DetailsElement elementDetails={`Monthly`} elementTitle={"Billed"} />
        <DetailsElement elementDetails={usage ? `$ ${parseAmount(usage.amount)} ` : `-`} elementTitle={"Amount"} />
      </Line>
      <AdminRoleRequired>
        <Line>
          <SecondaryButton
            text={"See Card Details"}
            onClick={() => handleGetDashboardUrl()}
          />
          <PrimaryButton
            text={"Download Invoices"}
            onClick={() => handleGetDashboardUrl()}
          />
        </Line>
      </AdminRoleRequired>
    </Container>
  );
};

export default BillingDetails;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  h1 {
    color: ${lightThemeColors.darkColorFaded};
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
  }
`;
const Line = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const ElementWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0px;
  padding: 0 15px;
  h1 {
    color: ${lightThemeColors.darkColorFaded};
    font-size: 16px;
    font-weight: 600;
    line-height: 27px;
    text-align: left;
  }
  p {
    color: ${lightThemeColors.darkColorFaded30};
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
  }
`;
