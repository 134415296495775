import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { lightThemeColors } from "../../assets/styles/colors";
import { ReactComponent as RemoveIcon } from "../../assets/icons/generic/removeIcon.svg";

import {
  Table,
  Header,
  HeaderRow,
  Body,
  Row,
  HeaderCell,
  Cell,
} from "@table-library/react-table-library/table";
import { useTheme } from "@table-library/react-table-library/theme";
import SecondaryButton from "../../components/Generic/SecondaryButton";
import InvitePeopleModal from "./InvitePeopleModal";
import ShowInitials from "../../components/MembersComponents/ShowInitials";
import { useInstanceContext } from "../../context/useInstanceContext";
import { showGenericError } from "../../helper/Notifications";
import { useOutletContext } from "react-router-dom";
import { cloneDeep } from "lodash";
import { useUserContext } from "../../context/useUserContext";
import DeleteConfirmationChild from "../../components/Generic/DeleteConfirmationWithChild";
import { MEMBER } from "../../constants/OptionsConstants";
import AdminRoleRequired from "../../helper/AdminRoleRequired";
import { HeaderCellSort, useSort } from "@table-library/react-table-library/sort";
import { ReactComponent as UpSortIcon } from "../../assets/icons/generic/tableSortIcons/SortUp.svg";
import { ReactComponent as DownSortIcon } from "../../assets/icons/generic/tableSortIcons/SortDown.svg";
import { ReactComponent as DefaultSortIcon } from "../../assets/icons/generic/tableSortIcons/SortDefault.svg";
import TestProdHeader from "../../components/Generic/TestProdHeader";
import { AnimatePresence } from "framer-motion";

const MembersPage = () => {
  const { getInstanceMembers, selectedInstance, addInstanceMember, removeInstanceMember } = useInstanceContext();
  const { userRef } = useUserContext()
  const { projectType, setProjectType, setLoading } = useOutletContext()

  const [inviteModalOpen, setInviteModalOpen] = useState(false);
  const [members, setMembers] = useState([]);
  const [nodes, setNodes] = useState([]);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false)

  // const nodes = [
  //   {
  //     id: "0",
  //     name: "John Doe",
  //     email: "johndoe@outlook.com",
  //     role: "Administrator",
  //   },
  //   {
  //     id: "2",
  //     name: "Jane Smith",
  //     email: "smithjane@outlook.com",
  //     role: "Member",
  //   },
  //   {
  //     id: "3",
  //     name: "Jane Stone",
  //     email: "janestone@gmail.com",
  //     role: "Invitee",
  //   },
  // ];

  const parseMembers = (members) => {
    let clone = cloneDeep(members)
    const newMembers = clone.map((member) => {
      return (
        {
          id: member.user.id,
          name: member.user.name || "Invited",
          email: member.user.email,
          role: member.role
        }
      )
    })
    return newMembers
  }

  const data = { nodes };

  const theme = useTheme({
    HeaderRow: `
        background:${lightThemeColors.menuBackground};
        border-radius:5px;
        overflow:hidden;
        `,
    HeaderCell: `
        font-size:16px;
        font-weight:700;
        color:${lightThemeColors.darkColorFaded};
        padding:14px;
        padding-left: 20px;
        :first-child{
            border-radius:5px;
        }
        :last-child{
            border-radius:5px;
        }
        `,
    Cell: `
        padding:14px;
        padding-left: 20px;
        gap:20px;
        color:${lightThemeColors.darkColorFaded};
        font-size:16px;
        font-weight:500;
        border-bottom:1px solid ${lightThemeColors.menuBackground};

        div{
            display:flex;
            align-items:center;
            gap:10px;
        }
        `,
    Row: `

           background-color:${props => props.invited ? lightThemeColors.darkColorFullFaded : "unset"};
        `

  });


  const handleGetMembers = () => {
    setLoading(true)
    getInstanceMembers(
      selectedInstance.id,
      (response) => {

        setMembers(response.data.members)
        const nodes = parseMembers(response.data.members)
        setNodes(nodes)
        setLoading(false)
      },
      (error) => {
        showGenericError()
        setLoading(false)
      },
    )
  }

  const handleInviteMember = (email, role) => {
    setLoading(true)
    addInstanceMember(
      selectedInstance.id,
      email,
      role,
      (response) => {
        handleGetMembers()
        setInviteModalOpen(false);
        setLoading(false)
      },
      (error) => {
        showGenericError()
        setLoading(false)
      }
    )
  }

  const handleRemoveMember = (email) => {
    setLoading(true)
    removeInstanceMember(
      selectedInstance.id,
      email,
      (response) => {
        setInviteModalOpen(false);
        setLoading(false)
        handleGetMembers();
      },
      (error) => {
        showGenericError()
        setLoading(false)
      }
    )
  }
  const mapRoles = (role) => {
    if (!role) {
      return
    }
    if (role === "admin") {
      return "administrator"
    }
    else {
      return role
    }
  }

  function onSortChange(action, state) {

  }

  const sort = useSort(
    data,

    {
      onChange: onSortChange,
    },
    {
      sortIcon: {
        margin: "0px",
        iconDefault: <DefaultSortIcon />,
        iconUp: <UpSortIcon />,
        iconDown: <DownSortIcon />,
      },
      sortFns: {
        name: (array) => array.sort((a, b) => a.name - b.name),
        email: (array) => array.sort((a, b) => a.email - b.email),
        role: (array) => array.sort((a, b) => a.role - b.role),

      },
    }
  );

  useEffect(() => {
    if (!selectedInstance) {
      return
    }
    handleGetMembers()
  }, [selectedInstance])

  return (
    <Container>
      {/* <TestProdHeader
        option={projectType}
        setOption={setProjectType}
      /> */}

      {/* <TitleWrapper>
        <h1>Project members</h1>
      </TitleWrapper> */}

      {/* <MembersContent> */}
      <Table data={data} theme={theme} sort={sort}>
        {(tableList) => (
          <>
            <Header>
              <HeaderRow>
                <HeaderCellSort sortKey="name">Name</HeaderCellSort>
                <HeaderCellSort sortKey="email">E-mail</HeaderCellSort>
                <HeaderCellSort className="center" sortKey="role">Role</HeaderCellSort>
                <HeaderCell></HeaderCell>
              </HeaderRow>
            </Header>

            <Body>
              {tableList.map((item) => (
                <Row key={item.id} item={item} >
                  <Cell >
                    <ShowInitials name={item.name} />
                    {item.name}
                  </Cell>
                  <Cell>{item.email}</Cell>
                  <Cell className="center">
                    <RoleCell>
                      <p style={{ textTransform: "capitalize" }}>{mapRoles(item.role)}</p>
                    </RoleCell>
                  </Cell>

                  {(item.id == userRef.current.id) ?
                    <Cell className="end">

                    </Cell>
                    :
                    <Cell className="end">
                      <DeleteConfirmationChild
                        action={handleRemoveMember}
                        item={item.email}
                        open={deleteConfirmationOpen}
                        setOpen={setDeleteConfirmationOpen}
                        variant={MEMBER}
                      >
                        <RemoveBtn onClick={() => setDeleteConfirmationOpen(true)}>
                          <RemoveIcon />
                        </RemoveBtn>
                      </DeleteConfirmationChild>
                    </Cell>
                  }

                </Row>
              ))}
            </Body>
          </>
        )}
      </Table>
      <Line style={{ marginLeft: "auto" }}>
        <AdminRoleRequired>
          <SecondaryButton
            text={"Invite member"}
            onClick={() => setInviteModalOpen(!inviteModalOpen)}
          />
        </AdminRoleRequired>
      </Line>
      {/* </MembersContent> */}
      <AnimatePresence>
        {inviteModalOpen && (
          <InvitePeopleModal
            isMoldaOpen={inviteModalOpen}
            setIsModalOpen={setInviteModalOpen}
            handleInviteMember={handleInviteMember}
          />
        )}
      </AnimatePresence>
    </Container>
  );
};

export default MembersPage;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  h2 {
    width: 100%;
  }
  gap: 30px;
`;

const TitleWrapper = styled.div`
  padding: 0 50px;
  h1 {
    font-size: 30px;
    font-weight: 700;
    color: ${lightThemeColors.darkColor};
  }
`;
const MembersContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  overflow: auto;
  padding-bottom: 30px;
  padding: 0 50px;
  position: relative;
  .center {
    div {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
  }
  .end {
    div {
      display: flex;
      margin-left: auto;
      align-items: center;
      width: 100%;
    }
    padding-right: 20px;
  }
`;

const RoleCell = styled.div`
  display: flex;
  align-items: center;
  background: ${lightThemeColors.sidebar.selectedItemBg};
  padding: 4px 10px;
  border-radius: 5px;
  max-width: 106px;
  justify-content: center;

  p {
    color: ${lightThemeColors.darkColor};
    font-size: 12px;
    font-weight: 500;
    line-height: 21px;
    text-align: center;
    opacity: 0.7;
  }
`;
const RemoveBtn = styled.button`
  border: none;
  cursor: pointer;
  background: none;
  margin-left: auto;
`;
const Line = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;
