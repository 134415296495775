import { GET, POST } from "../../API";
let source;
export const getProjectConfigurationAPICall = (
    instanceId,
    onSuccess,
    onError
) => {

    source = GET(
        process.env.REACT_APP_API_PATH + `/instance/${instanceId}/configurations`,
        onSuccess,
        onError,
        true,
        source
    );
};


export const setIOSAppConfigAPICall = (
    instanceId,
    data,
    onSuccess,
    onError
) => {

    POST(
        process.env.REACT_APP_API_PATH + `/instance/${instanceId}/configurations/ios`,
        data,
        onSuccess,
        onError
    );
};

export const setIOSPushConfigAPICall = (
    instanceId,
    data,
    onSuccess,
    onError
) => {

    POST(
        process.env.REACT_APP_API_PATH + `/instance/${instanceId}/configurations/ios/push`,
        data,
        onSuccess,
        onError
    );
};

export const setAndroidPushConfigAPICall = (
    instanceId,
    data,
    onSuccess,
    onError
) => {

    POST(
        process.env.REACT_APP_API_PATH + `/instance/${instanceId}/configurations/android/push`,
        data,
        onSuccess,
        onError
    );
};

export const setWebAppConfigAPICall = (
    instanceId,
    enabled,
    domains,
    onSuccess,
    onError
) => {

    const data = {
        enabled: enabled,
        domains: domains
    }

    POST(
        process.env.REACT_APP_API_PATH + `/instance/${instanceId}/configurations/web`,
        data,
        onSuccess,
        onError
    );
};

export const setAndroidAppConfigAPICall = (
    instanceId,
    data,
    onSuccess,
    onError
) => {

    POST(
        process.env.REACT_APP_API_PATH + `/instance/${instanceId}/configurations/android`,
        data,
        onSuccess,
        onError
    );
};

export const setDesktopAppConfigAPICall = (
    instanceId,
    data,
    onSuccess,
    onError
) => {

    POST(
        process.env.REACT_APP_API_PATH + `/instance/${instanceId}/configurations/desktop`,
        data,
        onSuccess,
        onError
    );
};
