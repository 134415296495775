import React from "react";
import styled from "styled-components";
import { lightThemeColors } from "../../assets/styles/colors";

const SecondaryButton = ({
  icon,
  text,
  disabled,
  onClick,
  style,
  ref,
  variant,
}) => {
  return (
    <CustomButton
      disabled={disabled}
      onClick={onClick}
      style={style}
      ref={ref}
      variant={variant}
    >
      {icon}
      {text}
    </CustomButton>
  );
};

export default SecondaryButton;

const CustomButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: fit-content;
  gap: 10px;
  background: white;
  padding: 8px 8px;
  padding-right: 12px;
  border: 1px solid
    ${(props) =>
      props.variant ? props.variant : lightThemeColors.primaryColor};
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  text-align: center;
  color: ${(props) =>
    props.variant ? props.variant : lightThemeColors.primaryColor};
  cursor: pointer;
  &:disabled {
    opacity: 0.3;
  }
  svg {
    color: ${lightThemeColors.primaryColor};
    height: 16px;
    width: 16px;
  }
`;
