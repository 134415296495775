import { useInstanceContext } from '../context/useInstanceContext';

const AdminRoleRequired = ({ children }) => {

    const { instanceUserRole } = useInstanceContext();

    if (instanceUserRole.current && instanceUserRole.current.role !== "admin") {
        return null
    }

    return (
        children
    )
}

export default AdminRoleRequired


