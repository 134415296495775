import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { lightThemeColors } from "../../assets/styles/colors";
import { ANDROID, IOS, TEST, WEB } from "../../constants/OptionsConstants";
import { AnimatePresence, motion } from "framer-motion";
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useInstanceContext } from "../../context/useInstanceContext";
import { showGenericError } from "../../helper/Notifications";

const NoSdkBanner = ({ projectType }) => {
  const { instanceConfig } = useInstanceContext();
  const [display, setDisplay] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const goToIntegration = () => {
    navigate({
      pathname: "developers",
      search: createSearchParams(searchParams).toString(),
    });
  };

  useEffect(() => {
    if (!instanceConfig) {
      return;
    }
    let android = instanceConfig.find((item) => item.platform === ANDROID);
    let ios = instanceConfig.find((item) => item.platform === IOS);
    let web = instanceConfig.find((item) => item.platform === WEB);

    if (!android?.configuration && !ios?.configuration && !web?.configuration
    ) {
      setDisplay(true);
    } else {
      setDisplay(false);
    }
  }, [instanceConfig]);

  return (
    <AnimatePresence>
      {display && (
        <AnimatedDiv
          onClick={() => goToIntegration()}
          initial={{ height: 0 }}
          animate={{ height: "34px" }}
          exit={{ height: 0 }}
          transition={{ duration: 0.4 }}
        >
          <Container>
            <h1>
              The SDK hasn't been integrated yet. Click here to configure it.
            </h1>
          </Container>
        </AnimatedDiv>
      )}
    </AnimatePresence>
  );
};

export default NoSdkBanner;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${lightThemeColors.noSDKBanner};
  height: 100%;
  padding: 0px;
  cursor: pointer;
  h1 {
    font-size: 15px;
    font-weight: 500;
    color: #fff;
  }
`;

const AnimatedDiv = styled(motion.div)``;
