import * as React from 'react';
import { styled } from '@mui/material/styles';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { styled as styledComp } from "styled-components"
import { lightThemeColors } from '../../assets/styles/colors';
import { Stack } from '@mui/material';


const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 48,
    height: 24,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,

        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(23px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'dark' ? lightThemeColors.primaryColor : lightThemeColors.primaryColor,
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: lightThemeColors.primaryColor,
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 20,
        height: 20,
    },
    '& .MuiSwitch-track': {
        borderRadius: 12,
        backgroundColor: theme.palette.mode === 'light' ? lightThemeColors.menuBackground : lightThemeColors.menuBackground,
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 200,
        }),
    },
}));


export const CustomSwitch = ({ option1, option2, selectedOption, setSelectedOption, disabled }) => {

    const handleSwitchChange = (e) => {
        const { checked } = e.currentTarget;
        if (checked) {
            setSelectedOption(option2)
        } else {
            setSelectedOption(option1)
        }
    }

    return (
        <StyledStack direction="row" spacing={"15px"} alignItems="center">
            <OptionWrapper selected={selectedOption === option1}>
                <p>{option1}</p>
            </OptionWrapper>

            <FormControlLabel
                disabled={disabled}
                onChange={(e) => handleSwitchChange(e)}
                checked={selectedOption === option2}
                control={
                    <IOSSwitch sx={{ m: 0 }} />}
            />
            <OptionWrapper selected={selectedOption === option2}>
                <p>{option2}</p>
            </OptionWrapper>
        </StyledStack>
    );
}

const StyledStack = styled(Stack)`

`
const OptionWrapper = styledComp.div`
display:flex;
align-items:center;
opacity:${(props) => props.selected ? 1 : 0.3};
p{
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    color:${lightThemeColors.darkColor};
}
`