import React, { useEffect, useState } from "react";
import styled from "styled-components";
import InputFieldWithValidation from "../Generic/InputFieldWithValidation";
import { ReactComponent as RemoveIcon } from "../../assets/icons/generic/removeIcon.svg";
import { ReactComponent as PointSeparatorIcon } from "../../assets/icons/generic/PointSeparatorIcon.svg";

const KeyPairValuesReadonly = ({
  keyValueItem,
  handleRemove,
  handleChangeKeyValuePair,
  index,
}) => {
  return (
    <Container>
      <InputFieldWithValidation
        noValidation
        readOnly
        inputName={"key"}
        inputValue={keyValueItem.key}
      />
      <PointSeparatorIcon />
      <InputFieldWithValidation
        readOnly
        noValidation
        inputName={"value"}
        inputValue={keyValueItem.value}
      />
    </Container>
  );
};

export default KeyPairValuesReadonly;
const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 10px;
  svg {
    min-width: 4px;
  }
`;
const RemoveBtn = styled.button`
  border: none;
  cursor: pointer;
  background: none;
  margin-left: auto;
`;
