import React from "react";
import styled from "styled-components";
import { lightThemeColors } from "../../assets/styles/colors";
import { usePaymentsContext } from "../../context/usePaymentsContext";
import { openInNewTab } from "../../helper/Utils";

const MuaProgress = ({ style }) => {
  const { mau } = usePaymentsContext();

  const mauUntilRequestUpdate = () => {
    let result = new Intl.NumberFormat("de-DE").format(
      mau.total_available - mau.current_quantity
    );
    let display = <p>{result} MUA until your plan requires update.</p>;

    if (result <= 0) {
      display = (
        <h3>
          {" "}
          Your need to increase your plan to keep using grovs this month.{" "}
        </h3>
      );
    }
    return display;
  };

  return (
    <Container style={style}>
      <MuaSegment>
        <h1>
          {new Intl.NumberFormat("de-DE").format(mau.current_quantity)} MUA
        </h1>
        <h1 style={{ marginLeft: "auto" }}>
          {new Intl.NumberFormat("de-DE").format(mau.total_available)} MUA
        </h1>
      </MuaSegment>
      <CustomProgress
        value={new Intl.NumberFormat("de-DE").format(
          (mau.current_quantity * 100) / mau.total_available
        )}
      />
      <InfoTextWrapper>
        {mauUntilRequestUpdate()}
        <p></p>
        <h2
          onClick={() =>
            openInNewTab(
              "https://docs.grovs.io/s/docs/doc/what-are-monthly-active-users-muas-2Zlj0MVh4q"
            )
          }
        >
          What is MUA?
        </h2>
      </InfoTextWrapper>
    </Container>
  );
};

export default MuaProgress;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-width: 760px;
`;

const MuaSegment = styled.div`
  display: flex;
  align-items: center;
  h1 {
    color: ${lightThemeColors.darkColorFaded};
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.02em;
    text-align: right;
  }
`;
const CustomProgress = styled.progress`
  width: 100%;
  appearance: none;
  border-radius: 5px;
  overflow: hidden;
  appearance: none;
  height: 6px;
  &::-webkit-progress-bar {
    background-color: rgba(42, 134, 255, 0.1);
    border-radius: 5px;
    overflow: hidden;
  }
  &::-webkit-progress-value {
    background-color: ${lightThemeColors.primaryColor};
    border-radius: 5px;
    overflow: hidden;
  }
`;

const InfoTextWrapper = styled.div`
  display: flex;
  gap: 5px;
  p {
    color: ${lightThemeColors.darkColorFaded30};
    font-size: 15px;
    font-weight: 400;
    line-height: 24px;
  }
  h3 {
    color: ${lightThemeColors.authentication.inputErrorColor};
    font-size: 15px;
    font-weight: 400;
    line-height: 24px;
  }
  h2 {
    width: inherit !important;
    cursor: pointer;
    color: ${lightThemeColors.primaryColor};
    font-size: 15px;
    font-weight: 400;
    line-height: 24px;
  }
`;
