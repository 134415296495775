import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { lightThemeColors } from "../../assets/styles/colors";

import AddMetrics from "../../components/linksComponents/AddMetrics";
import SelectedMetricTag from "../../components/linksComponents/SlectedMetricTag";
import { useExpanded } from "../../hooks/useExpanded";
import ConfigurationSectionHeader from "../../components/configurationComponents/ConfigurationSectionHeader";
import { useEventContext } from "../../context/useEventContext";
import { showGenericError } from "../../helper/Notifications";
import { useInstanceContext } from "../../context/useInstanceContext";
import moment from "moment";
import LinksOverviewChart from "./LinksOverviewChart";
import { ACTIVE } from "../../constants/OptionsConstants";

const LinksOverview = ({ startDate, endDate, linksType = undefined, linksOverview }) => {
  const { getMetricValuesForEvents, getEventsForOverview } = useEventContext();
  const { selectedProject } = useInstanceContext()
  const [expanded, expand] = useExpanded(false);

  const [selectedMetrics, setSelectedMetrics] = useState([
    {
      value: "view",
      label: "Views",
    },
    {
      value: "open",
      label: "Opens",
    },
    {
      value: "install",
      label: "Installs",
    },
    {
      value: "reinstall",
      label: "Reinstalls",
    },
  ]);

  const [dynamicMetrics, setDynamicMetrics] = useState([]);

  const [platforms, setPlatforms] = useState([]);
  const [appVersions, setAppVersions] = useState([]);
  const [buildVersions, setBuildVersions] = useState([]);

  const [overviewMetrics, setOverviewMetrics] = useState(null)

  const removeMetricsTag = (item) => {
    let filtered = selectedMetrics.filter(
      (metric) => metric.value !== item.value
    );
    setSelectedMetrics(filtered);
  };

  const removeAppVersionTag = (item) => {
    let filtered = appVersions.filter(
      (metric) => metric.value !== item.value
    );
    setAppVersions(filtered);
  };

  const removeBuildVersionTag = (item) => {
    let filtered = buildVersions.filter(
      (metric) => metric.value !== item.value
    );
    setBuildVersions(filtered);
  };

  const removePlatformsVersionTag = (item) => {
    let filtered = platforms.filter(
      (metric) => metric.value !== item.value
    );
    setPlatforms(filtered);
  };

  const handleGetMetricValuesForEvents = () => {
    if (!selectedProject) {
      return
    }
    getMetricValuesForEvents(
      selectedProject.id,
      (response) => {
        setDynamicMetrics(response.data.metrics_values)
      },
      (error) => {
        showGenericError()
      }
    )
  }

  const handleGetEventsForOverview = () => {
    if (!selectedProject || !startDate || !endDate) {
      return
    }

    let data = {
      start_date: moment(startDate).toISOString(),
      end_date: moment(endDate).toISOString()
    }

    if (linksType) {
      if (linksType === ACTIVE) {
        data.active = true
      } else {
        data.active = false
      }
    }

    if (linksOverview) {
      data.sdk_generated = false
    }

    if (platforms.length > 0) {
      data.platforms = platforms
    }
    if (appVersions.length > 0) {
      data.app_versions = appVersions
    }
    if (buildVersions.length > 0) {
      data.build_versions = buildVersions
    }

    getEventsForOverview(
      selectedProject.id,
      data,
      (response) => {

        const metrics = response.data;
        setOverviewMetrics(metrics);
      },
      (error) => {
        showGenericError();
      }
    )
  }


  useEffect(() => {
    handleGetMetricValuesForEvents();
  }, [selectedProject])

  useEffect(() => {
    handleGetEventsForOverview()
  },
    [selectedProject,
      platforms,
      appVersions,
      buildVersions,
      startDate,
      endDate,
      linksType,
      linksOverview
    ])

  return (
    <Container>
      <ConfigurationSectionHeader
        title={"Overview"}
        handleExpand={expand}
        expanded={expanded}
        headerStyle={{ position: "relative" }}
      />

      {expanded && (
        <ContentContainer>
          <Line>
            <AddMetrics
              dynamicMetrics={dynamicMetrics}
              selectedMetrics={selectedMetrics}
              setSelectedMetrics={setSelectedMetrics}
              platforms={platforms}
              setPlatforms={setPlatforms}
              appVersions={appVersions}
              setAppVersions={setAppVersions}
              buildVersions={buildVersions}
              setBuildVersions={setBuildVersions}
            />

            {selectedMetrics.map((metric, index) => (
              <SelectedMetricTag
                key={index}
                tagName={metric.label}
                handleRemove={() => removeMetricsTag(metric)}
              />
            ))}

            {platforms.map((selectedPlatforms, index) => (
              <SelectedMetricTag
                key={index}
                tagName={selectedPlatforms.label}
                selectedMetrics={selectedMetrics.find(
                  (item) => item.value === selectedPlatforms.value
                )}
                handleRemove={() => removePlatformsVersionTag(selectedPlatforms)}
              />
            ))}

            {appVersions.map((selectedAppVersion, index) => (
              <SelectedMetricTag
                key={index}
                tagName={selectedAppVersion.label}
                selectedMetrics={selectedMetrics.find(
                  (item) => item.value === selectedAppVersion.value
                )}
                handleRemove={() => removeAppVersionTag(selectedAppVersion)}
              />
            ))}

            {buildVersions.map((selectedBuildVersion, index) => (
              <SelectedMetricTag
                key={index}
                tagName={selectedBuildVersion.label}
                selectedMetrics={selectedMetrics.find(
                  (item) => item.value === selectedBuildVersion.value
                )}
                handleRemove={() => removeBuildVersionTag(selectedBuildVersion)}
              />
            ))}

          </Line>
          <Line>
            <LinksOverviewChart
              overviewMetrics={overviewMetrics}
              selectedMetrics={selectedMetrics}
            />
          </Line>
        </ContentContainer>
      )}
    </Container>
  );
};

export default LinksOverview;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: ${lightThemeColors.background};
  width: 100%;
  gap: 30px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap:20px;
`;
const Line = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
`;
