import {
    createContext,
    useState,
    useContext,
    useCallback,
    useRef,
} from "react";

import { cancelSubscriptionAPICall, createSubscriptionAPICall, getCurrentMauAPICall, getCurrentUsageAPICall, getDashboardUrlAPICall, getSubscriptionDetailsAPICall } from "../api/payments/paymentsService";

export const PaymentsContext = createContext({
    getSubscriptionDetails: () => { },
    getCurrentMau: () => { },
    getDashboardUrl: () => { },
    createSubscription: () => { },
    cancelSubscription: () => { },
    getCurrentUsage: () => { },
    subscription: [],
    mau: [],

});

const PaymentsContextProvider = ({ children }) => {
    const [subscription, setSubscription] = useState(undefined);
    const [mau, setMau] = useState({
        current_quantity: 0,
        total_available: 1
    })

    const getSubscriptionDetails = useCallback(async (instanceId, onSuccess, onError) => {
        getSubscriptionDetailsAPICall(
            instanceId,
            function (response) {
                setSubscription(response.data)
                onSuccess(response);
            },
            function (error) {
                if (error.response.status == 404 && error.response.data.message === "No active subscriptions") {
                    setSubscription(null);
                    onError(error)
                } else {
                    onError(error)
                }
            }
        );
    });

    const getCurrentMau = useCallback(async (instanceId, onSuccess, onError) => {
        getCurrentMauAPICall(
            instanceId,
            function (response) {
                setMau(response.data)
                onSuccess(response);
            },
            onError
        );
    });

    const getCurrentUsage = useCallback(async (instanceId, onSuccess, onError) => {
        getCurrentUsageAPICall(
            instanceId,
            function (response) {
                // setMau(response.data)
                onSuccess(response);
            },
            onError
        );
    });

    const getDashboardUrl = useCallback(async (instanceId, onSuccess, onError) => {
        getDashboardUrlAPICall(
            instanceId,
            function (response) {
                onSuccess(response);
            },
            onError
        );
    });

    const createSubscription = useCallback(async (instanceId, onSuccess, onError) => {
        createSubscriptionAPICall(
            instanceId,
            function (response) {
                onSuccess(response);
            },
            onError
        );
    });

    const cancelSubscription = useCallback(async (instanceId, onSuccess, onError) => {
        cancelSubscriptionAPICall(
            instanceId,
            function (response) {
                onSuccess(response);
            },
            onError
        );
    });



    return (
        <PaymentsContext.Provider
            value={{
                getSubscriptionDetails,
                getCurrentMau,
                getDashboardUrl,
                createSubscription,
                cancelSubscription,
                subscription,
                mau,
                getCurrentUsage

            }}
        >
            {children}
        </PaymentsContext.Provider>
    );
};

export const usePaymentsContext = () => useContext(PaymentsContext);

export default PaymentsContextProvider;
