import {
    createContext,
    useState,
    useContext,
    useCallback,
    useRef,
} from "react";
import { getAgregatedVisitorsForSearchParamsAPICall, getVisitorDetailsAPICall, getVisitorsForSearchParamsAPICall } from "../api/visitors/visitorsService";

export const VisitorContext = createContext({
    visitorMetrics: [],
    agregatedVisitorMetrics: [],
    setVisitorMetrics: () => { },
    setAgregatedVisitorMetrics: () => { },
    visitorMetricsRef: [],
    agregatedVisitorMetricsRef: [],
    getVisitorsForSearchParams: () => { },
    getAgregatedVisitorsForSearchParams: () => { },
    getVisitorDetails: () => { }
});

const VisitorsContextProvider = ({ children }) => {
    const [visitorMetrics, setVisitorMetrics] = useState();
    const [agregatedVisitorMetrics, setAgregatedVisitorMetrics] = useState()
    const visitorMetricsRef = useRef([]);
    const agregatedVisitorMetricsRef = useRef([]);


    const getVisitorsForSearchParams = useCallback(async (project_id, data, onSuccess, onError) => {
        getVisitorsForSearchParamsAPICall(
            project_id,
            data,
            function (response) {
                onSuccess(response);
            },
            onError
        );
    });

    const getAgregatedVisitorsForSearchParams = useCallback(async (project_id, data, onSuccess, onError) => {
        getAgregatedVisitorsForSearchParamsAPICall(
            project_id,
            data,
            function (response) {
                onSuccess(response);
            },
            onError
        );
    });

    const getVisitorDetails = useCallback(async (project_id, visitor_id, onSuccess, onError) => {
        getVisitorDetailsAPICall(
            project_id,
            visitor_id,
            function (response) {
                onSuccess(response);
            },
            onError
        );
    });



    return (
        <VisitorContext.Provider
            value={{
                visitorMetrics, setVisitorMetrics, visitorMetricsRef,
                agregatedVisitorMetrics, setAgregatedVisitorMetrics, agregatedVisitorMetricsRef,
                getVisitorsForSearchParams, getAgregatedVisitorsForSearchParams, getVisitorDetails,


            }}
        >
            {children}
        </VisitorContext.Provider>
    );
};

export const useVisitorContext = () => useContext(VisitorContext);

export default VisitorsContextProvider;
