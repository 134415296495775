import React from 'react'
import styled from 'styled-components'
import { lightThemeColors } from '../../assets/styles/colors'

const CustomTextArea = ({ value, onChange, style, title }) => {
    const tx = document.getElementsByTagName("textarea");
    for (let i = 0; i < tx.length; i++) {
        tx[i].setAttribute("style", "height:" + (tx[i].scrollHeight) + "px");
        tx[i].addEventListener("input", OnInput, false);
    }

    function OnInput() {
        this.style.height = 'auto';
        this.style.height = (this.scrollHeight) + "px";
    }
    return (
        <Container style={style}>
            {title && <h1>{title}</h1>}
            <CustomTA
                value={value}
                onChange={onChange}
            >

            </CustomTA>

        </Container>
    )
}

export default CustomTextArea
const Container = styled.div`
display:flex;
flex-direction:column;
gap:10px;
width:100%;
h1{
    color:${lightThemeColors.darkColorFaded};
    font-size: 15px;
    font-weight: 700;
    line-height: 27px;
    margin-bottom:5px;
}

`



const CustomTA = styled.textarea`
width:100%;
outline:none;
border:none;
color:${lightThemeColors.darkColorFaded};
font-size: 16px;
font-weight: 500;
line-height: 24px;
text-align: left;
background:${lightThemeColors.background};
border:1px solid ${lightThemeColors.darkColorFullFaded};
border-radius:5px;
resize:none;
min-height:100px;
max-height:300px;
padding:7px 15px;
overflow:auto;
`