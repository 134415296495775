import React from 'react'
import styled from 'styled-components'
import { lightThemeColors } from '../../assets/styles/colors'
import { DEFAULT_PALCEHOLDER_LINK } from '../../constants/OptionsConstants'
import { useInstanceContext } from '../../context/useInstanceContext'
import { useConfigurationContext } from '../../context/useConfigurationContext'

const LinkedInCardPreview = ({ domain, title, subtitle, img }) => {
    const { selectedProject } = useInstanceContext()
    const { projectDomain } = useConfigurationContext()
    const displayImage = () => {
        if (img) {
            return img
        } else {
            if (projectDomain.generic_image_url) {
                return projectDomain.generic_image_url
            } else {
                return DEFAULT_PALCEHOLDER_LINK
            }
        }
    }
    const displayTitle = () => {
        if (title) {
            return title
        } else {
            if (projectDomain.generic_title) {
                return projectDomain.generic_title
            } else {
                return selectedProject.name
            }
        }
    }
    return (
        <Card>
            <ImageWrapper>
                <img src={displayImage()} alt="social-media-preview" />
            </ImageWrapper>
            <InfoWrapper>
                <Title>{displayTitle()}</Title>
                <Domain>{domain}</Domain>

            </InfoWrapper>

        </Card>
    )
}

export default LinkedInCardPreview

const Card = styled.div`
display:flex;
flex-direction:column;
max-width:343px;
max-height:260px;


`
const InfoWrapper = styled.div`
display:flex;
flex-direction:column;
border:0.5px solid rgba(91, 112, 131, 1);
gap:5px;
padding:10px 12px;
border-radius: 0 0 15px 15px;
background:${lightThemeColors.background};
`
const ImageWrapper = styled.div`
display:flex;
min-height:200px;

img{
border-radius:15px 15px 0 0;
    width:100%;
    height:100%;
    object-fit:cover;
}
`
const Domain = styled.p`
color:${lightThemeColors.socialMediaPreviewCard.xCardDomainColor};
font-size: 9px;
font-weight: 500;
line-height: 12px;
text-transform:uppercase;
`
const Title = styled.h1`
color:${lightThemeColors.socialMediaPreviewCard.primaryColor};
font-size: 11px;
font-weight: 600;
line-height: 15px;
`
const Subtitle = styled.p`
color:${lightThemeColors.socialMediaPreviewCard.primaryColor};
font-size: 9px;
font-weight: 500;
line-height: 12px;
text-align: left;
`
