import React, { useState } from "react";
import styled from "styled-components";
import { lightThemeColors } from "../../assets/styles/colors";
import LoaderComponent from "../../components/Generic/LoaderComponent";
import { ReactComponent as Logo } from "../../assets/icons/generic/LogoLong.svg";
import NewPasswordCard from "../../components/AuthenticationComponents/NewPasswordCard";
import { useNavigate } from "react-router-dom";

const NewPasswordPage = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate()
  return (

    <LoaderComponent loading={loading}>
      <Container>
        <LogoWrapper onClick={() => navigate('/login')}>
          <Logo />
        </LogoWrapper>
        <NewPasswordCard setIsLoading={setLoading} />
      </Container>
    </LoaderComponent>
  );
};

export default NewPasswordPage;

const Container = styled.div`
  display: flex;
  align-items: center;
  background: ${lightThemeColors.menuBackground};
  height: 100%;
  gap: 50px;
  overflow: auto;
  padding: 80px;
`;
const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  jutify-conten: center;
  position: fixed;
  top: 40px;
  max-width: 150px;
  z-index: 0;
    cursor:pointer;
`;
