import React, { useState } from "react";
import styled from "styled-components";
import { lightThemeColors } from "../../assets/styles/colors";

import LoaderComponent from "../../components/Generic/LoaderComponent";
import { ReactComponent as Logo } from "../../assets/icons/generic/LogoLong.svg";
import AcceptInviteCard from "../../components/AuthenticationComponents/AcceptInviteCard";
const AcceptInvitePage = () => {
    const [loading, setLoading] = useState(false);
    return (
        <LoaderComponent loading={loading}>
            <Container>
                <LogoWrapper>
                    <Logo />
                </LogoWrapper>
                <AcceptInviteCard setIsLoading={setLoading} />
            </Container>
        </LoaderComponent>
    );
};

export default AcceptInvitePage;

const Container = styled.div`
  display: flex;
  align-items: center;

  background: ${lightThemeColors.menuBackground};
  height: 100%;
  gap: 50px;
  overflow: auto;
  padding: 80px;
`;
const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  jutify-conten: center;
  position: fixed;
  top: 40px;
  max-width: 150px;
  z-index: 0;
`;
