import React, { useEffect, useReducer, useRef, useState } from "react";
import styled, { keyframes } from "styled-components";
import { lightThemeColors } from "../../assets/styles/colors";
import { ReactComponent as CloseModalIcon } from "../../assets/icons/generic/CloseModalIcon.svg";
import InputFieldWithValidation from "../../components/Generic/InputFieldWithValidation";
import PrimaryButton from "../../components/Generic/PrimaryButton";
import { showGenericError } from "../../helper/Notifications";
import EmailRolePair from "./EmailRolePair";
import SecondaryButton from "../Generic/SecondaryButton";
import cloneDeep from "clone-deep";
import { useInstanceContext } from "../../context/useInstanceContext";
import { useSearchParams } from "react-router-dom";
import { motion } from "framer-motion";
import { isEmailValid } from "../../helper/ValidatorsHelper";

const CreateNewProjectModal = ({ isMoldaOpen, setIsModalOpen }) => {
  const { createInstance, setSelectedInstance, getInstances } =
    useInstanceContext();

  const [projectName, setProjectName] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [emailRolePair, setEmailRolePail] = useState([]);

  const ref = useRef();

  const handleCreateInstance = () => {
    const parsedMembers = emailRolePair.map((item) => {
      return {
        ...item,
        role: item.role.value
      }

    })

    createInstance(
      projectName,
      parsedMembers,
      (reponse) => {
        const resp = reponse.data.instance;
        setSelectedInstance(resp);
        setSearchParams((params) => {
          params.set("instance_id", resp.id);
          return params;
        });
        getInstances(
          (reponse) => { },
          () => {
            showGenericError();
          }
        );
        setIsModalOpen(false);
      },
      () => {
        showGenericError();
      }
    );
  };

  const handleAddKeyValuePair = () => {
    let newEmailRolePair = {
      email: "",
      role: { value: "member", label: "Member" },
    };

    setEmailRolePail([...emailRolePair, newEmailRolePair]);
  };

  const handleChangeEmailRolePair = (index, pair) => {
    let clone = cloneDeep(emailRolePair);
    clone[index] = pair;
    setEmailRolePail(clone);
  };
  const handleRemovePair = (index) => {
    let clone = cloneDeep(emailRolePair);
    clone.splice(index, 1);
    setEmailRolePail(clone);
  };

  const hasInvalidEmailMember = () => {
    let invalidEmail = emailRolePair.some((item) => !isEmailValid(item.email))
    return invalidEmail
  }

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (setIsModalOpen && ref.current && !ref.current.contains(e.target)) {
        setIsModalOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [setIsModalOpen]);

  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        setIsModalOpen(false);
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, []);

  return (
    <FullscreenContainer>
      <InviteModal
        ref={ref}
        as={motion.div}
        initial={{ translateX: "100%" }}
        animate={{ translateX: "0" }}
        exit={{ translateX: "100%" }}
        transition={{ duration: 0.4 }}
      >
        <CloneBtn onClick={() => setIsModalOpen(false)}>
          <CloseModalIcon />
        </CloneBtn>
        <HeadWrapper>
          <h1>Create new project </h1>
        </HeadWrapper>
        <ColumnGroup>
          <InputFieldWithValidation
            title={"Project name"}
            placeholder={"Enter a name for your project..."}
            noValidation
            inputName={"project_name"}
            handleInputChange={(e) => setProjectName(e.currentTarget.value)}
          />
          <div>
            <Line>
              <Subtitle>Invite members</Subtitle>
              <OptionalLabel>
                <p>OPTIONAL</p>
              </OptionalLabel>
            </Line>
            <InfoText>
              People added to this list will receive an email inviting them to
              join your project. If they already have an account, they will
              automatically be added to the new project.
            </InfoText>
          </div>

          {emailRolePair.map((pair, index) => (
            <EmailRolePair
              key={index}
              index={index}
              emailRoleItem={pair}
              handleChangeEmailRolePair={handleChangeEmailRolePair}
              handleRemove={handleRemovePair}
            />
          ))}
          <SecondaryButton
            style={{ width: "100%", padding: "11px" }}
            text={"Add Members"}
            onClick={() => handleAddKeyValuePair()}
          />
          <PrimaryButton
            disabled={projectName.length < 2 || hasInvalidEmailMember()}
            styled={{ width: "100%", padding: "12px" }}
            text={"Create Project"}
            onClick={() => handleCreateInstance()}

          />
        </ColumnGroup>
      </InviteModal>
    </FullscreenContainer>
  );
};

export default CreateNewProjectModal;

const InfoText = styled.p`
  color: ${lightThemeColors.inputSubtitleColor};
  margin-top: 15px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
`;

const FullscreenContainer = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  z-index: 5;
`;

const InviteModal = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background: ${lightThemeColors.background};
  max-width: 600px;
  width: 100%;
  height: 100%;
  margin-left: auto;
  padding: 40px;
  gap: 20px;

  .control {
    border-radius: 5px;
  }
`;

const CloneBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  border: none;
  background: none;
  cursor: pointer;
  position: absolute;
  top: 30px;
  right: 30px;
`;
const HeadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  h1 {
    color: ${lightThemeColors.darkColor};
    font-size: 20px;
    font-weight: 600;
    line-height: 37.5px;
  }
  p {
    color: ${lightThemeColors.darkColorFaded30};
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }
`;
const ColumnGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow:auto;
`;

const OptionalLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px 20px;
  background: ${lightThemeColors.sidebar.optionalBg};
  border-radius: 2px;
  p {
    color: ${lightThemeColors.primaryColor};
    font-size: 10px;
    font-weight: 600;
    line-height: 15px;
    text-align: left;
    text-transform: uppercase;
  }
`;
const Line = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;
const Subtitle = styled.p`
  color: ${lightThemeColors.darkColorFaded};
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
`;
