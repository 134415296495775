import { DELETE, GET, PATCH, POST } from "../API";

let availableSource;
let getLinksSource;
export const createNewLinkAPICall = (
  instanceId,
  formData,
  onSuccess,
  onError
) => {
  POST(
    process.env.REACT_APP_API_PATH + `/projects/${instanceId}/create`,
    formData,
    onSuccess,
    onError
  );
};

export const getProjectLinksAPICall = (
  instanceId,
  data,
  onSuccess,
  onError
) => {

  getLinksSource = POST(
    process.env.REACT_APP_API_PATH + `/projects/${instanceId}/links`,
    data,
    onSuccess,
    onError,
    true,
    getLinksSource
  );
};

export const updateLinkAPICall = (
  instanceId,
  linkId,
  formData,
  onSuccess,
  onError
) => {
  PATCH(
    process.env.REACT_APP_API_PATH + `/projects/${instanceId}/links/${linkId}`,
    formData,
    onSuccess,
    onError
  );
};

export const removeLinkAPICall = (instanceId, linkId, onSuccess, onError) => {
  DELETE(
    process.env.REACT_APP_API_PATH + `/projects/${instanceId}/links/${linkId}`,
    onSuccess,
    onError
  );
};

export const availablePathAPICall = (instanceId, path, onSuccess, onError) => {
  const data = {
    path: path,
  };
  availableSource = POST(
    process.env.REACT_APP_API_PATH +
    `/projects/${instanceId}/links/path_available`,
    data,
    onSuccess,
    onError,
    true,
    availableSource
  );
};

export const getRandomPathAPICall = (instanceId, onSuccess, onError) => {
  GET(
    process.env.REACT_APP_API_PATH +
    `/projects/${instanceId}/links/random_path`,
    onSuccess,
    onError
  );
};
