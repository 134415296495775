import {
    createContext,
    useState,
    useContext,
    useCallback,
    useRef,
} from "react";
import { ArchiveNotificationsAPICall, createNotificationsAPICall, getNotificationsAPICall } from "../api/notifications/notificationService";

export const NotificationsContext = createContext({
    notifications: [],
    setNotifications: () => { },
    getNotifications: () => { },
    createNotification: () => { },
    archiveNotification: () => { },

});

const NotificationsContextProvider = ({ children }) => {
    const [notifications, setNotifications] = useState([]);



    const getNotifications = useCallback(async (project_id, data, onSuccess, onError) => {
        getNotificationsAPICall(
            project_id,
            data,
            function (response) {

                onSuccess(response);
            },
            onError
        );
    });

    const createNotification = useCallback(async (data, onSuccess, onError) => {
        createNotificationsAPICall(
            data,
            function (response) {
                onSuccess(response);
            },
            onError
        );
    });

    const archiveNotification = useCallback(async (project_id, notification_id, onSuccess, onError) => {
        ArchiveNotificationsAPICall(
            project_id,
            notification_id,
            function (response) {
                onSuccess(response);
            },
            onError
        );
    });





    return (
        <NotificationsContext.Provider
            value={{
                notifications,
                setNotifications,
                getNotifications,
                createNotification,
                archiveNotification
            }}
        >
            {children}
        </NotificationsContext.Provider>
    );
};

export const useNotificationsContext = () => useContext(NotificationsContext);

export default NotificationsContextProvider;
