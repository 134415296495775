import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { lightThemeColors } from "../../assets/styles/colors";
import ConfigurationSectionHeader from "../../components/configurationComponents/ConfigurationSectionHeader";
import { ReactComponent as DeskTopIcon } from "../../assets/icons/generic/DesktopIconSmall.svg";
import { ReactComponent as PlusIcon } from "../../assets/icons/generic/plus.svg";
import SubcategoryTitleWrapperWithNumber from "../../components/Generic/SubcategoryTittleWrapperWithNumber";
import InputFieldWithValidation from "../../components/Generic/InputFieldWithValidation";
import SecondaryButton from "../../components/Generic/SecondaryButton";
import { ReactComponent as CopyIcon } from "../../assets/icons/generic/CopyIcon.svg";
import DomainLongItem from "../../components/integrationComponents/DomainLongItem";
import { useExpanded } from "../../hooks/useExpanded";
import {
  APP_DELEGATE,
  GRADLE,
  NPM,
  SCENE_DELEGATE,
  WEB,
} from "../../constants/OptionsConstants";
import OptionButton from "../../components/Generic/OptionButton";
import { useInstanceContext } from "../../context/useInstanceContext";
import { useOutletContext } from "react-router-dom";
import _, { cloneDeep } from "lodash";
import {
  showGenericError,
  showSuccessNotification,
} from "../../helper/Notifications";
import PrimaryButton from "../../components/Generic/PrimaryButton";
import { openInNewTab } from "../../helper/Utils";
import { Tooltip } from "react-tooltip";
import { copyToClipboard } from "../../helper/copy";
import { isURL } from "validator";
import { useConfigurationContext } from "../../context/useConfigurationContext";

const WebSDK = () => {
  const {
    selectedInstance,
    setProjectWebConfiguration,
    instanceConfig,
    getProjectConfiguration,
  } = useInstanceContext();
  const { projectRedirectsConfig } = useConfigurationContext();

  const { setLoading } = useOutletContext();

  const [expanded, expand] = useExpanded(false);
  const [integrationMethod, setIntegrationMethod] = useState(NPM);
  const [sdkType, setSdkType] = useState(APP_DELEGATE);

  const [domainInput, setDomainInput] = useState("");
  const [domainList, setDomainList] = useState([]);

  const [webConfig, setWebConfig] = useState(null);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);

  const [position, setPosition] = useState({ x: 0, y: 0 });
  const divRef = useRef(null);

  const handleMouseMove = (event) => {
    const x = event.clientX;
    const y = event.clientY;
    setPosition({ x, y });
  };

  const handleSelectIntegrationMethode = (method) => {
    setIntegrationMethod(method);
  };

  const handleSelectSdkIntegrationType = (value) => {
    setSdkType(value);
  };

  const containerStyle = {
    maxWidth: "635px",
  };

  const INTEGRATION_METHOD_LIST = [
    {
      text: "NPM",
      value: NPM,
    },
  ];

  const SDK_INTEGRATION_METHOD = [
    {
      text: "App Delegate",
      value: APP_DELEGATE,
    },
    {
      text: "Scene Delegate",
      value: SCENE_DELEGATE,
    },
  ];
  const handleGetProjectConfig = () => {
    setLoading(true);
    getProjectConfiguration(
      selectedInstance.id,
      (response) => {
        setLoading(false);
      },
      () => {
        showGenericError();
        setLoading(false);
      }
    );
  };

  const handleAddDomain = (domain) => {
    if (domain == "") {
      return;
    }
    setDomainList((prev) => {
      return [...prev, domain];
    });
    setDomainInput("");
  };

  const handleRemoveDomain = (index) => {
    let list = cloneDeep(domainList);
    list.splice(index, 1);
    setDomainList(list);
  };

  const handleSetWebConfiguration = (instanceId, enabled, domains) => {
    setLoading(true);
    setProjectWebConfiguration(
      instanceId,
      enabled,
      domains,
      (response) => {
        setWebConfig(response.data.config);
        showSuccessNotification("Saved");
        setLoading(false);
        handleGetProjectConfig();
      },
      () => {
        showGenericError();
        setLoading(false);
      }
    );
  };

  const handleSave = () => {
    let enabled = false;
    if (webConfig) {
      enabled = webConfig.enabled;
    }
    handleSetWebConfiguration(selectedInstance.id, enabled, domainList);
  };

  const detailsChanged = (config) => {
    if (!config) {
      if (!_.isEqual(domainList, [])) {
        setIsSaveDisabled(false);
      } else {
        setIsSaveDisabled(true);
      }
    } else if (!_.isEqual(domainList, config?.configuration.domains)) {
      setIsSaveDisabled(false);
    } else {
      setIsSaveDisabled(true);
    }
  };

  const initializeValues = (config) => {
    if (!config) {
      setDomainList([]);
    } else {
      setDomainList(config.configuration.domains);
    }
  };

  useEffect(() => {
    if (!instanceConfig) {
      return;
    }
    let found = instanceConfig.find((config) => config.platform === WEB);
    if (instanceConfig.length > 0 && found) {
      setWebConfig(found);
      initializeValues(found);
    } else {
      setWebConfig(null);
      initializeValues(null);
    }
  }, [instanceConfig]);

  useEffect(() => {
    detailsChanged(webConfig);
  }, [domainList, webConfig]);

  return (
    <Container>
      <ConfigurationSectionHeader
        icon={<DeskTopIcon />}
        title={"Web SDK"}
        handleExpand={expand}
        expanded={expanded}
        showValidation
        isValid={webConfig?.configuration?.domains.length > 0}
        unsavedChanges={!isSaveDisabled}
        customButton={
          !isSaveDisabled && (
            <PrimaryButton
              disabled={isSaveDisabled}
              bgVariant={lightThemeColors.background}
              styled={{ color: lightThemeColors.primaryColor }}
              text={"Save"}
              onClick={(e) => handleSave()}
            />
          )
        }
      />

      {expanded && (
        <ContentContainer>
          <Tooltip
            opacity={1}
            noArrow
            position={position}
            role="tooltip"
            border={"none"}
            style={{
              zIndex: "100",
              opacity: 1,
              background: lightThemeColors.primaryColor,
              color: lightThemeColors.background,
              padding: "5px",
              border: "none",
            }}
            id={"webSDK-tooltip"}
          />
          <SubcategoryContent>
            <Line>
              <SubcategoryTitleWrapperWithNumber
                number={"1"}
                text="Register domains "
              />
            </Line>

            <Line>
              <InputFieldWithValidation
                title={"Domain list"}
                subtitle={
                  "Register all domains that will use the SDK, including all production, test, and development domains. This ensures the SDK functions correctly across different environments."
                }
                style={containerStyle}
                placeholder={"Enter a new domain name..."}
                label={"Show me more details about the domains"}
                labelAction={() => {
                  openInNewTab(
                    "https://docs.grovs.io/s/docs/doc/registering-domains-for-sdk-integration-UsSBP9YTk4"
                  );
                }}
                valid={isURL(domainInput)}
                inputValue={domainInput}
                handleInputChange={(e) => setDomainInput(e.currentTarget.value)}
                customButton={
                  <SecondaryButton
                    text={"Add domain"}
                    icon={<PlusIcon />}
                    onClick={() => handleAddDomain(domainInput)}
                  />
                }
              />
            </Line>
            {/* {domainList.length > 0 && ( */}
            <DomainList style={containerStyle}>
              {domainList.map((item, index) => (
                <DomainLongItem
                  key={index}
                  item={item}
                  handleRemove={() => handleRemoveDomain(index)}
                />
              ))}
            </DomainList>
            {/* )} */}
          </SubcategoryContent>
          <Separator />
          <SubcategoryContent>
            <Line>
              <SubcategoryTitleWrapperWithNumber
                number={"2"}
                text="Add the SDK"
              />
            </Line>
            <Line>
              {INTEGRATION_METHOD_LIST.map((item, index) => (
                <OptionButton
                  key={index}
                  text={item.text}
                  selected={integrationMethod === item.value}
                  onClick={() => handleSelectIntegrationMethode(item.value)}
                />
              ))}
            </Line>
            <Line>
              <InputFieldWithValidation
                title={"Integration method"}
                subtitle={
                  "To add a new npm package, simply include its dependency in your package.json file, then run npm install to integrate it into your project."
                }
                style={containerStyle}
                readOnly
                noValidation
                labelAction={() => {
                  openInNewTab(
                    "https://docs.grovs.io/s/docs/doc/how-to-integrate-an-npm-package-rhgwqATEBP"
                  );
                }}
                inputValue={"npm install linksquared"}
                label={"Show me how to integrate an NPM package"}
                customButton={
                  <SecondaryButton
                    text={"Copy"}
                    icon={<CopyIcon />}
                    onClick={() => copyToClipboard("npm install linksquared")}
                  />
                }
              />
            </Line>
          </SubcategoryContent>
          <Separator />
          <SubcategoryContent>
            <Line>
              <SubcategoryTitleWrapperWithNumber
                number={"3"}
                text="Integrate the SDK"
              />
            </Line>

            {/* <Line>
              {SDK_INTEGRATION_METHOD.map((item, index) => (
                <OptionButton
                  key={index}
                  text={item.text}
                  selected={sdkType === item.value}
                  onClick={() => handleSelectSdkIntegrationType(item.value)}
                />
              ))}
            </Line> */}
            <InfoText>
              In the main entry point of your project, initialize the SDK to
              listen for link data, generate links, and set user attributes as
              needed.
            </InfoText>
            <TextCodeContainer
              id={"webSDKText"}
              ref={divRef}
              onMouseMove={handleMouseMove}
            >
              <span
                data-tooltip-id={"webSDK-tooltip"}
                data-tooltip-content={"Click text to copy"}
                onClick={(e) => copyToClipboard(e.currentTarget.innerText)}
              >
                import Linksquared from 'linksquared';
              </span>
              <br />
              <span
                data-tooltip-id={"webSDK-tooltip"}
                data-tooltip-content={"Click text to copy"}
                onClick={(e) => copyToClipboard(e.currentTarget.innerText)}
              >
                const APIKey = "{selectedInstance.api_key}"
              </span>
              <span
                data-tooltip-id={"webSDK-tooltip"}
                data-tooltip-content={"Click text to copy"}
                onClick={(e) => copyToClipboard(e.currentTarget.innerText)}
              >{`const linksquared = new Linksquared(APIKey, (data) => {
    console.log('Link data:', data);
})`}</span>
              <br />
              {`// Start the SDK`}
              <span
                data-tooltip-id={"webSDK-tooltip"}
                data-tooltip-content={"Click text to copy"}
                onClick={(e) => copyToClipboard(e.currentTarget.innerText)}
              >
                linksquared.start();
              </span>
              <br />
              {`// Set the user attributes`}
              <span
                data-tooltip-id={"webSDK-tooltip"}
                data-tooltip-content={"Click text to copy"}
                onClick={(e) => copyToClipboard(e.currentTarget.innerText)}
              >
                linksquared.setUserIdentifier('YOUR_USER_ID');
              </span>
              <span
                data-tooltip-id={"webSDK-tooltip"}
                data-tooltip-content={"Click text to copy"}
                onClick={(e) => copyToClipboard(e.currentTarget.innerText)}
              >{`linksquared.setUserAttributes({KEY:VALUE});`}</span>
            </TextCodeContainer>
            <Line>
              <CustomLabel
                onClick={() => {
                  openInNewTab(
                    "https://docs.grovs.io/s/docs/doc/web-fxOTQ0xzrj"
                  );
                }}
              >
                Show me more details about the SDK and it’s methods
              </CustomLabel>
              <SecondaryButton
                style={{ marginLeft: "auto" }}
                icon={<CopyIcon />}
                text={"Copy"}
                onClick={(e) => copyToClipboard(divRef.current.innerText)}
              />
            </Line>
          </SubcategoryContent>
        </ContentContainer>
      )}
    </Container>
  );
};

export default WebSDK;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: ${lightThemeColors.background};
  width: 100%;
  gap: 20px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding-bottom: 30px;
`;

const SubcategoryContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0 40px;
`;

const Line = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  p {
    color: ${lightThemeColors.darkColor};
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
  }
`;
const Separator = styled.div`
  display: flex;
  height: 1px;
  min-height: 1px;
  width: 100%;
  background: ${lightThemeColors.darkColor};
  opacity: 0.1;
  margin: 30px 0;
`;

const InfoText = styled.p`
  color: ${lightThemeColors.inputSubtitleColor};
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  max-width: 635px;
`;
const DomainList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;
const TextCodeContainer = styled.pre`
  display: flex;
  flex-direction: column;
  border: 1px solid ${lightThemeColors.menuBorder};
  border-radius: 5px;
  padding: 16px;
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: ${lightThemeColors.darkColorFaded60};
  overflow: auto;
  white-space: pre;
  span {
    width: fit-content;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    font-weight: 600;
    color: ${lightThemeColors.primaryColor};
    &:hover {
      background: ${lightThemeColors.sidebar.linkBg};
    }
  }
  p {
    white-space: pre;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    font-weight: 500;
    color: ${lightThemeColors.darkColorFaded60};
  }
`;

const CustomLabel = styled.label`
  margin-top: -20px;
  cursor: pointer;
  color: ${lightThemeColors.inputLabelColor};
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
`;
