import React from "react";

import { ReactComponent as OuterArrow } from "../../assets/icons/generic/OuterArrow.svg";
import styled from "styled-components";
import { lightThemeColors } from "../../assets/styles/colors";
const LableWithOuterArrow = ({ text, direction }) => {
  return (
    <Wrapper direction={direction}>
      <ArrowWrapper>
        <p>{text}</p>
      </ArrowWrapper>

      <OuterArrow />
    </Wrapper>
  );
};

export default LableWithOuterArrow;

const Wrapper = styled.div`
  display: flex;
  flex-direction: ${(props) =>
    props.direction === "right" ? "row" : "row-reverse"};
  align-items: center;
  gap: 15px;
  border-radius: 5px;

  p {
    color: ${lightThemeColors.primaryColor};
    font-size: 15px;
    line-height: 20px;
    font-weight: 500;
  }

  svg {
    transform: ${(props) => (props.direction === "right" ? "" : "scaleX(-1)")};
  }
`;
const ArrowWrapper = styled.div`
  padding: 0px 10px;
  min-height: 30px;
  min-width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed ${lightThemeColors.primaryColor};
  border-radius: 5px;
`;
