import * as React from "react";
import { styled } from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { styled as styledComp } from "styled-components";
import { lightThemeColors } from "../../assets/styles/colors";
import { Stack } from "@mui/material";
import { PRODUCTION, TEST } from "../../constants/OptionsConstants";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 48,
  height: 24,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,

    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(23px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor:
          theme.palette.mode === "dark"
            ? lightThemeColors.primaryColor
            : lightThemeColors.primaryColor,
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: lightThemeColors.primaryColor,
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 20,
    height: 20,
  },
  "& .MuiSwitch-track": {
    borderRadius: 12,
    backgroundColor:
      theme.palette.mode === "light"
        ? lightThemeColors.darkColorFullFaded
        : "red",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 200,
    }),
  },
}));

export const CustomSwitchSimple = ({
  title,
  secondaryOption,
  defaultOption,
  selectedOption,
  setSelectedOption,
}) => {
  const handleSwitchChange = (e) => {
    const { checked } = e.currentTarget;
    if (checked) {
      setSelectedOption(secondaryOption);
    } else {
      setSelectedOption(defaultOption);
    }
  };

  return (
    <StyledStack direction="row" spacing={"15px"} alignItems="center">
      <OptionWrapper selected={selectedOption === secondaryOption}>
        <p>{title}</p>
      </OptionWrapper>

      <FormControlLabel
        onChange={(e) => handleSwitchChange(e)}
        checked={selectedOption === secondaryOption}
        control={<IOSSwitch sx={{ m: 0 }} />}
      />
    </StyledStack>
  );
};

const StyledStack = styled(Stack)``;
const OptionWrapper = styledComp.div`
display:flex;
align-items:center;
justify-content: end;
opacity:${(props) => (props.selected ? 1 : 0.3)};
p{
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: ${(props) =>
      props.selected
        ? lightThemeColors.primaryColor
        : lightThemeColors.darkColor};
}
`;
