import {
    createContext,
    useState,
    useContext,
    useCallback,
    useRef,
} from "react";
import { addMemberToInstanceAPICall, createInstanceAPICall, currentUserRoleForInstanceAPICall, deleteInstanceAPICall, editInstanceAPICall, getInstancesAPICall, getMembersForInstanceeAPICall, removedMemberFromInstanceAPICall } from "../api/instances/instanceService";
import { getProjectConfigurationAPICall, setAndroidAppConfigAPICall, setAndroidPushConfigAPICall, setDesktopAppConfigAPICall, setIOSAppConfigAPICall, setIOSPushConfigAPICall, setWebAppConfigAPICall } from "../api/instances/aplications/configAplicationsService";


export const InstanceContext = createContext({
    instances: [],
    selectedInstance: [],
    selectedProject: [],
    instanceConfig: [],
    setInstanceConfig: () => { },
    getInstances: () => { },
    setSelectedInstance: () => { },
    setSelectedProject: () => { },
    editInstance: () => { },
    createInstance: () => { },
    getInstanceMembers: () => { },
    addInstanceMember: () => { },
    removeInstanceMember: () => { },
    deleteInstance: () => { },
    getProjectConfiguration: () => { },
    setProjectAndroidConfiguration: () => { },
    setProjectIosConfiguration: () => { },
    setProjectWebConfiguration: () => { },
    setProjectDesktopConfiguration: () => { },
    getInstanceUserRole: () => { },
    instanceUserRole: [],
    setProjectIosPushConfiguration: () => { },
    setProjectAndroidPushConfiguration: () => { },

});

const InstanceContextProvider = ({ children }) => {

    const instanceUserRole = useRef(null);
    const [instances, setInstances] = useState(undefined);
    const [selectedInstance, setSelectedInstance] = useState(undefined);
    const [selectedProject, setSelectedProject] = useState(undefined);
    const [instanceConfig, setInstanceConfig] = useState(null);



    const getInstances = useCallback(async (onSuccess, onError) => {
        getInstancesAPICall(
            function (response) {
                handleInstanceResponse(response)
                onSuccess(response);
            },
            onError
        );
    });

    const createInstance = useCallback(async (name, members, onSuccess, onError) => {
        createInstanceAPICall(
            name,
            members,
            function (response) {
                // handleInstanceResponse(response)
                onSuccess(response);
            },
            onError
        );
    });

    const editInstance = useCallback(async (instanceId, name, onSuccess, onError) => {
        editInstanceAPICall(
            instanceId, name,
            function (response) {
                handleInstanceResponse(response)
                onSuccess(response);
            },
            onError
        );
    });

    const getInstanceMembers = useCallback(async (instanceId, onSuccess, onError) => {
        getMembersForInstanceeAPICall(
            instanceId,
            function (response) {
                onSuccess(response);
            },
            onError
        );
    });

    const addInstanceMember = useCallback(async (instanceId, email, role, onSuccess, onError) => {
        addMemberToInstanceAPICall(
            instanceId,
            email,
            role,
            function (response) {
                onSuccess(response);
            },
            onError
        );
    });

    const removeInstanceMember = useCallback(async (instanceId, email, onSuccess, onError) => {
        removedMemberFromInstanceAPICall(
            instanceId,
            email,
            function (response) {
                onSuccess(response);
            },
            onError
        );
    });

    const deleteInstance = useCallback(async (instanceId, onSuccess, onError) => {
        deleteInstanceAPICall(
            instanceId,
            function (response) {
                onSuccess(response);
            },
            onError
        );
    });

    const getProjectConfiguration = useCallback(async (instanceId, onSuccess, onError) => {
        getProjectConfigurationAPICall(
            instanceId,
            function (response) {
                setInstanceConfig(response.data.configurations)
                onSuccess(response);
            },
            onError
        );
    });

    const setProjectIosPushConfiguration = useCallback(async (instanceId, data, onSuccess, onError) => {
        setIOSPushConfigAPICall(
            instanceId,
            data,
            function (response) {
                onSuccess(response);
            },
            onError
        );
    });



    const setProjectIosConfiguration = useCallback(async (instanceId, data, onSuccess, onError) => {
        setIOSAppConfigAPICall(
            instanceId, data,
            function (response) {
                onSuccess(response);
            },
            onError
        );
    });

    const setProjectWebConfiguration = useCallback(async (instanceId, enabled, domains, onSuccess, onError) => {
        setWebAppConfigAPICall(
            instanceId, enabled, domains,
            function (response) {
                onSuccess(response);
            },
            onError
        );
    });

    const setProjectAndroidConfiguration = useCallback(async (instanceId, data, onSuccess, onError) => {
        setAndroidAppConfigAPICall(
            instanceId, data,
            function (response) {
                onSuccess(response);
            },
            onError
        );
    });

    const setProjectAndroidPushConfiguration = useCallback(async (instanceId, data, onSuccess, onError) => {
        setAndroidPushConfigAPICall(
            instanceId,
            data,
            function (response) {
                onSuccess(response);
            },
            onError
        );
    });


    const setProjectDesktopConfiguration = useCallback(async (instanceId, data, onSuccess, onError) => {
        setDesktopAppConfigAPICall(
            instanceId, data,
            function (response) {
                onSuccess(response);
            },
            onError
        );
    });

    const getInstanceUserRole = useCallback(async (instanceId, onSuccess, onError) => {
        currentUserRoleForInstanceAPICall(
            instanceId,
            function (response) {
                instanceUserRole.current = response.data.role
                onSuccess(response);
            },
            onError
        );
    });

    const handleInstanceResponse = (response) => {
        const instances = response.data.instances
        let sortedInstances = instances.sort((a, b) => a.updated_at < b.updated_at ? 1 : -1)
        setInstances(sortedInstances);
    }

    return (
        <InstanceContext.Provider
            value={{
                instances,
                selectedInstance,
                setSelectedInstance,
                getInstances,
                selectedProject,
                setSelectedProject,
                editInstance,
                createInstance,
                getInstanceMembers,
                addInstanceMember,
                removeInstanceMember,
                deleteInstance,
                getProjectConfiguration,
                setProjectIosConfiguration,
                setProjectWebConfiguration,
                setProjectAndroidConfiguration,
                setProjectDesktopConfiguration,
                instanceConfig,
                setInstanceConfig,
                getInstanceUserRole,
                instanceUserRole,
                setProjectIosPushConfiguration,
                setProjectAndroidPushConfiguration
            }}
        >
            {children}
        </InstanceContext.Provider>
    );
};

export const useInstanceContext = () => useContext(InstanceContext);

export default InstanceContextProvider;
