import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { lightThemeColors } from "../../assets/styles/colors";
import { ReactComponent as BackIcon } from "../../assets/icons/generic/SquareArrow.svg";
import {
  BUTTON,
  CENTER,
  END,
  FIT,
  FULL,
  IMAGE,
  LARGE,
  MEDIUM,
  MENU,
  PILL,
  ROUND,
  SEPARATOR,
  SMALL,
  SQUARE,
  START,
} from "../../constants/OptionsConstants";
import CustomInput from "../AuthenticationComponents/CustomInput";
import { SketchPicker } from "react-color";
import { parseColorFromRgbaColorPicker } from "../../helper/Utils";
import { Item, Menu, useContextMenu } from "react-contexify";
import CustomEditorMenuButton from "./CustomEditorMenuButton";

const CustomEditorSeparatorMenu = ({
  setDisplayOption,
  setActiveElementIndex,
  resultList,
  activeElementIndex,
  handleItemChanges,
}) => {
  const currentSeparator = resultList.find(
    (item) => item.id === activeElementIndex
  );
  const currentProps = currentSeparator.content.props.state;

  const [minHeight, setMinHeight] = useState(currentProps?.minHeight);
  const [background, setBackground] = useState(currentProps?.background);
  const [form, setForm] = useState(currentProps?.form);
  const [marginTop, setMarginTop] = useState(currentProps?.marginTop);
  const [marginBottom, setMarginBottom] = useState(currentProps?.marginBottom);

  const SEPARATOR_MENU = "separator-menu";

  const { show } = useContextMenu({
    id: SEPARATOR_MENU,
  });

  const labelStyle = {
    fontWeight: 500,
    color: lightThemeColors.inputSubtitleColor,

  }

  function displayMenu(e) {
    // put whatever custom logic you need
    // you can even decide to not display the Menu
    show({
      event: e,
    });
  }

  const handleBack = () => {
    setDisplayOption(MENU);
    setActiveElementIndex(null);
  };

  useEffect(() => {
    if (!currentSeparator || !currentProps) {
      return;
    }
    setMinHeight(currentProps.minHeight);
    setBackground(currentProps.background);
    setForm(currentProps.form);
    setMarginBottom(currentProps.marginBottom);
    setMarginTop(currentProps.marginTop);
  }, [currentSeparator.id]);

  useEffect(() => {
    handleItemChanges(currentSeparator.id, "minHeight", minHeight);
  }, [minHeight]);

  useEffect(() => {
    handleItemChanges(currentSeparator.id, "background", background);
  }, [background]);

  useEffect(() => {
    handleItemChanges(currentSeparator.id, "form", form);
  }, [form]);

  useEffect(() => {
    handleItemChanges(currentSeparator.id, "marginTop", marginTop);
  }, [marginTop]);
  useEffect(() => {
    handleItemChanges(currentSeparator.id, "marginBottom", marginBottom);
  }, [marginBottom]);

  return (
    <MenuCard>
      <MenuContent>
        <Line style={{ position: "relative" }}>
          <CloseBtn onClick={() => handleBack()}>
            <BackIcon />
            Back
          </CloseBtn>
          <HeadWrapper>
            <h1>{SEPARATOR}</h1>
          </HeadWrapper>
        </Line>

        <Group>
          <Line>
            <CustomInput
              labelStyle={labelStyle}
              type={"number"}
              label={"Height"}
              value={minHeight}
              onChange={(e) => setMinHeight(e.currentTarget.value)}
            />
          </Line>
        </Group>
        <Group>
          <GroupDescription>Margin</GroupDescription>
          <Line>
            <CustomInput
              labelStyle={labelStyle}
              type={"number"}
              label={"Top"}
              value={marginTop}
              onChange={(e) => setMarginTop(e.currentTarget.value)}
            />
            <CustomInput
              labelStyle={labelStyle}
              type={"number"}
              label={"Bottom"}
              value={marginBottom}
              onChange={(e) => setMarginBottom(e.currentTarget.value)}
            />
          </Line>
        </Group>
        <Group>
          <GroupDescription>Type</GroupDescription>
          <Line>
            <CustomEditorMenuButton
              onClick={() => setForm(SQUARE)}
              selected={form === SQUARE}
            >
              Square
            </CustomEditorMenuButton>
            <CustomEditorMenuButton
              onClick={() => setForm(ROUND)}
              selected={form === ROUND}
            >
              Round
            </CustomEditorMenuButton>
          </Line>
        </Group>
        <Group>
          <GroupDescription>Color</GroupDescription>
          <Line>
            <ColorPickerContainer onClick={(e) => displayMenu(e)}>
              <ColorPickerInside style={{ background: background }} />
            </ColorPickerContainer>
          </Line>
        </Group>
      </MenuContent>
      <Menu id={SEPARATOR_MENU}>
        <Item closeOnClick={false} style={{ padding: 0 }}>
          <SketchPicker
            width="300px"
            color={background}
            onChange={(color) =>
              setBackground(parseColorFromRgbaColorPicker(color.rgb))
            }
          />
        </Item>
      </Menu>
    </MenuCard>
  );
};

export default CustomEditorSeparatorMenu;
const MenuCard = styled.div`
  max-width: 600px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  top: 0;
  background: ${lightThemeColors.menuBackground};
  z-index: 1;
  position: relative;

  .contexify {
    padding: 0 !important;
  }
  .contexify_itemContent {
    padding: 0 !important;
  }
  .contexify_itemContent:hover {
    background-color: transparent !important;
  }
`;

const HeadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: auto;
  h1 {
    color: ${lightThemeColors.darkColorFaded};
    font-size: 20px;
    font-weight: 700;
    line-height: 37.5px;
  }
  p {
    color: ${lightThemeColors.darkColorFaded30};
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
  }
`;

const CloseBtn = styled.button`
display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  border: none;
  background: none;
  cursor: pointer;
  gap:10px;
  color:${lightThemeColors.primaryColor};
  font-size:18px;
  line-height:22px;
  font-weight:500;
  position:absolute;
  top:calc(50% - 11px);
  left:40;
`;
const Line = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 0 40px;
  width: 100%;
`;
const Group = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`;

const MenuContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  gap: 20px;
  padding: 40px 0;
`;

const GroupDescription = styled.p`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: ${lightThemeColors.inputSubtitleColor};
  padding: 0px 40px;
  padding-top: 10px;
`;

const ColorPickerContainer = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50px;
  border: none;
  position: relative;
  cursor: pointer;
  padding: 3px;
  background: ${lightThemeColors.background};
  box-shadow: 0 2px 5px rgba(43, 58, 84, 0.2);
`;

const ColorPickerInside = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  border-radius: 50px;
`;
