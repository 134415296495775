import {
    createContext,
    useState,
    useContext,
    useCallback,
    useRef,
} from "react";
import { availablePathAPICall, createNewLinkAPICall, getProjectLinksAPICall, getRandomPathAPICall, removeLinkAPICall, updateLinkAPICall } from "../api/links/linksService";





export const LinkContext = createContext({
    links: [],
    setLinks: () => { },
    getLinks: () => { },
    createLink: () => { },
    updateLink: () => { },
    removeLink: () => { },
    isPathAvailable: () => { },
    getRandomPath: () => { },
    linksRef: [],
    shouldRefreshLinksRef: [],

});

const LinkContextProvider = ({ children }) => {
    const [links, setLinks] = useState(undefined);
    const linksRef = useRef();
    const shouldRefreshLinksRef = useRef();



    const createLink = useCallback(async (instanceId, formData, onSuccess, onError) => {
        createNewLinkAPICall(
            instanceId,
            formData,
            function (response) {
                onSuccess(response);
            },
            onError
        );
    });

    const getLinks = useCallback(async (instanceId, data, onSuccess, onError) => {
        getProjectLinksAPICall(
            instanceId,
            data,
            function (response) {
                onSuccess(response);
            },
            onError
        );
    });

    const updateLink = useCallback(async (instanceId, linkId, formData, onSuccess, onError) => {
        updateLinkAPICall(
            instanceId,
            linkId,
            formData,
            function (response) {
                onSuccess(response);
            },
            onError
        );
    });

    const removeLink = useCallback(async (instanceId, linkId, onSuccess, onError) => {
        removeLinkAPICall(
            instanceId,
            linkId,
            function (response) {
                onSuccess(response);
            },
            onError
        );
    });

    const isPathAvailable = useCallback(async (instanceId, path, onSuccess, onError) => {
        availablePathAPICall(
            instanceId,
            path,
            function (response) {
                onSuccess(response);
            },
            onError
        );
    });

    const getRandomPath = useCallback(async (instanceId, onSuccess, onError) => {
        getRandomPathAPICall(
            instanceId,
            function (response) {
                onSuccess(response);
            },
            onError
        );
    });


    return (
        <LinkContext.Provider
            value={{
                links,
                getLinks,
                setLinks,
                createLink,
                updateLink,
                removeLink,
                isPathAvailable,
                getRandomPath,
                linksRef,
                shouldRefreshLinksRef

            }}
        >
            {children}
        </LinkContext.Provider>
    );
};

export const useLinkContext = () => useContext(LinkContext);

export default LinkContextProvider;
