import React from "react";
import styled from "styled-components";
import { ALL_USERS, NEW_USERS, REFERRALS, USERS } from "../../constants/OptionsConstants";
import OptionSelectComponent from "../../components/Generic/OptionSelectComponent";

const NotificationOptionLine = ({ notificationType, setNotifificationType, }) => {
    const OPTIONS_LIST = [
        {
            text: "Existing users",
            value: ALL_USERS,
        },
        {
            text: "Future Users",
            value: NEW_USERS,
        },
    ];


    return (
        <Container>
            <OptionSelectComponent
                optionsList={OPTIONS_LIST}
                selectedOption={notificationType}
                setSelectedOption={setNotifificationType}
            />

        </Container>
    );
};

export default NotificationOptionLine;
const Container = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
`;
