import React, { useState } from "react";
import styled from "styled-components";
import { lightThemeColors } from "../../assets/styles/colors";
import DatePickerWithOptions from "../linksComponents/DatePickerWithOptions";
import PaymentScreenAudienceOverview from "./SubscriptionAudienceOverview";
import { settingsOverviewSelectOptions } from "../../constants/DatePickerSelectOptions";
import moment from "moment";

const EngagementOverview = () => {
  const startOfMonth = moment().utc().startOf("month")
  const endOfMonth = moment().utc().endOf("day")
  const [startDate, setStartDate] = useState(new Date(startOfMonth));
  const [endDate, setEndDate] = useState(new Date(endOfMonth));

  return (
    <Container>
      <Line>
        <TitleWrapper>
          <h1>Usage Overview</h1>
        </TitleWrapper>
        <div style={{ marginLeft: "auto" }}>
          <DatePickerWithOptions
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            selectMenuOptions={settingsOverviewSelectOptions}
            disableCustomDate
            initialMonths={{ value: 98, label: "Current month" }}
          />
        </div>
      </Line>
      <PaymentScreenAudienceOverview
        startDate={startDate}
        endDate={endDate}
        settingsView
      />
    </Container>
  );
};

export default EngagementOverview;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  h1 {
    color: ${lightThemeColors.darkColorFaded};
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
  }
`;
const Line = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const ElementWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0 15px;
  h1 {
    color: ${lightThemeColors.darkColorFaded};
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    text-align: left;
  }
  p {
    color: ${lightThemeColors.darkColorFaded30};
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
  }
`;
