import React, { useEffect, useRef, useState } from "react";
import { lightThemeColors } from "../../assets/styles/colors";
import styled, { keyframes } from "styled-components";
import { ReactComponent as CloseModalIcon } from "../../assets/icons/generic/CloseModalIcon.svg";
import AudienceAttributes from "./AudienceAttributes";
import AudienceAnalytics from "./AudienceAnalytics";

import { motion } from "framer-motion";
import { useVisitorContext } from "../../context/useVisitorsContext";
import { useInstanceContext } from "../../context/useInstanceContext";
import { useOutletContext } from "react-router-dom";
import { showGenericError } from "../../helper/Notifications";

const AudienceModal = ({
  isMoldaOpen,
  setIsModalOpen,
  selectedAudienceInfo,
}) => {
  const { selectedProject } = useInstanceContext();
  const { getVisitorDetails } = useVisitorContext();
  const { setLoading } = useOutletContext();
  const [metrics, setMetrics] = useState(null);
  const [agregatedMetrics, setAgregatedMetrics] = useState(null);
  const [visitorInfo, setVisitorInfo] = useState(null);

  const ref = useRef();

  const handleGetVisitorDetails = () => {
    setLoading(true);
    getVisitorDetails(
      selectedProject.id,
      selectedAudienceInfo.id,
      (response) => {
        const resp = response.data;
        setVisitorInfo(resp.visitor);
        console.log("resp", resp);
        setMetrics(resp.metrics[0]);
        var aggregated = resp.aggregated_metrics[0];
        aggregated["number_of_generated_links"] =
          resp["number_of_generated_links"];

        setAgregatedMetrics(aggregated);
        setLoading(false);
      },
      (error) => {
        setLoading(false);
        console.log(error);
        showGenericError();
      }
    );
  };

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (setIsModalOpen && ref.current && !ref.current.contains(e.target)) {
        setIsModalOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [setIsModalOpen]);

  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        setIsModalOpen(false);
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, []);

  useEffect(() => {
    handleGetVisitorDetails();
  }, []);

  return (
    <FullscreenContainer>
      <Modal
        ref={ref}
        as={motion.div}
        initial={{ translateX: "100%" }}
        animate={{ translateX: "0" }}
        exit={{ translateX: "100%" }}
        transition={{ duration: 0.4 }}
      >
        <Line style={{ padding: "0 40px" }}>
          <HeadWrapper>
            <h1>User details</h1>
          </HeadWrapper>
          <CloneBtn
            onClick={() => setIsModalOpen(false)}
            style={{ marginLeft: "auto" }}
          >
            <CloseModalIcon />
          </CloneBtn>
        </Line>

        <ConfigList>
          <AudienceAttributes visitorInfo={visitorInfo} />
          <AudienceAnalytics
            metrics={metrics}
            agregatedMetrics={agregatedMetrics}
            selectedAudienceInfo={selectedAudienceInfo}
          />
        </ConfigList>
      </Modal>
    </FullscreenContainer>
  );
};

export default AudienceModal;

const FullscreenContainer = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  z-index: 3;
`;
const expandAnimation = keyframes`
0% { transform:translateX(100%)}

100% {
   transform: translateX(0)
}

`;

const Modal = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background: ${lightThemeColors.background};
  max-width: 750px;
  width: 100%;
  height: 100%;
  margin-left: auto;
  padding: 40px 0 0 0;
  gap: 30px;
  overflow: auto;
  .control {
    border-radius: 5px;
  }
`;

const CloneBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  border: none;
  background: none;
  cursor: pointer;
`;
const HeadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  h1 {
    color: ${lightThemeColors.darkColorFaded};
    font-size: 25px;
    font-weight: 700;
    line-height: 37.5px;
  }
  p {
    color: ${lightThemeColors.darkColorFaded30};
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
  }
`;

const Line = styled.div`
  display: flex;
  gap: 30px;
  align-items: center;
`;

const ConfigList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  overflow: auto;
  position: relative;
  height: 100%;
  padding: 0 40px 30px 40px;
`;
