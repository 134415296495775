import React, { useEffect, useImperativeHandle, useRef, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import Quill styles
import styled from "styled-components";
import { TEXT } from "../../constants/OptionsConstants";

const QuillCustomComponent = ({
  handleSelectCurrentNode,
  state,
  handleAddtoRefList, dropIndex,
  initialContent
}) => {

  const ref = useRef(null);

  const [editorContent, setEditorContent] = useState(initialContent || "Enter your text here");
  const [pFontSize, setPFontSize] = useState(state.pFontSize);
  const [h1FontSize, setH1FontSize] = useState(state.h1FontSize);
  const [h2FontSize, setH2FontSize] = useState(state.h2FontSize);
  const [h3FontSize, setH3FontSize] = useState(state.h2FontSize);

  const handleChange = (value) => {
    setEditorContent(value); // This will contain the HTML content
  };

  const modules = {
    toolbar: false,
  };

  const customQuill =
    <ReactQuill
      ref={ref}
      style={{ width: "100%" }}
      modules={modules}
      value={editorContent}
      onChange={handleChange}
    />

  useEffect(() => {
    setH1FontSize(state.h1FontSize);
    setH2FontSize(state.h2FontSize);
    setH3FontSize(state.h3FontSize);
    setPFontSize(state.pFontSize);
  }, [state]);

  useEffect(() => {
    handleAddtoRefList(ref.current, dropIndex)
  }, []);

  return (
    <EditorWrapper
      onClick={() => handleSelectCurrentNode(ref.current, TEXT)}
      pFontSize={pFontSize}
      h1FontSize={h1FontSize}
      h2FontSize={h2FontSize}
      h3FontSize={h3FontSize}

    >
      {customQuill}
    </EditorWrapper>
  );
};

export default QuillCustomComponent;

const EditorWrapper = styled.div`
  display: flex;
  width: 100%;

  .ql-editor {
    padding: 0;
  }

  .ql-container {
    border: none;
  }
  .ql-editor p {
    font-size: ${(props) => props.pFontSize}px;
  }
  .ql-editor h1 {
    font-size: ${(props) => props.h1FontSize}px;
  }
  .ql-editor h2 {
    font-size: ${(props) => props.h2FontSize}px;
  }
  .ql-editor h3 {
    font-size: ${(props) => props.h3FontSize}px;
  }
`;
