import React from "react";
import styled from "styled-components";
import { ACTIVE, INACTIVE, APPS, WEB, WEB_SWITCH, SDK } from "../../constants/OptionsConstants";
import OptionSelectComponent from "../../components/Generic/OptionSelectComponent";

const LinksOptionsLine = ({ linksType, setLinksType, }) => {
  const OPTIONS_LIST = [
    {
      text: "Active",
      value: ACTIVE,
    },
    {
      text: "Inactive",
      value: INACTIVE,
    },
  ];



  return (
    <Container>
      <OptionSelectComponent
        optionsList={OPTIONS_LIST}
        selectedOption={linksType}
        setSelectedOption={setLinksType}
      />

    </Container>
  );
};

export default LinksOptionsLine;
const Container = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
`;
