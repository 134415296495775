import React from "react";
import styled from "styled-components";
import { lightThemeColors } from "../../assets/styles/colors";

const SubcategoryTitleWrapperWithNumber = ({ number, text }) => {
  return (
    <Wrapper>
      <TitleWrapper>
        {number && (
          <h1>
            <span>{number}</span>
          </h1>
        )}
        <h1>{text}</h1>
      </TitleWrapper>
    </Wrapper>
  );
};

export default SubcategoryTitleWrapperWithNumber;
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  border-radius: 5px;
  overflow: hidden;
  border: 1px solid ${lightThemeColors.menuBorder};
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 2px 8px;
  width: fit-content;
  background: ${lightThemeColors.menuBackground};
  gap: 10px;
  h1 {
    color: ${lightThemeColors.darkColorFaded};
    font-size: 15px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
  }
  span {
    color: ${lightThemeColors.primaryColor};
    font-weight: 900;
  }
`;

const NumberWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 20px;
  width: fit-content;
  background: ${lightThemeColors.menuBackground};

  h1 {
    color: ${lightThemeColors.primaryColor};
    font-size: 26px;
    font-weight: 900;
    line-height: 30px;
    text-align: left;
  }
`;
