import { GET, POST } from "../../API";

let source;
export const getProjectDomainAPICall = (
    projectId,
    onSuccess,
    onError
) => {

    source = GET(
        process.env.REACT_APP_API_PATH + `/projects/${projectId}/domain`,
        onSuccess,
        onError,
        true,
        source
    );
};


export const setSubdomainAPICall = (
    projectId,
    formData,
    onSuccess,
    onError
) => {

    const data = formData

    POST(
        process.env.REACT_APP_API_PATH + `/projects/${projectId}/domain`,
        data,
        onSuccess,
        onError
    );
};


export const verifySubdomainAvailabilityAPICall = (
    projectId,
    subdomain,
    onSuccess,
    onError
) => {

    const data = {
        subdomain: subdomain,
    }

    POST(
        process.env.REACT_APP_API_PATH + `/projects/${projectId}/subdomain_available`,
        data,
        onSuccess,
        onError
    );
};
