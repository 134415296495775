import { Draggable, Droppable } from "react-beautiful-dnd";
import styled from "styled-components";
import { lightThemeColors } from "../../assets/styles/colors";
import { ReactComponent as DraggableIcon } from "../../assets/icons/notificationTemplate/DraggableSeparatorIcon.svg"
import { ReactComponent as DeleteIcon } from "../../assets/icons/generic/removeIcon.svg"

const Canvas = ({ items, activeElementIndex, setActiveElementIndex, handleRemoveNode, pageBackground }) => {

    return (
        <Droppable droppableId="board">
            {(provided) => (
                <Board
                    pageBackground={pageBackground}
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                >
                    {items.map(({ id, content, state }, index) => {

                        return (
                            <Draggable key={id} draggableId={`draggable-${id}`} index={index}  >
                                {(provided) => (
                                    <ItemWrapper
                                        onClick={() => setActiveElementIndex(id)}
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={{
                                            ...provided.draggableProps.style,
                                        }}
                                    >
                                        {activeElementIndex === id &&
                                            <SideMenu>
                                                <DragBox >
                                                    <DraggableIcon />
                                                </DragBox>
                                                <DragBox onClick={() => handleRemoveNode(id)}>
                                                    <DeleteIcon />
                                                </DragBox>
                                            </SideMenu>
                                        }
                                        {content}
                                    </ItemWrapper>
                                )}
                            </Draggable>
                        )
                    })}

                    {provided.placeholder}
                </Board>
            )}
        </Droppable>
    );
};

export default Canvas;
const ItemWrapper = styled.div`
display:flex;
width:100%;
z-index:2;
position:relative;
`
const Board = styled.div`
display:flex;
flex-direction:column;
height:100%;
min-height:300px;
background:${props => props.pageBackground};
width:100%;
overflow:auto;
padding:20px;
`

const DragBox = styled.div`
display:flex;
align-items:center;
justify-content:center;
height:40px;
width:100%;
cursor:grab;
`
const SideMenu = styled.div`
border-radius:8px;
width:40px;
display:flex;
flex-direction:column;
background:${lightThemeColors.menuBackground};
position:absolute;
right:0;
box-shadow: 0 2px 5px rgba(43, 58, 84, 0.2);
justify-content:center;
align-items:center;
z-index:2;
`