import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import ConfigurationSectionHeader from '../configurationComponents/ConfigurationSectionHeader'
import { useExpanded } from '../../hooks/useExpanded'
import CustomInput from '../AuthenticationComponents/CustomInput'
import { SketchPicker } from 'react-color'
import { parseColorFromRgbaColorPicker } from '../../helper/Utils'
import { Item, Menu, useContextMenu } from 'react-contexify'
import { lightThemeColors } from '../../assets/styles/colors'

const SettingsList = ({
    pageBackground, setPageBackground,

    h1FontSize, setH1FontSize,
    h2FontSize, setH2FontSize,
    h3FontSize, setH3FontSize,
    pFontSize, setPFontSize,

    handlePageSettingsChange,
}) => {

    const [showMenuItem, setShowMenuItem] = useState("")

    const SETTINGS_MENU = 'settings-menu'
    const { show } = useContextMenu({
        id: SETTINGS_MENU

    });

    function displayMenu(e, showItem) {
        // put whatever custom logic you need
        // you can even decide to not display the Menu
        setShowMenuItem(showItem)
        show({
            event: e,
        });
    }

    useEffect(() => {
        handlePageSettingsChange("h1FontSize", h1FontSize)
    }, [h1FontSize])

    useEffect(() => {
        handlePageSettingsChange("h2FontSize", h2FontSize)
    }, [h2FontSize])

    useEffect(() => {
        handlePageSettingsChange("h3FontSize", h3FontSize)
    }, [h3FontSize])
    useEffect(() => {
        handlePageSettingsChange("pFontSize", pFontSize)
    }, [pFontSize])

    return (
        <Container>

            <ContentContainer>
                <SubcategoryContent>
                    <ColumnGroup>

                        <GroupDescription>
                            Page Background
                        </GroupDescription>
                        <ColorPickerContainer onClick={(e) => displayMenu(e, "pageBackground")} >
                            <ColorPickerInside style={{ background: pageBackground }} />
                        </ColorPickerContainer>
                    </ColumnGroup>
                    <ColumnGroup>

                        <CustomInput
                            type={"number"}
                            label={"H1 size"}
                            value={h1FontSize}
                            onChange={(e) => setH1FontSize(e.currentTarget.value)}
                        />
                        <CustomInput
                            type={"number"}
                            label={"H2 size"}
                            value={h2FontSize}
                            onChange={(e) => setH2FontSize(e.currentTarget.value)}
                        />
                        <CustomInput
                            type={"number"}
                            label={"H3 size"}
                            value={h3FontSize}
                            onChange={(e) => setH3FontSize(e.currentTarget.value)}
                        />
                        <CustomInput
                            type={"number"}
                            label={"P size"}
                            value={pFontSize}
                            onChange={(e) => setPFontSize(e.currentTarget.value)}
                        />

                    </ColumnGroup>
                </SubcategoryContent>
            </ContentContainer>


            <Menu id={SETTINGS_MENU}>
                {showMenuItem === "pageBackground" &&
                    <Item closeOnClick={false} style={{ padding: 0 }}>
                        <SketchPicker
                            width='300px'
                            color={pageBackground}
                            onChange={(color) => setPageBackground(parseColorFromRgbaColorPicker(color.rgb))}
                        />
                    </Item>
                }

            </Menu>


        </Container>
    )
}

export default SettingsList

const Container = styled.div`
display:flex;
flex-direction:column;
width:100%;
gap:10px;


  .contexify{
  padding:0 !important;
  }
.contexify_itemContent{
padding:0!important;
}
.contexify_itemContent:hover{
background-color:transparent!important;

}

`


const ContentContainer = styled.div`
  display: flex;
  overflow: hidden;
  flex-wrap: wrap;
  padding-bottom: 20px;
  row-gap: 20px;
`;
const SubcategoryContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
  max-width: 620px;
`;

const ColumnGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
`;
const ColorPickerContainer = styled.div`
width:50px;
height:50px;
border-radius:50px;
border:none;
position:relative;
cursor:pointer;
padding:3px;
background:${lightThemeColors.background};
  box-shadow: 0 2px 5px rgba(43, 58, 84, 0.2);
  margin-left:5px;
`
const ColorPickerInside = styled.div`
display:flex;
width:100%;
height:100%;
border-radius:50px;
`

const GroupDescription = styled.p`
  font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: ${lightThemeColors.darkColorFaded};

`
