import React from "react";
import { lightThemeColors } from "../../assets/styles/colors";
import styled from "styled-components";

import { copyToClipboard } from "../../helper/copy";
import { useInstanceContext } from "../../context/useInstanceContext";

const SceneDelegateText = ({ ref, onMouseMove }) => {
    const { selectedInstance } = useInstanceContext();

    const indent = "              "
    return (
        <TextCodeContainer ref={ref} onMouseMove={onMouseMove} id="iosSDKText">

            <span data-tooltip-id={"iosSDK-tooltip"}
                data-tooltip-content={"Click text to copy"}
                onClick={(e) => copyToClipboard(e.currentTarget.innerText)}>
                {`import Linksquared`}
            </span>
            <br />

            <h2>{`class SceneDelegate: UIResponder, UIWindowSceneDelegate {

    func scene(_ scene: UIScene, willConnectTo session: UISceneSession, options connectionOptions: UIScene.ConnectionOptions) {
        // ... other code...

        // Initialize SDK`}</h2>
            <span data-tooltip-id={"iosSDK-tooltip"}
                data-tooltip-content={"Click text to copy"}
                onClick={(e) => copyToClipboard(e.currentTarget.innerText)}>
                {indent + `Linksquared.configure(APIKey: "${selectedInstance.api_key}", delegate: self)`}</span>
            <h2>{`
        // Handle URL
        Linksquared.handleSceneDelegate(options: connectionOptions)
    }


    func scene(_ scene: UIScene, continue userActivity: NSUserActivity) {
        // Handle URL`}</h2>
            <span data-tooltip-id={"iosSDK-tooltip"}
                data-tooltip-content={"Click text to copy"}
                onClick={(e) => copyToClipboard(e.currentTarget.innerText)}>
                {indent + `Linksquared.handleSceneDelegate(continue: userActivity) `}</span>
            <h2>{` }

    func scene(_ scene: UIScene, openURLContexts URLContexts: Set<UIOpenURLContext>) {
        // Handle URL`}</h2>
            <span data-tooltip-id={"iosSDK-tooltip"}
                data-tooltip-content={"Click text to copy"}
                onClick={(e) => copyToClipboard(e.currentTarget.innerText)}>
                {indent + `Linksquared.handleSceneDelegate(openURLContexts: URLContexts)`}</span>
            <h2>{`      }
}

extension SceneDelegate: LinksquaredDelegate {`}</h2>
            <span data-tooltip-id={"iosSDK-tooltip"}
                data-tooltip-content={"Click text to copy"}
                onClick={(e) => copyToClipboard(e.currentTarget.innerText)}>
                {indent + `func linksquaredReceivedPayloadFromDeeplink(payload: [String : Any]) { 
                     print("Recived payload:")
                     debugPrint(payload)
    }`}</span>
            <h2>{`}`}</h2>
        </TextCodeContainer>
    );

};

export default SceneDelegateText;

const TextCodeContainer = styled.pre`
  display: flex;
  flex-direction: column;
  border: 1px solid ${lightThemeColors.menuBorder};
  border-radius: 5px;
  padding: 16px;
  width: 100%;
  overflow: hidden;
  white-space: pre-wrap;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: ${lightThemeColors.darkColorFaded60};
  h2 {
    white-space: pre-wrap;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: ${lightThemeColors.darkColorFaded60};
  }
  span {
   &:hover{
    background:${lightThemeColors.sidebar.linkBg};
    }
    white-space: pre-wrap;
    width: fit-content;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    font-weight: 600;
    color: ${lightThemeColors.primaryColor};
  }
`;
