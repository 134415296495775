import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { lightThemeColors } from "../../assets/styles/colors";
import ConfigurationSectionHeader from "../configurationComponents/ConfigurationSectionHeader";
import KeyPairValues from "./KeyPairValues";
import SecondaryButton from "../Generic/SecondaryButton";
import { ReactComponent as PlusSvg } from "../../assets/icons/generic/plus.svg";
import cloneDeep from "clone-deep";
import { useExpanded } from "../../hooks/useExpanded";

const DataNewLink = ({ keyValuePair, setKeyValuePair, noLabel, disabledActions }) => {
  const [expanded, expand] = useExpanded(false);

  const handleAddKeyValuePair = () => {
    let newKeyValuePair = {
      key: "",
      value: "",
    };

    setKeyValuePair([...keyValuePair, newKeyValuePair]);
  };

  const handleRemoveItem = (index) => {
    let list = cloneDeep(keyValuePair);
    list.splice(index, 1);
    setKeyValuePair(list);

  };


  const handleChangeKeyValuePair = (index, pair) => {
    let clone = cloneDeep(keyValuePair);
    clone[index] = pair;
    setKeyValuePair(clone);
  };

  return (
    <Container>
      <ConfigurationSectionHeader
        title={"Data"}
        handleExpand={expand}
        expanded={expanded}
        optionalLabel={!noLabel}
      />

      {expanded && (
        <ContentContainer>
          <SubcategoryContent>
            {keyValuePair?.length > 0 && (
              <Line>
                <LineHeader style={{ maxWidth: "286px" }}>
                  <ColumnGroup>
                    <SmallTitle>Key</SmallTitle>
                  </ColumnGroup>
                </LineHeader>
                <div style={{ display: "flex", minWidth: "4px" }}></div>
                <LineHeader>
                  <ColumnGroup>
                    <SmallTitle>Value</SmallTitle>
                  </ColumnGroup>
                </LineHeader>
              </Line>
            )}

            <ColumnGroup>
              {keyValuePair?.map((item, index) => (
                <KeyPairValues
                  key={index}
                  index={index}
                  placeholderKey={"Key name"}
                  placeholderValue={"Value"}
                  keyValueItem={item}
                  handleRemove={(e) => handleRemoveItem(index)}
                  handleChangeKeyValuePair={handleChangeKeyValuePair}
                  disabled={disabledActions}
                />
              ))}
            </ColumnGroup>
            {!disabledActions &&
              <AddKeyValueContainer>
                <SecondaryButton
                  style={{ width: "100%" }}
                  icon={<PlusSvg />}
                  text={"Add Key/Value Pair"}
                  onClick={() => handleAddKeyValuePair()}
                  disabled={disabledActions}
                />
              </AddKeyValueContainer>
            }
          </SubcategoryContent>
        </ContentContainer>
      )}
    </Container>
  );
};

export default DataNewLink;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: ${lightThemeColors.background};
  width: 100%;
  height: 100%;
  gap: 30px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 20px;
  row-gap: 20px;
  height: 100%;
`;
const SubcategoryContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
  padding: 0 1px;
`;
const Line = styled.div`
  display: flex;
  align-items: center;
  gap: 25px;
  width: 100%;
`;

const ColumnGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`;

const SmallTitle = styled.h1`
  color: ${lightThemeColors.darkColorFaded};
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
`;
const SmallSubtitle = styled.h1`
  color: ${lightThemeColors.primaryColor};
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
`;

const LineHeader = styled.div`
  display: flex;
  width: 100%;
`;

const AddKeyValueContainer = styled.div`
  margin-top: 15px;
`;
