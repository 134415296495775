import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import TestProdHeader from "../../components/Generic/TestProdHeader";
import { Outlet, useOutletContext } from "react-router-dom";
import NotificationOptionLine from "./NotificationOptionLine";
import NotificationPageContent from "./NotificationPageContent";
import { useNotificationsContext } from "../../context/useNotificationsContext";
import { ALL_USERS, NEW_USERS, USERS } from "../../constants/OptionsConstants";
import { useInstanceContext } from "../../context/useInstanceContext";
import { showGenericError } from "../../helper/Notifications";
import moment from "moment";
import PrimaryButton from "../../components/Generic/PrimaryButton";
import { ReactComponent as PlusIcon } from "../../assets/icons/generic/plus.svg";
import { AnimatePresence } from "framer-motion";
import CreateNewMessageModal from "./CreateNewMessageModal";
import MessageCreator from "./MessageCreator";
import PreviewComponent from "../../components/TemplaterComponents/PreviewComponent";

const MessagesPage = () => {
  const initialSort = {
    sortKey: "title",
    ascendent: false,
  };

  const { setLoading } = useOutletContext();

  const { projectType, setProjectType } = useOutletContext();
  const { getNotifications, setNotifications, archiveNotification } =
    useNotificationsContext();
  const { selectedProject } = useInstanceContext();
  const [addNewMessageModalOpen, setAddNewMessageModalOpen] = useState(false);
  const [isContentCreatorOpen, setIsContentCreatorOpen] = useState(false);

  const [generatedHtml, setGeneratedHtml] = useState(null);
  const [generatedHtmlPreview, setGeneratedHtmlPreview] = useState(null);

  const [notificationType, setNotifificationType] = useState(ALL_USERS);
  const [notificationsList, setNotificationsList] = useState([]);

  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [sort, setSort] = useState(initialSort);

  const [isPreviewOpen, setIsPreviewOpen] = useState(false);

  const [searchInputValue, setSearchInputvalue] = useState("");
  const today = new Date();

  const initialDateStart = moment(today)
    .subtract(29, "day")
    .startOf("day")
    .utc();
  const initialDateEnd = moment(today).endOf("day").utc();

  const [startDate, setStartDate] = useState(new Date(initialDateStart));
  const [endDate, setEndDate] = useState(new Date(initialDateEnd));

  const savedResultListRef = useRef([]);
  const savedContentListRef = useRef([]);
  const savedPageSettingsRef = useRef();

  const [readMessage, setReadMessage] = useState(null);
  const [isRemoveConfirmOpen, setIsRemoveConfirmOpen] = useState(false);

  const handleOpenCreateNewMessage = () => {
    setAddNewMessageModalOpen(true);
    setGeneratedHtml(null);
    setGeneratedHtmlPreview(null);
    setReadMessage(null);
  };

  const handleSearchInputValue = (inputValue) => {
    setSearchInputvalue(inputValue);
  };

  const handleGetMessages = (
    page,
    forNewUsers,
    archived,
    term,
    startDate,
    endDate
  ) => {
    var dataObject = {
      page: page,
      for_new_users: forNewUsers,
      archived: archived,
      start_date: moment(startDate).endOf("day").toISOString(),
    };

    if (searchInputValue != "") {
      dataObject.term = term;
    }

    if (endDate) {
      dataObject.end_date = moment(endDate).endOf("day").toISOString();
    }

    setLoading(true);

    getNotifications(
      selectedProject.id,
      dataObject,
      (response) => {
        setNotificationsList(response.data.notifications);
        setTotalPages(response.data.total_pages);
        setLoading(false);
      },
      (error) => {
        showGenericError();
        setLoading(false);
      }
    );
  };

  const handleAddContentButton = () => {
    setIsContentCreatorOpen(true);
  };

  const handleRemoveMessage = (message) => {
    setLoading(true);
    archiveNotification(
      selectedProject.id,
      message.id,
      (response) => {
        setAddNewMessageModalOpen(false);
        setLoading(false);
        setReadMessage(null);
        getMessages();
      },
      (error) => {
        setLoading(false);
        showGenericError();
      }
    );
  };

  const getMessages = () => {
    if (notificationType === ALL_USERS) {
      handleGetMessages(
        page,
        false,
        false,
        searchInputValue,
        startDate,
        endDate
      );
    }

    if (notificationType === NEW_USERS) {
      handleGetMessages(
        page,
        true,
        false,
        searchInputValue,
        startDate,
        endDate
      );
    }
  };

  const handleClosePreview = () => {
    setIsPreviewOpen(false);
  };

  const fetchHtml = async (url) => {
    try {
      const response = await fetch("http://" + url);

      if (response.ok) {
        const html = await response.text();

        return html; // Save HTML as a string in state
      } else {
        console.error("Failed to fetch HTML:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching HTML:", error);
    }
  };

  const handleDownloadMessageContent = async (message) => {
    setLoading(true);
    try {
      let responseHtml = await fetchHtml(message.access_url);
      setGeneratedHtml(responseHtml);
      setGeneratedHtmlPreview(responseHtml);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const openMessageReadMode = (notification) => {
    setGeneratedHtml(null);
    setGeneratedHtmlPreview(null);
    setReadMessage(null);
    setReadMessage(notification);
    setAddNewMessageModalOpen(true);
  };

  useEffect(() => {
    if (!selectedProject) {
      return;
    }
    getMessages();
  }, [
    selectedProject,
    page,
    notificationType,
    startDate,
    endDate,
    searchInputValue,
  ]);

  return (
    <Container>
      <TestProdHeader
        title={"Messages"}
        option={projectType}
        setOption={setProjectType}
        customButton={
          <PrimaryButton
            icon={<PlusIcon />}
            text={"New Message"}
            onClick={() => handleOpenCreateNewMessage()}
          />
        }
      />

      <PageContent>
        <AnimatePresence>
          {addNewMessageModalOpen && (
            <CreateNewMessageModal
              isMoldaOpen={addNewMessageModalOpen}
              setIsModalOpen={setAddNewMessageModalOpen}
              handleAddContentButton={handleAddContentButton}
              generatedHtml={generatedHtml}
              setGeneratedHtml={setGeneratedHtml}
              savedResultListRef={savedResultListRef}
              getMessages={getMessages}
              savedContentListRef={savedContentListRef}
              setIsPreviewOpen={setIsPreviewOpen}
              readMessage={readMessage}
              handleDownloadMessageContent={handleDownloadMessageContent}
              handleRemoveMessage={handleRemoveMessage}
              isRemoveConfirmOpen={isRemoveConfirmOpen}
              setIsRemoveConfirmOpen={setIsRemoveConfirmOpen}
              generatedHtmlPreview={generatedHtmlPreview}
            />
          )}

          {isContentCreatorOpen && (
            <MessageCreator
              setIsModalOpen={setIsContentCreatorOpen}
              isMoldaOpen={isContentCreatorOpen}
              generatedHtml={generatedHtml}
              setGeneratedHtml={setGeneratedHtml}
              savedResultListRef={savedResultListRef}
              setShowPreview={setIsPreviewOpen}
              savedContentListRef={savedContentListRef}
              savedPageSettingsRef={savedPageSettingsRef}
              setGeneratedHtmlPreview={setGeneratedHtmlPreview}
            />
          )}

          {isPreviewOpen && (
            <PreviewComponent
              setShowPreview={setIsPreviewOpen}
              generatedHtmlPreview={generatedHtmlPreview}
              handleClosePreview={handleClosePreview}
            />
          )}
        </AnimatePresence>

        <NotificationOptionLine
          notificationType={notificationType}
          setNotifificationType={setNotifificationType}
        />

        <NotificationPageContent
          page={page}
          setPage={setPage}
          totalPages={totalPages}
          notificationsList={notificationsList}
          sort={sort}
          setSort={setSort}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          searchInputValue={searchInputValue}
          handleSearchInputValue={handleSearchInputValue}
          notificationType={notificationType}
          handleRemoveMessage={handleRemoveMessage}
          handleMessageClick={openMessageReadMode}
        />
      </PageContent>
    </Container>
  );
};

export default MessagesPage;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: hidden;
  gap: 0px;
  h2 {
    width: 100%;
  }
`;

const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  overflow: auto;
  padding-bottom: 30px;
  padding: 0 50px;
  position: relative;
  height: 100%;
  padding-top: 20px;
`;
