import React, { useState } from "react";
import styled from "styled-components";
import { lightThemeColors } from "../../assets/styles/colors";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

import EmailRolePair from "./EmailRolePair";
import SecondaryButton from "../Generic/SecondaryButton";
import PrimaryButton from "../Generic/PrimaryButton";
import InputFieldWithValidation from "../Generic/InputFieldWithValidation";
import { cloneDeep } from "lodash";
import { showGenericError } from "../../helper/Notifications";
import { useInstanceContext } from "../../context/useInstanceContext";
import { isEmailValid } from "../../helper/ValidatorsHelper";

const CreateFirstProjectModal = ({ setLoading }) => {
  const { createInstance, setSelectedInstance, getInstances } =
    useInstanceContext();

  const [projectName, setProjectName] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [emailRolePair, setEmailRolePail] = useState([]);
  const navigate = useNavigate();

  const handleCreateInstance = () => {
    const parsedMembers = emailRolePair.map((item) => {
      return {
        ...item,
        role: item.role.value
      }

    })
    setLoading(true);
    createInstance(
      projectName,
      parsedMembers,
      (reponse) => {
        const resp = reponse.data.instance;
        setSelectedInstance(resp);
        setSearchParams((params) => {
          params.set("instance_id", resp.id);
          return params;
        });

        navigate({
          pathname: "/developers",
          search: createSearchParams(searchParams).toString(),
        });

        getInstances(
          (reponse) => {
            setLoading(false);
          },
          () => {
            setLoading(false);
            showGenericError();
          }
        );
      },
      () => {
        setLoading(false);
        showGenericError();
      }
    );
  };

  const handleAddKeyValuePair = () => {
    let newEmailRolePair = {
      email: "",
      role: { value: "member", label: "Member" },
    };

    setEmailRolePail([...emailRolePair, newEmailRolePair]);
  };

  const handleChangeEmailRolePair = (index, pair) => {
    let clone = cloneDeep(emailRolePair);
    clone[index] = pair;
    setEmailRolePail(clone);
  };
  const handleRemovePair = (index) => {
    let clone = cloneDeep(emailRolePair);
    clone.splice(index, 1);
    setEmailRolePail(clone);
  };
  const hasInvalidEmailMember = () => {
    let invalidEmail = emailRolePair.some((item) => !isEmailValid(item.email))
    return invalidEmail
  }
  return (
    <FullscreenContainer>
      <Card>
        <TitleContainer>
          <h2>Create your first project</h2>
        </TitleContainer>
        <ColumnGroup>
          <InputFieldWithValidation
            title={"Project name"}
            noValidation
            inputName={"project_name"}
            handleInputChange={(e) => setProjectName(e.currentTarget.value)}
          />
          <Line>
            <Subtitle>Invite members</Subtitle>
            <OptionalLabel>
              <p>OPTIONAL</p>
            </OptionalLabel>
          </Line>
          {emailRolePair.map((pair, index) => (
            <EmailRolePair
              key={index}
              index={index}
              emailRoleItem={pair}
              handleChangeEmailRolePair={handleChangeEmailRolePair}
              handleRemove={handleRemovePair}
            />
          ))}
          <SecondaryButton
            style={{ width: "100%" }}
            text={"Add Member"}
            onClick={() => handleAddKeyValuePair()}
          />
        </ColumnGroup>

        <PrimaryButton
          disabled={projectName.length < 2 || hasInvalidEmailMember()}
          styled={{ width: "100%", padding: "12px" }}
          selectOptions
          text={"Create Project"}
          onClick={() => handleCreateInstance()}
        />
      </Card>
    </FullscreenContainer>
  );
};

export default CreateFirstProjectModal;
const FullscreenContainer = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: ${lightThemeColors.background};
  display: flex;
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  padding: 50px;
  align-items: center;
  min-width: 530px;
  background: ${lightThemeColors.background};
  min-height: 550px;
  gap: 30px;
  border-radius: 10px;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  box-shadow: 0px 0px 40px 0px rgba(151, 168, 190, 0.2);
  z-index: 1;
  height: fit-content;
  width: fit-content;
`;
const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 20px;
  width: 100%;
  h1 {
    color: ${lightThemeColors.darkColorFaded};
    font-weight: 900;
    font-size: 25px;
  }
  h2 {
    color: ${lightThemeColors.darkColorFaded};
    font-weight: 700;
    font-size: 25px;
    line-height: 38px;
  }
`;

const ColumnGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
`;

const OptionalLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px 20px;
  background: ${lightThemeColors.sidebar.optionalBg};
  border-radius: 2px;
  p {
    color: ${lightThemeColors.primaryColor};
    font-size: 10px;
    font-weight: 600;
    line-height: 15px;
    text-align: left;
    text-transform: uppercase;
  }
`;
const Line = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;
const Subtitle = styled.p`
  color: ${lightThemeColors.darkColorFaded};
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
`;
