import React from 'react'
import styled from 'styled-components'
import { lightThemeColors } from '../../assets/styles/colors'
import { ReactComponent as FacebookIcon } from "../../assets/icons/socialMediaIcons/facebookIcon.svg"
import { ReactComponent as XIcon } from "../../assets/icons/socialMediaIcons/xIcon.svg"
import { ReactComponent as LinkedInIcon } from "../../assets/icons/socialMediaIcons/linkedinIcon.svg"
import { FACEBOOK, LINKEDIN, X } from '../../constants/OptionsConstants'


const SocialMediaButtonsGroup = ({ selectedOption, setSelectedOption }) => {

    const socialMediaButtons = [
        {
            value: FACEBOOK,
            icon: <FacebookIcon />
        },
        {
            value: X,
            icon: <XIcon />
        },
        {
            value: LINKEDIN,
            icon: <LinkedInIcon />
        },
    ]

    return (
        <Container>
            {socialMediaButtons.map((btn, index) => (
                <SocialMediaElement
                    key={index}
                    onClick={() => setSelectedOption(btn.value)}
                    selected={selectedOption === btn.value}
                >
                    {btn.icon}
                </SocialMediaElement>
            ))
            }
        </Container >
    )
}

export default SocialMediaButtonsGroup

const Container = styled.div`
display:flex;
border-radius:5px;
min-height:34px;
width:fit-content;
align-items:center;
overflow:hidden;
`
const SocialMediaElement = styled.div`
display:flex;
align-items:center;
justify-content:center;
padding:7px;
background:${(props) => props.selected ? lightThemeColors.socialMediaPreviewCard.buttonBgSelected : lightThemeColors.socialMediaPreviewCard.buttonBg};
cursor:pointer;

svg{
opacity:${(props) => props.selected ? 1 : 0.3};
height:20px;
width:20px;
}
`