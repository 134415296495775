import axios from "axios";
import LocalStorage from "../../helper/LocalStorage";




var contactLinksquaredConfig = (data = undefined) => {
    var config = {
        method: "POST",
        url: `https://linksquared.services-appssemble.top/actions/send_contact_email`,
        headers: {
            Authorization: `Bearer ${LocalStorage.getAuthenticationToken()}`,
        },
        data: data
    };
    return config;
};

export const contactLinkSquaredAPICall = (data, onSuccess, onError) => {
    axios(contactLinksquaredConfig(data))
        .then(function (response) {
            onSuccess(response);
        })
        .catch(function (error) {
            onError(error);
        });
};
