import React, { useState } from "react";
import styled from "styled-components";
import { lightThemeColors } from "../../assets/styles/colors";
import ConfigurationSectionHeader from "../../components/configurationComponents/ConfigurationSectionHeader";
import InputFieldWithHiddenContent from "../../components/Generic/InputFieldWithHiddenContent";
import SecondaryButton from "../../components/Generic/SecondaryButton";
import { ReactComponent as CopyIcon } from "../../assets/icons/generic/CopyIcon.svg";
import { ReactComponent as AccesKeyIcon } from "../../assets/icons/generic/AccesKeysIcon.svg";
import { useExpanded } from "../../hooks/useExpanded";
import { useInstanceContext } from "../../context/useInstanceContext";
import { copyToClipboard } from "../../helper/copy";
import SubcategoryTitleWrapperWithNumber from "../../components/Generic/SubcategoryTittleWrapperWithNumber";

const AccesKeysSetup = () => {
  const [expanded, expand] = useExpanded(false);
  const { selectedInstance } = useInstanceContext();

  const containerStyle = {
    maxWidth: "635px",
  };

  return (
    <Container>
      <ConfigurationSectionHeader
        icon={<AccesKeyIcon />}
        title={"Access Key"}
        handleExpand={expand}
        expanded={expanded}
      />

      {expanded && (
        <ContentContainer>
          <SubcategoryContent>
            <Line>
              <SubcategoryTitleWrapperWithNumber text="API Key" />
            </Line>
            <InfoText>
              This is the project’s API Key, this should only be shared with
              developers.
            </InfoText>
            <Line>
              <InputFieldWithHiddenContent
                readOnly
                inputValue={selectedInstance.api_key}
                style={containerStyle}
                customButton={
                  <SecondaryButton
                    icon={<CopyIcon />}
                    text={"Copy"}
                    onClick={() => copyToClipboard(selectedInstance.api_key)}
                  />
                }
              />
            </Line>
          </SubcategoryContent>
        </ContentContainer>
      )}
    </Container>
  );
};

export default AccesKeysSetup;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: ${lightThemeColors.background};
  width: 100%;
  gap: 20px;
  margin-top: 20px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding-bottom: 30px;
`;

const SubcategoryContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0 40px;
`;

const Line = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  p {
    min-width: fit-content;
    color: ${lightThemeColors.darkColor};
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
  }
`;
const InfoText = styled.p`
  color: ${lightThemeColors.inputSubtitleColor};
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
`;
