import React, { useEffect, useReducer, useRef, useState } from "react";
import styled, { keyframes } from "styled-components";
import { lightThemeColors } from "../../assets/styles/colors";
import { ReactComponent as CloseModalIcon } from "../../assets/icons/generic/CloseModalIcon.svg";
import SocialMediaNewLink from "./SocialMediaNewLink";
import PrimaryButton from "../Generic/PrimaryButton";

import DataNewLink from "./DataNewLink";
import DetailsNewLink from "./DetailsNewLink";

import SocialMediaButtonsGroup from "../configurationComponents/SocialMediaButtonsGroup";
import FacebookCardPreview from "../configurationComponents/FacebookCardPreview";
import {
  FACEBOOK,
  FILE,
  LINK,
  LINKEDIN,
  X,
} from "../../constants/OptionsConstants";
import SecondaryButton from "../Generic/SecondaryButton";
import { ReactComponent as CopyIcon } from "../../assets/icons/generic/CopyIcon.svg";
import GeneratedLink from "./GeneratedLink";
import { useLinkContext } from "../../context/useLinkContext";
import { useInstanceContext } from "../../context/useInstanceContext";
import {
  showErrorNotification,
  showGenericError,
} from "../../helper/Notifications";
import LableWithArrow from "../configurationComponents/LableWithArrow";
import XCardPreview from "../configurationComponents/XCardPreview";
import LinkedInCardPreview from "../configurationComponents/LinkedInCardPreview";
import { copyToClipboard } from "../../helper/copy";
import InputFieldWithValidation from "../Generic/InputFieldWithValidation";
import { isURL } from "validator";
import { motion } from "framer-motion";

const CreateNewLinkModal = ({ isMoldaOpen, setIsModalOpen }) => {
  const { createLink, getRandomPath, isPathAvailable, shouldRefreshLinksRef } =
    useLinkContext();
  const { selectedProject } = useInstanceContext();

  const [domain, setDomain] = useState("https://" + selectedProject.domain);

  const [linkName, setLinkName] = useState("");
  const [path, setPath] = useState("");

  const [socialMediaTitle, setSocialMediaTitle] = useState("");
  const [socialMediaSubTitle, setSocialMediaSubTitle] = useState("");
  const [imageFile, setImageFile] = useState();
  const [imageLink, setImageLink] = useState("");
  const [imageType, setImageType] = useState(FILE);
  const [tagInput, setTagInput] = useState("");
  const [imagePreview, setImagePreview] = useState();
  const [createdLink, setCreatedLink] = useState(null);
  const [pathAvailable, setPathAvailable] = useState(true);
  const [replaceImage, handleSetReplaceImage] = useState(false)

  const [tagsList, setTagsList] = useState([]);

  const [keyValuePair, setKeyValuePair] = useState([]);

  const [isLinkGenerated, setIsLinkGenerated] = useState(false);
  const [socialMediaCardType, setSocialMediaCardType] = useState(FACEBOOK);

  const ref = useRef();

  const inputContainerStyle = {
    background: "none",
    border: "none",
  };

  const inputCustomStyle = {
    background: "none",
    overflow: "hidden",
    whiteSpace: "wrap",
    textOverflow: "ellipsis",
    color: lightThemeColors.darkColor,
    fontSize: "18px",
    fontWeight: 500,
    lineHeight: "27px",
  };

  const handleSetImageType = (imageType) => {
    if (imageType === FILE) {
      setImageType(imageType);
      setImageLink("");
    }

    if (imageType === LINK) {
      setImageType(imageType);
      setImageFile(null);
    }
  };

  const handleSetImage = (e) => {
    setImageFile(e.currentTarget.files[0]);
    let preview = createPreview(e.currentTarget.files[0]);
    setImagePreview(preview);
  };

  const hasDuplicateKeys = (arr) => {
    const seenKeys = new Set();
    return arr.some((item) => {
      if (seenKeys.has(item.key)) {
        return true;
      }
      seenKeys.add(item.key);
      return false;
    });
  }

  const createPreview = (imageInput) => {
    if (!imageInput) {
      return;
    }
    let preview = URL.createObjectURL(imageInput);
    return preview;
  };


  const handleCreateLinks = () => {
    let formData = new FormData();
    formData.append("name", linkName);
    formData.append("path", path);

    if (socialMediaTitle != "") {
      formData.append("title", socialMediaTitle);
    }

    if (socialMediaSubTitle != "") {
      formData.append("subtitle", socialMediaSubTitle);
    }

    if (imageType === FILE) {
      if (imageFile) {
        formData.append("image", imageFile);
      }
    }

    if (imageType === LINK) {
      if (imageLink) {
        formData.append("image_url", imageLink);
      }
    }

    if (tagsList.length > 0) {
      formData.append("tags", JSON.stringify(tagsList));
    }

    if (keyValuePair.length > 0) {

      let dictionar = {}

      keyValuePair.forEach((item) => {
        if (item.value != "" && item.key != "") {
          Object.assign(dictionar, { [item.key]: item.value })
        }
      });

      if (Object.keys(dictionar).length > 0) {
        formData.append("data", JSON.stringify(dictionar));
      }

    }
    if (hasDuplicateKeys(keyValuePair)) {
      showErrorNotification("You have duplicate keys")
    } else {
      createLink(
        selectedProject.id,
        formData,
        (response) => {
          const link = response.data.link;
          setCreatedLink(link);
          setIsLinkGenerated(true);
          shouldRefreshLinksRef.current = true;
        },
        () => {
          showGenericError();
        }
      );
    }
  };

  const disabledCreateButton = () => {
    let validImageLink = true;
    if (imageLink) {
      validImageLink = isURL(imageLink);
    }

    if (linkName.length < 3 || path.length < 3 || !validImageLink) {
      return true;
    } else {
      return false;
    }
  };

  const displaySocialMediaCardForGeneratedLink = () => {
    if (!createdLink) {
      return;
    }
    var displayCard = <></>;
    switch (socialMediaCardType) {
      case FACEBOOK:
        displayCard = (
          <FacebookCardPreview
            img={createdLink?.image}
            title={createdLink?.title}
            domain={createdLink?.access_path}
            subtitle={createdLink?.subtitle}
          />
        );
        break;
      case X:
        displayCard = (
          <XCardPreview
            img={createdLink?.image}
            title={createdLink?.title}
            domain={createdLink?.access_path}
            subtitle={createdLink?.subtitle}
          />
        );

        break;
      case LINKEDIN:
        displayCard = (
          <LinkedInCardPreview
            img={createdLink?.image}
            title={createdLink?.title}
            domain={createdLink?.access_path}
            subtitle={createdLink?.subtitle}
          />
        );
        break;

      default:
        break;
    }
    return displayCard;
  };

  const createLinkContent = (
    <>
      <ConfigList>
        <PathTitle>
          <h2>URL</h2>
        </PathTitle>
        <LinkPathWrapper>
          <LinkWrapper>
            <p>{domain}</p>
          </LinkWrapper>
          <PathWrapper>
            <InputFieldWithValidation
              inputCustomStyle={inputCustomStyle}
              inputContainerStyle={inputContainerStyle}
              style={{ margin: 0 }}
              inputValue={path}
              valid={pathAvailable}
              handleInputChange={(e) => setPath(e.currentTarget.value)}
            />

          </PathWrapper>

        </LinkPathWrapper>
        <DetailsNewLink
          linkName={linkName}
          setLinkName={setLinkName}
          tagInput={tagInput}
          setTagInput={setTagInput}
          tagsList={tagsList}
          setTagsList={setTagsList}

        />
        <SocialMediaNewLink
          socialMediaTitle={socialMediaTitle}
          setSocialMediaTitle={setSocialMediaTitle}
          socialMediaSubTitle={socialMediaSubTitle}
          setSocialMediaSubTitle={setSocialMediaSubTitle}
          handleSetImage={handleSetImage}
          imageLink={imageLink}
          imageFile={imageFile}
          setImageLink={setImageLink}
          imageType={imageType}
          setImageType={handleSetImageType}
          tagInput={tagInput}
          setTagInput={setTagInput}
          tagsList={tagsList}
          setTagsList={setTagsList}
          domain={domain}
          path={path}
          imagePreview={imagePreview}
          replaceImage={replaceImage}
          handleSetReplaceImage={handleSetReplaceImage}
        />
        <DataNewLink
          keyValuePair={keyValuePair}
          setKeyValuePair={setKeyValuePair}
        />
        <PrimaryButton
          styled={{ width: "100%", marginTop: "auto", padding: "12px" }}
          text={"Create New Link"}
          onClick={() => handleCreateLinks()}
          disabled={disabledCreateButton()}
        />
      </ConfigList>
    </>
  );

  const newLinkGenerated = (
    <ConfigList>
      <LableWithArrow
        text={"Here's a preview of your social media appearance."}
        arrowDirection={"down"}
        reverse
      />
      <SocialMediaButtonsGroup
        selectedOption={socialMediaCardType}
        setSelectedOption={setSocialMediaCardType}
      />
      {displaySocialMediaCardForGeneratedLink()}
      <Separator />
      <Line style={{ alignItems: "end" }}>
        <GeneratedLink
          style={{ maxWidth: "500px" }}
          generatedLink={createdLink?.access_path}
        />
        <SecondaryButton
          icon={<CopyIcon />}
          text={"Copy Link"}
          onClick={() => copyToClipboard(createdLink?.access_path)}
        />
      </Line>
    </ConfigList>
  );

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (setIsModalOpen && ref.current && !ref.current.contains(e.target)) {
        setIsModalOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [setIsModalOpen]);

  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        setIsModalOpen(false);
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, []);

  useEffect(() => {
    getRandomPath(
      selectedProject.id,
      (response) => {
        setPath(response.data.valid_path);
      },
      () => {
        showGenericError();
      }
    );
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      isPathAvailable(
        selectedProject.id,
        path,
        (response) => {
          const available = response.data.available;
          setPathAvailable(available);
          if (!available) {
            showErrorNotification("Path is not available");
          }
        },
        (error) => {
          setPathAvailable(false);
          console.log(error);
        }
      );
    }, 250);
    return () => {
      clearTimeout(timeout);
    };
  }, [path]);

  useEffect(() => {
    if (imageType === LINK) {
      setImagePreview(imageLink);
    } else {
      let preview = createPreview(imageFile);
      setImagePreview(preview);
    }
  }, [imageType, imageLink, imageFile]);

  return (
    <FullscreenContainer>
      <InviteModal ref={ref}
        as={motion.div}
        initial={{ translateX: "100%" }}
        animate={{ translateX: "0" }}
        exit={{ translateX: "100%" }}
        transition={{ duration: 0.4 }}
      >
        <Line style={{ padding: "0 40px" }}>
          <HeadWrapper>
            {isLinkGenerated ? (
              <h1>Generated Link</h1>
            ) : (
              <h1>Create New Link</h1>
            )}
            {isLinkGenerated && (
              <p>
                Here is the link that has been newly generated for you and how
                your new link will appear when shared across various platforms.
              </p>
            )}
          </HeadWrapper>
          <CloneBtn
            onClick={() => setIsModalOpen(false)}
            style={{ marginLeft: "auto" }}
          >
            <CloseModalIcon />
          </CloneBtn>
        </Line>
        {isLinkGenerated ? newLinkGenerated : createLinkContent}
      </InviteModal>
    </FullscreenContainer>
  );
};

export default CreateNewLinkModal;
const FullscreenContainer = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  z-index: 3;
`;
const expandAnimation = keyframes`
0% { transform:translateX(100%)}

100% {
   transform: translateX(0)
}

`;

const InviteModal = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background: ${lightThemeColors.background};
  max-width: 750px;
  width: 100%;
  height: 100%;
  margin-left: auto;
  padding: 40px 0;
  // animation: ${expandAnimation} 400ms linear;
  gap: 40px;
  overflow: auto;
  .control {
    border-radius: 5px;
  }
`;

const CloneBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  border: none;
  background: none;
  cursor: pointer;
`;
const HeadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  h1 {
    color: ${lightThemeColors.darkColorFaded};
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
  }
  p {
    color: ${lightThemeColors.darkColorFaded30};
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
  }
`;
const Line = styled.div`
  display: flex;
  gap: 30px;
  align-items: center;

`;
const ConfigList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow: auto;
  position: relative;
  height: 100%;
  padding:0 40px 30px 40px;
`;
const LinkPathWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 60px;
  border-radius: 5px;
  overflow: hidden;
`;

const LinkWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 20px;
  background: ${lightThemeColors.sidebar.linkBg};
  width: 50%;
  min-height: 60px;
  p {
    overflow: hidden;
    white-space: wrap;
    text-overflow: ellipsis;
    color: ${lightThemeColors.primaryColor};
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
  }
`;
const PathWrapper = styled(LinkWrapper)`
  background: ${lightThemeColors.menuBackground};
  p {
    color: ${lightThemeColors.darkColor};
  }
`;
const Separator = styled.div`
  display: flex;
  height: 1px;
  min-height: 1px;
  width: 100%;
  background: ${lightThemeColors.darkColor};
  opacity: 0.1;
`;
const PathTitle = styled.div`
  h2 {
    font-size: 15px;
    font-weight: 600;
    color: ${lightThemeColors.darkColorFaded};
  }
`;
