import React, { useEffect } from "react";
import styled from "styled-components";

import AccesKeysSetup from "./AccesKeysSetup";
import AndroidSDK from "./AndroidSDK";
import IosSDK from "./IosSDK";

import WebSDK from "./WebSDK";
import { useOutletContext } from "react-router-dom";
import { useInstanceContext } from "../../context/useInstanceContext";
import { showGenericError } from "../../helper/Notifications";
import TestProdHeader from "../../components/Generic/TestProdHeader";
import { useConfigurationContext } from "../../context/useConfigurationContext";

const IntegrationsPage = () => {
  const { projectType, setProjectType, setLoading } = useOutletContext();

  const { selectedProject } =
    useInstanceContext();
  const { getProjectRedirects } = useConfigurationContext();

  const handleGetProjectRedirects = () => {
    setLoading(true);
    getProjectRedirects(
      selectedProject.id,
      (response) => {
        setLoading(false);
      },
      () => {
        showGenericError();
        setLoading(false);
      }
    );
  };


  useEffect(() => {
    if (!selectedProject) {
      return;
    }
    handleGetProjectRedirects();
  }, [selectedProject]);


  return (
    <Container>
      <TestProdHeader
        title={"Developers"}
        option={projectType}
        setOption={setProjectType}
        hideTestSwitch
      />

      <ConfigList >
        <AccesKeysSetup />
        <AndroidSDK />
        <IosSDK />
        <WebSDK />
      </ConfigList>
    </Container>
  );
};

export default IntegrationsPage;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow:hidden;
  h2 {
    width: 100%;
  }
  gap: 0px;
`;

const ConfigList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow: auto;
  padding-bottom: 30px;
  padding: 0px 50px 30px 50px;
  position: relative;
  height:100%;
`;
