import { capitalize } from "lodash";
import moment from "moment";
import React from "react";
import {
    Area,
    AreaChart,
    CartesianGrid,
    Legend,
    ResponsiveContainer,
    XAxis,
    YAxis,
    Tooltip,
} from "recharts";
import styled from "styled-components";
import { lightThemeColors } from "../../assets/styles/colors";

const SettingsPageOverview = ({ overviewMetrics, selectedMetrics }) => {
    const legendWrapperStyle = {
        width: "fit-content",
        top: 20,
        left: 20,
        backgroundColor: "transparent",
        lineHeight: "40px",
    };



    const mapColors = (dataKey) => {
        switch (dataKey) {
            case 0:
                return "#2A86FF";
            case 1:
                return "#B15CEA";
            case 2:
                return "#E65D6E";
            case 3:
                return "#71B7D4";
            case 4:
                return "#ECD6C3";
            case 5:
                return "#D9CBC7";
            case 6:
                return "#29CB27";
            default:
                break;
        }
    };

    const mapLegend = (value) => {

        let returnValue = "";

        switch (value) {

            case "dau":
                returnValue = "DAU (Daily Active Users)"
                break
            default:
                returnValue = value;
                returnValue = capitalize(returnValue);
                break;
        }

        return (
            <span
                style={{
                    color: "rgba(19, 36, 65, 1)",
                    paddingLeft: "4px",
                    paddingRight: "4px",
                    fontSize: "14px",
                }}
            >
                {returnValue}
            </span>
        );
    };

    const mapTooltip = (value, name) => {
        let returnName = "";
        switch (name) {
            case "dau":
                returnName = "Dau"
                break
            default:
                returnName = name;
                returnName = capitalize(returnName);
                break;
        }
        return [value, returnName];
    };

    const mappedData = (metrics) => {
        if (!metrics) {
            return;
        }

        const newData = Object.keys(metrics).map((element) => {
            const newDate = moment(element)
            return {
                dau: metrics[element],
                date: newDate.format("DD MMM, YYYY")
            };
        });

        return newData;
    };

    const data = mappedData(overviewMetrics);

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {

            const xAxisValue = payload[0].payload.date;

            return (
                <CustomTooltipContainer className="custom-tooltip">
                    <p className="label">{`Date: ${xAxisValue}`}</p>
                    <p className="intro">{`Dau: ${payload[0].value}`}</p>
                </CustomTooltipContainer>
            );
        }

        return null;
    };

    return (
        <ResponsiveContainerCustom width="100%" height="100%" minHeight={"200px"}>
            {data?.length > 0 ?
                <AreaChart
                    width={500}
                    height={200}
                    data={data}
                    margin={{
                        top: 40,
                        right: 20,
                        left: 20,
                        bottom: 0,
                    }}
                >
                    <defs>
                        <linearGradient id="0" x1="0" y1="0" x2="0" y2="2">
                            <stop offset="5%" stopColor="#2A86FF" stopOpacity={0.8} />
                            <stop offset="95%" stopColor="#2A86FF" stopOpacity={0} />
                        </linearGradient>
                        <linearGradient id="1" x1="0" y1="0" x2="0" y2="2">
                            <stop offset="5%" stopColor="#B15CEA" stopOpacity={0.8} />
                            <stop offset="95%" stopColor="#B15CEA" stopOpacity={0} />
                        </linearGradient>
                        <linearGradient id="2" x1="0" y1="0" x2="0" y2="2">
                            <stop offset="5%" stopColor="#E65D6E" stopOpacity={0.8} />
                            <stop offset="95%" stopColor="#E65D6E" stopOpacity={0} />
                        </linearGradient>
                        <linearGradient id="3" x1="0" y1="0" x2="0" y2="2">
                            <stop offset="5%" stopColor="#71B7D4" stopOpacity={0.8} />
                            <stop offset="95%" stopColor="#71B7D4" stopOpacity={0} />
                        </linearGradient>
                        <linearGradient id="4" x1="0" y1="0" x2="0" y2="2">
                            <stop offset="5%" stopColor="#ECD6C3" stopOpacity={0.8} />
                            <stop offset="95%" stopColor="#ECD6C3" stopOpacity={0} />
                        </linearGradient>
                        <linearGradient id="5" x1="0" y1="0" x2="0" y2="2">
                            <stop offset="5%" stopColor="#D9CBC7" stopOpacity={0.8} />
                            <stop offset="95%" stopColor="#D9CBC7" stopOpacity={0} />
                        </linearGradient>
                        <linearGradient id="6" x1="0" y1="0" x2="0" y2="2">
                            <stop offset="5%" stopColor="#29CB27" stopOpacity={0.8} />
                            <stop offset="95%" stopColor="#29CB27" stopOpacity={0} />
                        </linearGradient>
                    </defs>
                    <XAxis dataKey="date" style={{ fontSize: "10px" }} />

                    <Tooltip
                        // formatter={(value, name, props) => mapTooltip(value, name)}
                        content={<CustomTooltip />}
                    />

                    {selectedMetrics.map((item, index) => (
                        <Area
                            key={index}
                            type="monotone"
                            strokeWidth={3}
                            dataKey={item.value}
                            stackId={index}
                            stroke={mapColors(index)}
                            fill={`url(#${index})`}
                        />
                    ))}
                    <Legend
                        formatter={(value, entry, index) => mapLegend(value)}
                        iconType="circle"
                        iconSize={10}
                        wrapperStyle={legendWrapperStyle}
                    />
                </AreaChart>
                :
                <PlaceHolder><h1>There is currently no data available.</h1></PlaceHolder>
            }
        </ResponsiveContainerCustom>
    );
};

export default SettingsPageOverview;

const ResponsiveContainerCustom = styled(ResponsiveContainer)`
  border-radius: 10px;
  -webkit-box-shadow: 5px 0px 17.3px 17.3px rgba(124, 177, 208, 0.06);
  -moz-box-shadow: 5px 0px 17.3px 17.3px rgba(124, 177, 208, 0.06);
  box-shadow: 5px 0px 17.3px 17.3px rgba(124, 177, 208, 0.06);
`;

const PlaceHolder = styled.div`
display:flex;
align-items:center;
justify-content:center;
width:100%;
height:100%;
h1{
color:${lightThemeColors.darkColorFaded60};
font-size:16px;
font-weight:500;
line-height:24px;
}
`
const CustomTooltipContainer = styled.div`
display:flex;
flex-direction:column;
background:${lightThemeColors.background};
padding:10px;
gap:5px;
border:1px solid ${lightThemeColors.darkColorFaded30};
.label{
font-size:16px;
color:${lightThemeColors.darkColor};
}
.intro{
color:${lightThemeColors.primaryColor};
}
`