import React from "react";
import styled from "styled-components";
import { lightThemeColors } from "../../assets/styles/colors";
import { ReactComponent as SuccessCheck } from "../../assets/icons/generic/validCircle.svg";
import { ReactComponent as WarningInfoIcon } from "../../assets/icons/generic/WarningInfoIcon.svg";
import { INFO, SUCCESS, WARNING } from "../../constants/OptionsConstants";

const SDKIntegrationCard = ({ type, text, action }) => {
  let display = <></>;
  const infoType = (
    <Container onClick={action}>
      <p>{text}</p>
    </Container>
  );
  const succesType = (
    <SuccesContainer>
      <SuccessCheck />
      <p>{text}</p>
    </SuccesContainer>
  );

  const warningType = (
    <WarningContainer>
      <WarningInfoIcon />
      <p>{text}</p>
    </WarningContainer>
  );

  switch (type) {
    case INFO:
      display = infoType;
      break;
    case SUCCESS:
      display = succesType;
      break;
    case WARNING:
      display = warningType;
      break;

    default:
      break;
  }

  return <>{display}</>;
};

export default SDKIntegrationCard;
const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 15px;
  background: ${lightThemeColors.primaryColor};
  border-radius: 5px;
  cursor: pointer;

  p {
    color: white;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    text-align: center;
  }
`;

const SuccesContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 5px 10px;
  background: ${lightThemeColors.validGreenFaded};
  border-radius: 5px;
  width: fit-content;
  p {
    color: ${lightThemeColors.validGreen};
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    text-align: center;
  }
  svg {
    width: 18px;
    color: rgba(112, 203, 151, 1);
  }
`;
const WarningContainer = styled(SuccesContainer)`
  background: rgba(255, 102, 102, 0.1);
  p {
    color: ${lightThemeColors.authentication.inputErrorColor};
  }
`;
