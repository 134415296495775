import React from 'react'
import styled from 'styled-components'
import { lightThemeColors } from '../../assets/styles/colors'

const CustomBigSubmitButton = ({ text, onClick, disabled }) => {
    return (

        <StyledButton
            onClick={onClick}
            disabled={disabled}
        >
            {text}
        </StyledButton>

    )
}

export default CustomBigSubmitButton

const StyledButton = styled.button`
width:100%;
color:white;
font-size: 16px;
font-weight: 700;
line-height: 16px;
text-align: center;
padding:17px 65px;
border-radius:4px;
border:none;
background:${lightThemeColors.primaryColor};
cursor:pointer;
&:disabled{
    opacity:0.3;
}
`