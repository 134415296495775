import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { lightThemeColors } from "../../assets/styles/colors";
import ConfigurationSectionHeader from "../../components/configurationComponents/ConfigurationSectionHeader";
import LableWithArrow from "../../components/configurationComponents/LableWithArrow";
import InputWithFixSection from "../../components/configurationComponents/InputWithFixSection";
import { useConfigurationContext } from "../../context/useConfigurationContext";
import PrimaryButton from "../../components/Generic/PrimaryButton";
import { useInstanceContext } from "../../context/useInstanceContext";
import { showGenericError } from "../../helper/Notifications";
import { ReactComponent as DomainIcon } from "../../assets/icons/generic/domainIcon.svg";
import SubcategoryTitleWrapper from "../../components/configurationComponents/SubcategoryTitleWrapper";
import { ReactComponent as SubdomainIcon } from "../../assets/icons/generic/subdomainIcon.svg";
import InputFieldWithValidation from "../../components/Generic/InputFieldWithValidation";
import DeleteConfirmationChild from "../../components/Generic/DeleteConfirmationWithChild";
import { DOMAIN } from "../../constants/OptionsConstants";

const DomainConfig = () => {
  const { projectDomain, setSubdomain } = useConfigurationContext();
  const { selectedProject } = useInstanceContext();
  const [expanded, setExpanded] = useState(false);
  const [appDomain, setAppDomain] = useState("");
  const [appSubdomain, setAppSubdomain] = useState("");
  const [saveDisabled, setSaveDisabled] = useState(true);
  const [openConfirmation, setOpenConfirmation] = useState(false);

  const handleExpand = () => {
    setExpanded(!expanded);
  };

  const checkChanges = () => {
    if (!projectDomain) {
      return;
    }
    if (
      projectDomain.subdomain !== appSubdomain &&
      appSubdomain != "" &&
      appSubdomain.length > 5
    ) {
      setSaveDisabled(false);
    } else {
      setSaveDisabled(true);
    }
  };

  const handleSetSubdomain = () => {
    let formData = new FormData();
    formData.append("subdomain", appSubdomain);
    setSubdomain(
      selectedProject.id,
      formData,
      () => {},
      () => {
        showGenericError();
      }
    );
  };

  const handleSaveButtonClick = () => {
    handleSetSubdomain();
  };

  useEffect(() => {
    if (!projectDomain) {
      return;
    }
    setAppSubdomain(projectDomain.subdomain);
    setAppDomain(projectDomain.domain);
  }, [projectDomain]);

  useEffect(() => {
    checkChanges();
  }, [appSubdomain, projectDomain]);

  const SaveButton = (
    <Line style={{ marginLeft: "auto" }}>
      <DeleteConfirmationChild
        open={openConfirmation}
        setOpen={setOpenConfirmation}
        variant={DOMAIN}
        action={handleSetSubdomain}
      >
        <PrimaryButton
          text={"Save"}
          bgVariant={lightThemeColors.background}
          styled={{ color: lightThemeColors.primaryColor }}
          onClick={() => setOpenConfirmation(true)}
          disabled={saveDisabled}
        />
      </DeleteConfirmationChild>
    </Line>
  );

  return (
    <Container>
      <ConfigurationSectionHeader
        title={"Domain"}
        handleExpand={handleExpand}
        expanded={expanded}
        showValidation
        isValid={
          appSubdomain + appDomain != appDomain && appSubdomain.length > 5
        }
        customButton={saveDisabled === false && SaveButton}
        icon={<DomainIcon />}
        unsavedChanges={saveDisabled === false}
      />

      {expanded && (
        <ContentContainer>
          <SubcategoryContent>
            <SubcategoryTitleWrapper
              icon={<SubdomainIcon />}
              text="Subdomain"
              id={"SubdomainConfigId"}
              colorType={"faded-dark"}
            />

            <Line style={{ alignItems: "start" }}>
              <InputWithFixSection
                style={{ maxWidth: "540px" }}
                subtitle={
                  "Use a custom subdomain generated by grovs. You have the option to switch to another valid subdomain, but note that this change will impact all your links."
                }
                placeholder={"example-subdomain"}
                inputValue={appSubdomain}
                fixInputValue={appDomain}
                handleInputChange={(e) =>
                  setAppSubdomain(e.currentTarget.value.replace(/\s/g, ""))
                }
                valid={appSubdomain.length > 5}
              />

              <ArrowContainer>
                <LableWithArrow
                  arrowDirection={"left"}
                  text={"This is the subdomain all your links are going to use"}
                  reverse
                />
              </ArrowContainer>
            </Line>
          </SubcategoryContent>
        </ContentContainer>
      )}
    </Container>
  );
};

export default DomainConfig;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: ${lightThemeColors.background};
  width: 100%;
  gap: 30px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding-bottom: 30px;
`;

const SubcategoryContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 0 40px;
`;
const Line = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
`;
const ArrowContainer = styled.h1`
  margin-top: 50px;
`;
