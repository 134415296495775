import React, { useEffect, useReducer, useRef, useState } from "react";
import styled, { keyframes } from "styled-components";
import { lightThemeColors } from "../../assets/styles/colors";
import { ReactComponent as CloseModalIcon } from "../../assets/icons/generic/CloseModalIcon.svg";

import { motion } from "framer-motion";
import PrimaryButton from "../../components/Generic/PrimaryButton";
import { useUserContext } from "../../context/useUserContext";
import InputFieldWithValidation from "../../components/Generic/InputFieldWithValidation";
import { useOutletContext } from "react-router-dom";
import { showGenericError } from "../../helper/Notifications";

const TwoFAModal = ({ isMoldaOpen, setIsModalOpen }) => {


  const ref = useRef();
  const { enable2FA, getOTPQrcode, isOtpEnabled, handleSetUser, user } = useUserContext()
  const [inputValue, setInputValue] = useState("")
  const [qrCodeSvg, setQrCodeSvg] = useState(null);
  const { setLoading } = useOutletContext()

  const handleEnable2FA = (enable, otpCode) => {
    setLoading(true)
    enable2FA(
      enable,
      otpCode,
      (response) => {
        setInputValue("")
        handleIsOtpEnabled();
        handleSetUser(response.data.user)
        setLoading(false)
        setIsModalOpen(false)
      },
      (error) => {
        setInputValue("")
        showGenericError();
        setLoading(false)
      }
    )
  }

  const handleIsOtpEnabled = () => {
    isOtpEnabled(
      (response) => {

      },
      (error) => { }
    )
  }

  const handleGetQrCode = () => {
    getOTPQrcode(
      (response) => {
        const svg = response.data
        setQrCodeSvg(svg)
      },
      (error) => {
        console.log(error)
      }
    )
  }

  const handleSubmitButton = () => {
    if (user.otp_required_for_login) {
      handleEnable2FA(false, inputValue)
    } else {
      handleEnable2FA(true, inputValue)
    }
  }


  useEffect(() => {
    handleGetQrCode();
  }, [])

  useEffect(() => {
    if (inputValue.length === 6) {
      handleSubmitButton()
    }
  }, [inputValue])

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (setIsModalOpen && ref.current && !ref.current.contains(e.target)) {
        setIsModalOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [setIsModalOpen]);

  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        setIsModalOpen(false);
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, []);

  return (
    <FullscreenContainer>
      <InviteModal
        ref={ref}
        as={motion.div}
        initial={{ translateX: "65vw" }}
        animate={{ translateX: "0" }}
        exit={{ translateX: "65vw" }}
        transition={{ duration: 0.4 }}
      >
        <Line>
          <HeadWrapper>
            <h1>2 Factor Authentification</h1>
          </HeadWrapper>
          <CloneBtn
            onClick={() => setIsModalOpen(false)}
            style={{ marginLeft: "auto" }}
          >
            <CloseModalIcon />
          </CloneBtn>
        </Line>

        {!user.otp_required_for_login && <ColumnGroup>

          <SvgLine >
            <svg dangerouslySetInnerHTML={{ __html: qrCodeSvg }} />
          </SvgLine>
          <Line>
            <InfoText> Scan QR Code for 2FA in your favourite authenticaion app (Google Authenticatior, Microsoft Authenticatior, etc.)</InfoText>
          </Line>

        </ColumnGroup>}

        <Line>
          <InputFieldWithValidation
            title={"Enter OTP Code"}
            noValidation
            placeholder={"OTP Code"}
            inputValue={inputValue}
            handleInputChange={(e) => setInputValue(e.currentTarget.value)}
          />
        </Line>
        <Line>
          <PrimaryButton
            disabled={inputValue.length < 6}
            styled={{ padding: "12px", width: "100%" }}
            text={user.otp_required_for_login ? "Disable 2FA" : "Enable 2FA"}
            onClick={() => handleSubmitButton()}
          />
        </Line>
      </InviteModal>
    </FullscreenContainer>
  );
};

export default TwoFAModal;

const FullscreenContainer = styled.div`
  display: flex;
  align-items:center;
  justify-content:center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  z-index: 5;
`;

const InviteModal = styled.div`

  position: fixed;
  display: flex;
  flex-direction: column;
  background: ${lightThemeColors.background};
  max-width: 400px;
  max-height:600px;
margin-left:auto;
margin-right:auto;
width:100%;
  padding: 30px;
  border-radius:10px;
  gap: 20px;
  .control {
    border-radius: 5px;
  }
`;

const CloneBtn = styled.button`
 display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  border: none;
  background: none;
  cursor: pointer;
`;
const HeadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  h1 {
    color: ${lightThemeColors.darkColor};
    font-size: 20px;
    font-weight: 600;
    line-height: 37.5px;
  }
  p {
    color: ${lightThemeColors.darkColorFaded30};
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }
`;
const ColumnGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Line = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;



const SvgLine = styled.div`
display:flex;
align-items:center;
justify-content:center;
background:white;
margin-left:auto;
margin-right:auto;
width:150px;
height:150px;
svg{
    fill:white;
}
`
const InfoText = styled.p`
  color: ${lightThemeColors.inputSubtitleColor};
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
`