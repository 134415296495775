import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { BUTTON } from "../../constants/OptionsConstants";
import {
  handleForm,
  handlePosition,
  handleSize,
} from "../../helper/TemplateCreatorHelpers";

const CustomButton = ({
  state,
  handleSelectCurrentNode,
  handleAddtoRefList,
  dropIndex,
}) => {
  const ref = useRef();

  const [size, setSize] = useState(state.size);
  const [position, setPosition] = useState(state.position);
  const [form, setForm] = useState(state.form);
  const [background, setBackground] = useState(state.background);
  const [textColor, setTextColor] = useState(state.textColor);
  const [paddingTop, setPaddingTop] = useState(state.paddingTop);
  const [paddingBottom, setPaddingBottom] = useState(state.paddingBottom);
  const [paddingLeft, setPaddingLeft] = useState(state.paddingLeft);
  const [paddingRight, setPaddingRight] = useState(state.paddingRight);
  const [text, setText] = useState(state.text);
  const [link, setLink] = useState(state.link);

  useEffect(() => {
    setSize(state.size);
    setPosition(state.position);
    setForm(state.form);
    setBackground(state.background);
    setTextColor(state.textColor);
    setPaddingTop(state.paddingTop);
    setPaddingBottom(state.paddingBottom);
    setPaddingLeft(state.paddingLeft);
    setPaddingRight(state.paddingRight);
    setText(state.text);
    setLink(state.link);
  }, [state]);

  useEffect(() => {
    handleAddtoRefList(ref.current, dropIndex);
  }, []);

  return (
    <ButtonWrapper onClick={() => handleSelectCurrentNode(ref.current, BUTTON)}>
      <CustomButtonWrapper
        size={handleSize(size)}
        position={handlePosition(position)}
        form={handleForm(form)}
        backgroundColor={background}
        textColor={textColor}
        paddingBottom={paddingBottom}
        paddingTop={paddingTop}
        paddingLeft={paddingLeft}
        paddingRight={paddingRight}
        ref={ref}
        href={link}
        target="_blank"
        rel="noreferrer noopener"
      >
        {text}
      </CustomButtonWrapper>
    </ButtonWrapper>
  );
};

export default CustomButton;
const CustomButtonWrapper = styled.a`
  font-family: "Lato";
  background: ${(props) => props.backgroundColor};
  color: ${(props) => props.textColor};
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  cursor: pointer;
  border: none;
  text-decoration: none;
  max-width: ${(props) => props.size};
  margin-left: ${(props) => props.position.marginLeft};
  margin-right: ${(props) => props.position.marginRight};
  border-radius: ${(props) => props.form};
  padding-bottom: ${(props) => props.paddingBottom}px;
  padding-top: ${(props) => props.paddingTop}px;
  padding-right: ${(props) => props.paddingRight}px;
  padding-left: ${(props) => props.paddingLeft}px;

  @media (max-width: 425px) {
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    font-weight: 500;
    max-width: 100%;
    margin-left: 0px;
    margin-right: 0px;
  }
`;
const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 10px 0px;
`;
