import React, { useEffect, useState } from "react";
import styled from "styled-components";
import InputFieldWithValidation from "../Generic/InputFieldWithValidation";
import { ReactComponent as RemoveIcon } from "../../assets/icons/generic/removeIcon.svg";
import { ReactComponent as PointSeparatorIcon } from "../../assets/icons/generic/PointSeparatorIcon.svg";
import { lightThemeColors } from "../../assets/styles/colors";
import Select from "react-select";

const EmailRolePair = ({
  emailRoleItem,
  handleRemove,
  handleChangeEmailRolePair,
  index,
}) => {
  const [role, setRole] = useState(emailRoleItem.role);

  const selectOptions = [
    { value: "admin", label: "Administrator" },
    { value: "member", label: "Member" },
  ];

  const [inputValues, setInputValues] = useState({
    email: emailRoleItem.email,
  });

  const customStyles = {
    container: (provided) => ({
      ...provided,
      width: "100%",
      "&:focus-visible": {
        outline: "none",
      },
    }),
    control: (provided) => ({
      ...provided,
      borderRadius: "5px",
      borderColor: lightThemeColors.darkColorFullFaded,
      outline: "none",
      "&:hover": {
        borderColor: "none",
      },
      height: "40px",
    }),

    singleValue: (provided) => ({
      ...provided,
      fontSize: "16px",
      fontWeight: "500",
      color: lightThemeColors.darkColorFaded,
    }),
  };

  const handleInputChange = (e) => {
    const { name, value } = e.currentTarget;
    setInputValues({
      ...inputValues,
      [name]: value,
    });
  };

  useEffect(() => {
    let newPair = {
      email: inputValues.email,
      role: role,
    };

    handleChangeEmailRolePair(index, newPair);
  }, [inputValues.email, role]);

  return (
    <Container>
      <InputFieldWithValidation
        noValidation
        inputName={"email"}
        placeholder={"Enter the email the email"}
        inputValue={emailRoleItem.email}
        handleInputChange={handleInputChange}
      />
      <SelectContainer>
        <Select
          placeholder={"Role"}
          styles={customStyles}
          value={emailRoleItem.role}
          options={selectOptions}
          onChange={(e) => setRole(e)}
        />
      </SelectContainer>

      <RemoveBtn onClick={() => handleRemove(index)}>
        <RemoveIcon />
      </RemoveBtn>
    </Container>
  );
};

export default EmailRolePair;
const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 10px;
  svg {
    min-width: 4px;
  }
`;
const SelectContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  width: 50%;
`;
const RemoveBtn = styled.button`
  margin-top: 10px;
  border: none;
  cursor: pointer;
  background: none;
  margin-left: auto;
`;
