import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { lightThemeColors } from "../../assets/styles/colors";
import { AnimatePresence, motion } from "framer-motion";
import { useInstanceContext } from "../../context/useInstanceContext";
import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";

const NoSubscriptionBanner = ({ projectType }) => {
  const { selectedInstance } = useInstanceContext();
  const [searchParams] = useSearchParams()
  const [display, setDisplay] = useState(false);
  const navigate = useNavigate();

  const goTo = (path) => {
    navigate({
      pathname: path,
      search: createSearchParams(searchParams).toString(),
    });
  };

  useEffect(() => {
    if (!selectedInstance) {
      return;
    }
    if (selectedInstance.quota_exceeded) {
      setDisplay(true);
    } else {
      setDisplay(false);
    }
  }, [selectedInstance]);

  return (
    <AnimatePresence>
      {display && (
        <AnimatedDiv
          initial={{ height: 0 }}
          animate={{ height: "34px" }}
          exit={{ height: 0 }}
          transition={{ duration: 0.4 }}
        >
          <Container onClick={() => goTo("/settings/subscription")}>
            <h1>
              You've exceeded your free plan quota. Upgrade your plan, as links
              are no longer working!
            </h1>
          </Container>
        </AnimatedDiv>
      )}
    </AnimatePresence>
  );
};

export default NoSubscriptionBanner;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${lightThemeColors.quotaColor};
  height: 100%;
  padding: 0px;
  cursor:pointer;
  h1 {
    font-size: 15px;
    font-weight: 500;
    color: #fff;
  }
`;

const AnimatedDiv = styled(motion.div)``;
