import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { lightThemeColors } from "../../assets/styles/colors";
import { ReactComponent as BackIcon } from "../../assets/icons/generic/SquareArrow.svg";
import {
  BUTTON,
  CENTER,
  END,
  FIT,
  FULL,
  IMAGE,
  LARGE,
  MEDIUM,
  MENU,
  PILL,
  ROUND,
  SMALL,
  SQUARE,
  START,
  VIDEO,
} from "../../constants/OptionsConstants";
import CustomInput from "../AuthenticationComponents/CustomInput";
import CustomEditorMenuButton from "./CustomEditorMenuButton";

const CustomEditorVideoMenu = ({
  setDisplayOption,
  setActiveElementIndex,
  resultList,
  activeElementIndex,
  handleItemChanges,
}) => {
  console.log("afisez");
  const currentVideo = resultList.find(
    (item) => item.id === activeElementIndex
  );
  const currentProps = currentVideo.content.props.state;

  const [size, setSize] = useState(currentProps?.size);
  const [position, setPosition] = useState(currentProps?.position);
  const [title, setTitle] = useState(currentProps?.title);
  const [src, setSrc] = useState(currentProps?.src);
  const [alt, setAlt] = useState(currentProps?.alt);

  const handleBack = () => {
    setDisplayOption(MENU);
    setActiveElementIndex(null);
  };

  useEffect(() => {
    if (!currentVideo || !currentProps) {
      return;
    }

    setSize(currentProps.size);
    setPosition(currentProps.position);
    setTitle(currentProps.title);
    setSrc(currentProps.src);
    setAlt(currentProps.alt);
  }, [currentVideo.id]);

  useEffect(() => {
    handleItemChanges(currentVideo.id, "size", size);
  }, [size]);

  useEffect(() => {
    handleItemChanges(currentVideo.id, "position", position);
  }, [position]);

  useEffect(() => {
    handleItemChanges(currentVideo.id, "title", title);
  }, [title]);
  useEffect(() => {
    handleItemChanges(currentVideo.id, "src", src);
  }, [src]);
  useEffect(() => {
    handleItemChanges(currentVideo.id, "alt", alt);
  }, [alt]);

  return (
    <MenuCard>
      <MenuContent>
        <Line style={{ position: "relative" }}>
          <CloseBtn onClick={() => handleBack()}>
            <BackIcon />
            Back
          </CloseBtn>
          <HeadWrapper>
            <h1>{VIDEO}</h1>
          </HeadWrapper>
        </Line>

        <Group>
          <GroupDescription>Position</GroupDescription>
          <Line>
            <CustomEditorMenuButton
              onClick={() => setPosition(START)}
              selected={position === START}
            >
              Left
            </CustomEditorMenuButton>
            <CustomEditorMenuButton
              onClick={() => setPosition(CENTER)}
              selected={position === CENTER}
            >
              Center
            </CustomEditorMenuButton>
            <CustomEditorMenuButton
              onClick={() => setPosition(END)}
              selected={position === END}
            >
              Right
            </CustomEditorMenuButton>
          </Line>
        </Group>
        <Group>
          <GroupDescription>Size</GroupDescription>
          <Line>
            <CustomEditorMenuButton
              onClick={() => setSize(SMALL)}
              selected={size === SMALL}
            >
              Small
            </CustomEditorMenuButton>
            <CustomEditorMenuButton
              selected={size === MEDIUM}
              onClick={() => setSize(MEDIUM)}
            >
              Medium
            </CustomEditorMenuButton>
            <CustomEditorMenuButton
              selected={size === FULL}
              onClick={() => setSize(FULL)}
            >
              Full
            </CustomEditorMenuButton>
          </Line>
        </Group>
        <Group>
          <GroupDescription>Source</GroupDescription>
          <Line>
            <CustomInput
              value={src}
              onChange={(e) => setSrc(e.currentTarget.value)}
            />
          </Line>
        </Group>
      </MenuContent>
    </MenuCard>
  );
};

export default CustomEditorVideoMenu;
const MenuCard = styled.div`
  max-width: 600px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  top: 0;
  background: ${lightThemeColors.menuBackground};
  z-index: 1;
  position: relative;
`;

const HeadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: auto;
  h1 {
    color: ${lightThemeColors.darkColorFaded};
    font-size: 25px;
    font-weight: 700;
    line-height: 37.5px;
  }
  p {
    color: ${lightThemeColors.darkColorFaded30};
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
  }
`;

const CloseBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  border: none;
  background: none;
  cursor: pointer;
  gap: 10px;
  color: ${lightThemeColors.primaryColor};
  font-size: 18px;
  line-height: 22px;
  font-weight: 500;
  position: absolute;
  top: calc(50% - 11px);
  left: 40;
`;
const Line = styled.div`
  display: flex;
  gap: 30px;
  align-items: center;
  padding: 0 40px;
  width: 100%;
`;
const Group = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`;

const MenuContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  gap: 20px;
  padding: 40px 0;
`;

const GroupDescription = styled.p`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: ${lightThemeColors.darkColorFaded};
  padding: 0 40px;
`;
