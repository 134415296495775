import React from "react";
import styled from "styled-components";
import { lightThemeColors } from "../../assets/styles/colors";

const OptionButton = ({ text, onClick, disabled, selected }) => {
  return (
    <SdkTypeButton disabled={disabled} onClick={onClick} selected={selected}>
      {text}
    </SdkTypeButton>
  );
};

export default OptionButton;
const SdkTypeButton = styled.button`
  display: flex;
  align-items: center;
  padding: 5px 10px;
  border-radius: 5px;
  border: 1px solid ${lightThemeColors.primaryColor};
  background: ${lightThemeColors.menuBackground};
  color: ${lightThemeColors.primaryColor};
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  text-align: center;
  cursor: pointer;
  width: fit-content;
  opacity: ${(props) => (props.selected ? 1 : 0.3)};

  &:disabled {
    opacity: 0.3;
    cursor: initial;
  }
`;
