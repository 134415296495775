import React, { useState } from "react";
import styled from "styled-components";
import { lightThemeColors } from "../../assets/styles/colors";
import SecondaryButton from "../Generic/SecondaryButton";
import { useInstanceContext } from "../../context/useInstanceContext";
import { useOutletContext, useSearchParams } from "react-router-dom";
import { showGenericError, showSuccessNotification } from "../../helper/Notifications";
import DeleteConfirmationChild from "../Generic/DeleteConfirmationWithChild";
import { DELETE_PROJECT } from "../../constants/OptionsConstants";
import { lastIndexOf } from "lodash";

const DeleteProject = () => {
  const { deleteInstance, selectedInstance, getInstances, setSelectedInstance } =
    useInstanceContext();
  const { setLoading } = useOutletContext();
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams()

  const handleDeleteInstance = () => {
    setLoading(true);
    deleteInstance(
      selectedInstance.id,
      (response) => {
        showSuccessNotification("Project deleted")
        handleGetInstance();
      },
      (error) => {
        showGenericError();
        console.log(error);
        setLoading(false);
      }
    );
  };

  const handleInstanceResponse = (instances) => {
    if (instances.length > 0) {
      const lastInstance = instances[lastIndexOf(instances) - 1];
      setSearchParams((params) => {
        params.set("instance_id", lastInstance.id);
        return params;
      });
      setSelectedInstance(lastInstance);
      setLoading(false);
    }
  }


  const handleGetInstance = () => {
    getInstances(
      (response) => {
        const instances = response.data.instances
        handleInstanceResponse(instances)
        setLoading(false);
      },
      () => {
        showGenericError();
        setLoading(false);
      }
    );
  };

  return (
    <Container>
      <Line>
        <TitleWrapper>
          <h1>Delete Project</h1>
        </TitleWrapper>
      </Line>
      <InfoList>
        <h2>Deleting your project will have the following consequences: </h2>
        <li>
          The project and generated links associated with this project will no
          longer be accessible or usable.
        </li>
        <li>
          {" "}
          Other users will lose access to shared resources from your account.
        </li>
      </InfoList>
      <Line>
        <DeleteConfirmationChild
          variant={DELETE_PROJECT}
          action={handleDeleteInstance}
          setOpen={setOpenDeleteConfirm}
          open={openDeleteConfirm}
        >

          <SecondaryButton
            text={"Delete project"}
            variant={lightThemeColors.authentication.inputErrorColor}
            onClick={() => setOpenDeleteConfirm(true)}
          />
        </DeleteConfirmationChild>
      </Line>
    </Container>
  );
};

export default DeleteProject;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  h1 {
    color: ${lightThemeColors.darkColorFaded};
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
  }
`;
const Line = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const InfoList = styled.div`
  display: flex;
  flex-direction: column;

  h2 {
    color: ${lightThemeColors.darkColorFaded60};
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    margin-bottom: 10px;
  }
  li {
    color: ${lightThemeColors.darkColorFaded60};
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
  }
`;
