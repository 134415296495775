import React from 'react'
import styled from 'styled-components'
import { ReactComponent as SmallXIcon } from "../../assets/icons/generic/SmallX.svg"
import { lightThemeColors } from '../../assets/styles/colors'
const TagElement = ({ tagName, handleRemove, disabled }) => {

    return (
        <Container>
            <p>{tagName}</p>
            <RemoveBtn disabled={disabled} onClick={handleRemove}>
                <SmallXIcon />
            </RemoveBtn>

        </Container>
    )
}

export default TagElement

const Container = styled.div`
display:flex;
align-items:center;
padding:4px 12px;
background:${lightThemeColors.sidebar.tagBg};
border-radius:5px;
gap:10px;

p{
color:${lightThemeColors.darkColorFaded};
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    text-align: center; 
}
`
const RemoveBtn = styled.button`
border:none;
width:fit-content;
display:flex;
background:none;
cursor:pointer;
&disabled{
opacity:0.3;
}
`