import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import SubcategoryTitleWrapper from "../../components/configurationComponents/SubcategoryTitleWrapper";
import InputFieldWithValidation from "../../components/Generic/InputFieldWithValidation";
import LableWithArrow from "../../components/configurationComponents/LableWithArrow";
import { ReactComponent as FallBackIcon } from "../../assets/icons/generic/fallBackIcon.svg";
import { isURL } from "validator";
import { isCustomUrlValid } from "../../helper/ValidatorsHelper";

const DefaultUrlSetup = ({ setSubtitle, defaultUrl, setDefaultUrl }) => {
  const handleSetHeaderSubtitle = (e) => {
    let element = document.getElementById("DefaultUrl");
    const rect = element.getBoundingClientRect();
    if (rect.top - rect.height - 136 < 0) {
      setSubtitle("- Fallback");
    } else {
      setSubtitle(null);
    }
  };

  useEffect(() => {
    let element = document.getElementById("configListId");
    element.addEventListener("scroll", handleSetHeaderSubtitle);
    return () => {
      element.removeEventListener("scroll", handleSetHeaderSubtitle);
    };
  }, []);

  return (
    <Content>
      <SubcategoryContent>
        <SubcategoryTitleWrapper
          icon={<FallBackIcon />}
          text="Fallback"
          id={"DefaultUrl"}
          colorType={"faded-dark"}
        />
        <Line>
          <InputFieldWithValidation
            title={"URL"}
            inputValue={defaultUrl}
            placeholder={"Fallback URL"}
            handleInputChange={(e) => setDefaultUrl(e.currentTarget.value)}
            subtitle={
              "This is your fallback URL, if no redirect is defined for a platform, this URL will be used for redirect"
            }
            style={{ maxWidth: "540px" }}
            valid={isCustomUrlValid(defaultUrl)}
          />

          <LableWithArrow
            text={"Take the user here, if no redirect is defined"}
            reverse
          />
        </Line>
      </SubcategoryContent>
    </Content>
  );
};

export default DefaultUrlSetup;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const SubcategoryContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0 40px;
`;
const Line = styled.div`
  display: flex;
  align-items: end;
  gap: 20px;
`;
