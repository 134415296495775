import { GET, POST } from "../API";

let visitorsSearchSource;
let agregatedVisitorsSearchSource;

export const getVisitorsForSearchParamsAPICall = (
    project_id,
    data,
    onSuccess,
    onError
) => {

    visitorsSearchSource = POST(
        process.env.REACT_APP_API_PATH + `/projects/${project_id}/visitor_metrics_for_search_params`,
        data,
        onSuccess,
        onError,
        true,
        visitorsSearchSource
    );
};

export const getAgregatedVisitorsForSearchParamsAPICall = (
    project_id,
    data,
    onSuccess,
    onError
) => {

    agregatedVisitorsSearchSource = POST(
        process.env.REACT_APP_API_PATH + `/projects/${project_id}/aggregated_visitor_metrics_for_search_params`,
        data,
        onSuccess,
        onError,
        true,
        agregatedVisitorsSearchSource
    );
};

export const getVisitorDetailsAPICall = (
    project_id,
    visitor_id,
    onSuccess,
    onError
) => {

    GET(
        process.env.REACT_APP_API_PATH + `/projects/${project_id}/visitor/${visitor_id}`,
        onSuccess,
        onError,
        true
    );
};