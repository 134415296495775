import React from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import styled from "styled-components";
import { lightThemeColors } from "../../assets/styles/colors";

const DraggableList = ({ items }) => {
  return (
    <Droppable droppableId="componentsListId" isDropDisabled={true}>
      {(provided) => (
        <Sidebar {...provided.droppableProps} ref={provided.innerRef}>
          <InfoText>
            {" "}
            Drag components to your page and build it as you wish
          </InfoText>
          {items.map(({ id, content }, index) => (
            <Draggable key={id} draggableId={id} index={index}>
              {(provided) => (
                <ItemContainer
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  style={{
                    ...provided.draggableProps.style,
                  }}
                >
                  {content}
                </ItemContainer>
              )}
            </Draggable>
          ))}
          {provided.placeholder}
        </Sidebar>
      )}
    </Droppable>
  );
};

export default DraggableList;

const Sidebar = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  top: 0;
  position: relative;
`;
const ItemContainer = styled.div`
  width: 100%;
`;

const InfoText = styled.p`
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;
  color: ${lightThemeColors.darkColorFaded};
`;
