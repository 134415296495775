import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { lightThemeColors } from "../../assets/styles/colors";
import AddMetrics from "../../components/linksComponents/AddMetrics";
import SelectedMetricTag from "../../components/linksComponents/SlectedMetricTag";
import { useExpanded } from "../../hooks/useExpanded";
import ConfigurationSectionHeader from "../../components/configurationComponents/ConfigurationSectionHeader";
import { useEventContext } from "../../context/useEventContext";
import { showGenericError } from "../../helper/Notifications";
import { useInstanceContext } from "../../context/useInstanceContext";
import moment from "moment";
import LinksOverviewChart from "../../pages/linksPage/LinksOverviewChart";
import SettingsPageOverview from "../../pages/subscription/SettingsPageOverview";



const PaymentScreenAudienceOverview = ({ startDate, endDate, settingsView }) => {
    const { getEventsForPaymentScreen } = useEventContext();
    const { selectedInstance } = useInstanceContext()
    const [expanded, expand] = useExpanded(false);

    const [selectedMetrics, setSelectedMetrics] = useState([
        {
            value: "dau",
            label: "DAU (Daily Active Users)",
        },
    ]);

    const [overviewMetrics, setOverviewMetrics] = useState(null)

    const handleGetEventsForOverview = () => {
        if (!selectedInstance || !startDate || !endDate) {
            return
        }

        let data = {
            start_date: moment(startDate).toISOString(),
            end_date: moment(endDate).toISOString()
        }

        getEventsForPaymentScreen(
            selectedInstance.id,
            data,
            (response) => {
                const metrics = response.data.metrics_values;
                setOverviewMetrics(metrics);

            },
            (error) => {
                showGenericError();
            }
        )
    }


    useEffect(() => {
        handleGetEventsForOverview()
    }, [selectedInstance, startDate, endDate])

    return (
        <Container>
            {!settingsView &&
                <ConfigurationSectionHeader
                    title={"Overview"}
                    handleExpand={expand}
                    expanded={expanded}
                />
            }
            {(expanded || settingsView) && (
                <ContentContainer>
                    <Line>
                        <SettingsPageOverview
                            overviewMetrics={overviewMetrics}
                            selectedMetrics={selectedMetrics}
                        />
                    </Line>
                </ContentContainer>
            )}
        </Container>
    );
};

export default PaymentScreenAudienceOverview;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: ${lightThemeColors.background};
  width: 100%;
  gap: 30px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap:20px;
`;
const Line = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
`;
