import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { lightThemeColors } from "../../assets/styles/colors";
import { IMAGE } from "../../constants/OptionsConstants";
import {
  handleImageSize,
  handlePosition,
  handleSize,
} from "../../helper/TemplateCreatorHelpers";

const CustomImage = ({
  state,
  handleSelectCurrentNode,
  handleAddtoRefList,
  dropIndex,
}) => {
  const ref = useRef();

  const [src, setSrc] = useState(state.src);
  const [alt, setAlt] = useState(state.alt);
  const [title, setTitle] = useState(state.title);
  const [size, setSize] = useState(state.size);
  const [position, setPosition] = useState(state.position);

  useEffect(() => {
    setSrc(state.src);
    setTitle(state.title);
    setAlt(state.alt);
    setSize(state.size);
    setPosition(state.position);
  }, [state]);

  useEffect(() => {
    handleAddtoRefList(ref.current, dropIndex);
  }, []);

  return (
    <Wrapper onClick={() => handleSelectCurrentNode(ref.current, IMAGE)}>
      {src ? (
        <CustomImg
          size={handleImageSize(size)}
          position={handlePosition(position)}
          src={src}
          alt={alt}
          title={title}
          ref={ref}
        />
      ) : (
        <CustomPLaceholder
          onClick={() => handleSelectCurrentNode(ref.current, IMAGE)}
          ref={ref}
        >
          <p>Your image will appear here, once you add a source...</p>
        </CustomPLaceholder>
      )}
    </Wrapper>
  );
};

export default CustomImage;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 10px 0px;
`;
const CustomImg = styled.img`
  display: flex;
  object-fit: contain;
  max-width: ${(props) => props.size};
  margin-left: ${(props) => props.position.marginLeft};
  margin-right: ${(props) => props.position.marginRight};
`;
const CustomPLaceholder = styled.div`
  margin-left: auto;
  margin-right: auto;
  display: flex;
  width: 100%;
  max-width: 500px;
  min-height: 300px;
  align-items: center;
  justify-content: center;
  background: ${lightThemeColors.menuBackground};
  color: ${lightThemeColors.darkColorFaded30};
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(43, 58, 84, 0.2);
  p {
    font-size: 16px;
    line-height: 26px;
    font-weight: 500;
    text-align: center;
  }
`;
