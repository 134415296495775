import React from "react";
import styled from "styled-components";
import { lightThemeColors } from "../../assets/styles/colors";

const SubcategoryTitleWrapper = ({
  text,
  icon,
  small,
  colorType,
  iconColorType,
  id,
}) => {
  return (
    <Wrapper
      small={small}
      colorType={colorType}
      id={id}
      iconColorType={iconColorType}
    >
      {icon}
      <h1>{text}</h1>
    </Wrapper>
  );
};

export default SubcategoryTitleWrapper;
const Wrapper = styled.div`
  ${(props) =>
    props.small
      ? `display:flex;
    gap:10px;
    align-items:center;
    padding:7px 30px;
    border-radius:5px;
    width:fit-content;
    background:${lightThemeColors.menuBackground};
    h1{
        color:${
          props.colorType === "faded-dark"
            ? lightThemeColors.darkColorFaded
            : lightThemeColors.primaryColor
        };
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    }
    svg{
  width:15px;
  color:${
    props.iconColorType === "faded-dark"
      ? lightThemeColors.darkColorFaded
      : lightThemeColors.primaryColor
  };
  border:1px solid ${lightThemeColors.menuBorder};
    }`
      : `display:flex;
gap:10px;
align-items:center;
padding:4px 8px;
border-radius:5px;
width:fit-content;
background:${lightThemeColors.menuBackground};
h1{
    color:${
      props.colorType === "faded-dark"
        ? lightThemeColors.darkColorFaded
        : lightThemeColors.primaryColor
    };
font-size: 15px;
font-weight: 600;
line-height: 20px;
text-align: left;
}
svg{
    height: 16px;
    width: 16px;
    color:${
      props.iconColorType === "faded-dark"
        ? lightThemeColors.darkColorFaded
        : lightThemeColors.primaryColor
    };
}`}
  border:1px solid ${lightThemeColors.menuBorder};
`;
