import { GET, POST } from "../../API";
let source;
export const getProjectRedirectsAPICall = (
    projectId,
    onSuccess,
    onError
) => {

    source = GET(
        process.env.REACT_APP_API_PATH + `/projects/${projectId}/redirect_config`,
        onSuccess,
        onError,
        true,
        source
    );
};


export const setRedirectAPICall = (
    projectId,
    platform,
    variation,
    appstore,
    fallback_url,
    enabled,
    onSuccess,
    onError
) => {

    const data = {
        platform: platform,
        variation: variation,
        appstore: appstore,
        fallback_url: fallback_url,
        enabled: enabled,
    }

    POST(
        process.env.REACT_APP_API_PATH + `/projects/${projectId}/redirect`,
        data,
        onSuccess,
        onError
    );
};

export const setDefaultRedirectAPICall = (
    projectId,
    default_fallback,
    onSuccess,
    onError
) => {
    const data = {
        default_fallback: default_fallback,
    }

    POST(
        process.env.REACT_APP_API_PATH + `/projects/${projectId}/redirect_config`,
        data,
        onSuccess,
        onError
    );
};