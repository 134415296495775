import React from "react";
import styled from "styled-components";
import { lightThemeColors } from "../../assets/styles/colors";

const PrimaryButton = ({
  icon,
  text,
  disabled,
  onClick,
  styled,
  bgVariant,
}) => {
  return (
    <CustomButton
      disabled={disabled}
      onClick={onClick}
      style={styled}
      bgVariant={bgVariant}
    >
      {icon}
      {text}
    </CustomButton>
  );
};

export default PrimaryButton;

const CustomButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  gap: 6px;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  text-align: center;
  color: white;
  background: ${(props) =>
    props.bgVariant ? props.bgVariant : lightThemeColors.primaryColor};
  padding: 8px 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:disabled {
    opacity: 0.3;
    cursor: inherit;
  }

  svg {
    color: white;
    height: 16px;
    width: 16px;
  }
`;
