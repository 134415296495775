import { createBrowserRouter } from "react-router-dom";
import App from "../App";
import LinksPage from "../pages/linksPage/LinksPage";
import LoginPage from "../pages/authenticationPage/LoginPage";
import ConfigurationPage from "../pages/configurationPage/ConfigurationPage";
import IntegrationsPage from "../pages/integrationsPage/IntegrationsPage";
import MembersPage from "../pages/membersPage/MembersPage";
import SubscriptionPage from "../pages/subscription/SubscriptionPage";
import AccountPage from "../pages/account/AccountPage";
import ProtectedRoute from "./ProtectedRoute";
import RegisterPage from "../pages/authenticationPage/RegisterPage";
import ResetPasswordPage from "../pages/authenticationPage/ResetPasswordPage";
import InstanceContextProvider from "../context/useInstanceContext";
import NewPasswordPage from "../pages/authenticationPage/NewPasswordPage";
import ConfigurationContextProvider from "../context/useConfigurationContext";
import LinkContextProvider from "../context/useLinkContext";
import EventContextProvider from "../context/useEventContext";
import SettingsPage from "../pages/settingsPage/SettingsPage";
import AudiencePage from "../pages/AudiencePage/AudiencePage";
import VisitorsContextProvider from "../context/useVisitorsContext";
import AcceptInvitePage from "../pages/authenticationPage/AcceptInvite";
import PaymentsContextProvider from "../context/usePaymentsContext";

import NotificationsContextProvider from "../context/useNotificationsContext";
import MessagesPage from "../pages/MessagePage/MessagesPage";






export const router = createBrowserRouter([

    {
        index: "*",
        element:
            <ProtectedRoute>
                <InstanceContextProvider>
                    <ConfigurationContextProvider>
                        <LinkContextProvider>
                            <EventContextProvider>
                                <VisitorsContextProvider>
                                    <PaymentsContextProvider>
                                        <NotificationsContextProvider>
                                            <App />
                                        </NotificationsContextProvider>
                                    </PaymentsContextProvider>
                                </VisitorsContextProvider>
                            </EventContextProvider>
                        </LinkContextProvider>
                    </ConfigurationContextProvider>
                </InstanceContextProvider>
            </ProtectedRoute>
        ,

        children: [
            {
                path: "/links",
                element: <LinksPage />
            },
            {
                path: "/configuration",
                element: <ConfigurationPage />
            },
            {
                path: "/developers",
                element: <IntegrationsPage />
            },
            {
                path: "/developers",
                element: <IntegrationsPage />
            },
            {
                path: "/audience",
                element: <AudiencePage />
            },
            {
                path: "/notifications",
                element: <MessagesPage />,

            },
            {
                path: "/account",
                element: <AccountPage />
            },
            {
                path: "/settings",
                element: <SettingsPage />,
                children: [
                    {
                        path: "/settings/members",
                        element: <MembersPage />
                    },
                    {
                        path: "/settings/subscription",
                        element: <SubscriptionPage />
                    }

                ]
            },
        ]
    },

    {
        path: "/login",
        element: <LoginPage />
    },
    {
        path: "/register",
        element: <RegisterPage />
    },
    {
        path: "/accept-invite",
        element: <AcceptInvitePage />
    },
    {
        path: "/reset_password",
        element: <ResetPasswordPage />
    },
    {
        path: "/new-password",
        element: <NewPasswordPage />
    },


])