import React from 'react'
import styled from 'styled-components'
import { lightThemeColors } from '../../assets/styles/colors'
import { ReactComponent as ValidIcon } from "../../assets/icons/generic/validCircle.svg"
const GeneratedLink = ({ generatedLink, style }) => {
    return (
        <Container style={style}>
            <ValidIcon />
            <p>{generatedLink}</p>
        </Container>
    )
}

export default GeneratedLink
const Container = styled.div`
display:flex;
align-items:center;
padding:9px 20px;
border-radius:5px;
background:${lightThemeColors.validGreenFaded};
width:100%;
gap:20px;
p{
color:${lightThemeColors.validGreen};
font-size: 16px;
font-weight: 500;
line-height: 22px;
text-align: left;
}
svg{
    color:${lightThemeColors.validGreen};
}
`