import React, { useEffect, useState } from "react";
import ConfigurationSectionHeader from "../configurationComponents/ConfigurationSectionHeader";
import { useExpanded } from "../../hooks/useExpanded";
import styled from "styled-components";
import { lightThemeColors } from "../../assets/styles/colors";
import InputFieldWithValidation from "../Generic/InputFieldWithValidation";
import KeyPairValuesReadonly from "./KeyPairValuesReadOnly";

const AudienceAttributes = ({ visitorInfo }) => {
  const [expanded, expand] = useExpanded(true);
  const [identifier, setIdentifier] = useState(visitorInfo?.uuid || "");
  const [invitedBy, setInvitedBy] = useState(visitorInfo?.inviter || "");
  const [sdkIdentifier, setSdkIdentifier] = useState(
    visitorInfo?.sdk_identifier || ""
  );

  const mapIdentifiers = (identifiers) => {
    if (!identifiers) {
      return [];
    }
    const newData = Object.keys(identifiers).map((element) => ({
      key: element,
      value: identifiers[element],
    }));
    return newData;
  };

  const [keyValuePair, setKeyValuePair] = useState(
    mapIdentifiers(visitorInfo?.sdk_attributes)
  );

  const initializeData = () => {
    setIdentifier(visitorInfo?.uuid);
    setInvitedBy(visitorInfo?.inviter);
    setSdkIdentifier(visitorInfo?.sdk_identifier);
    setKeyValuePair(mapIdentifiers(visitorInfo?.sdk_attributes));
  };
  useEffect(() => {
    initializeData();
  }, [visitorInfo]);

  return (
    <Container>
      <ConfigurationSectionHeader
        title={"Attributes"}
        handleExpand={expand}
        expanded={expanded}
      />
      {expanded && (
        <ContentContainer>
          <ColumnGroup>
            <InputFieldWithValidation
              title={"ID"}
              subtitle={"Grovs generated ID for your project"}
              noValidation
              readOnly
              inputValue={identifier}
              handleInputChange={(e) => setIdentifier(e.currentTarget.value)}
            />
            <InputFieldWithValidation
              title={"Invited By"}
              subtitle={
                "This user signed up from a link send by the user with the identifier"
              }
              noValidation
              readOnly
              inputValue={invitedBy}
              handleInputChange={(e) => setInvitedBy(e.currentTarget.value)}
            />
            <InputFieldWithValidation
              title={"SDK Identifier"}
              subtitle={
                "This is an identifier for the user set by your team using the SDKs, usually this is a user ID"
              }
              noValidation
              readOnly
              inputValue={sdkIdentifier}
              handleInputChange={(e) => setSdkIdentifier(e.currentTarget.value)}
            />
            {keyValuePair.length > 0 && (
              <Subcategory>
                <SubcategoryTitle>SDK Attributes</SubcategoryTitle>
                <SubcategorySubTitle>
                  This is a list of attributes added by your team using the SDK,
                  usually those are properties of the user
                </SubcategorySubTitle>
                {keyValuePair.map((item, index) => (
                  <KeyPairValuesReadonly
                    key={index}
                    index={index}
                    keyValueItem={item}
                  />
                ))}
              </Subcategory>
            )}
          </ColumnGroup>
        </ContentContainer>
      )}
    </Container>
  );
};

export default AudienceAttributes;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: ${lightThemeColors.background};
  width: 100%;
  gap: 30px;
`;
const ContentContainer = styled.div`
  display: flex;
  overflow: hidden;
  flex-wrap: wrap;
  padding: 0 0px;
  row-gap: 50px;
`;
const SubcategoryContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
  max-width: 620px;
`;
const ColumnGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
`;

const Subcategory = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`;
const SubcategoryTitle = styled.h1`
  color: ${lightThemeColors.darkColorFaded};
  font-size: 15px;
  font-weight: 700;
  line-height: 27px;
  margin-bottom: 5px;
`;
const SubcategorySubTitle = styled.p`
  color: ${lightThemeColors.inputSubtitleColor};
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
`;
