import { GET, POST } from "../API";


export const getSubscriptionDetailsAPICall = (
    id,
    onSuccess,
    onError
) => {
    GET(
        process.env.REACT_APP_API_PATH + `/payments/stripe_subscription_details/${id}`,
        onSuccess,
        onError
    );
};

export const getCurrentMauAPICall = (
    id,
    onSuccess,
    onError
) => {
    GET(
        process.env.REACT_APP_API_PATH + `/payments/${id}/current_mau`,
        onSuccess,
        onError
    );
};
export const getDashboardUrlAPICall = (
    id,
    onSuccess,
    onError
) => {
    GET(
        process.env.REACT_APP_API_PATH + `/payments/stripe_dashboard/${id}`,
        onSuccess,
        onError
    );
};

export const getCurrentUsageAPICall = (
    id,
    onSuccess,
    onError
) => {
    GET(
        process.env.REACT_APP_API_PATH + `/payments/${id}/current_usage`,
        onSuccess,
        onError
    );
};

export const createSubscriptionAPICall = (
    instanceId,
    onSuccess,
    onError
) => {
    const data = {
        id: instanceId
    }

    POST(
        process.env.REACT_APP_API_PATH + `/payments/create_subscription`,
        data,
        onSuccess,
        onError
    );
};
export const cancelSubscriptionAPICall = (
    instanceId,
    onSuccess,
    onError
) => {
    const data = {
        id: instanceId
    }
    POST(
        process.env.REACT_APP_API_PATH + `/payments/cancel_subscription`,
        data,
        onSuccess,
        onError
    );
};
