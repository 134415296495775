import React from "react";
import styled from "styled-components";
import { ACTIVE, INACTIVE, APPS, WEB, WEB_SWITCH, SDK, PLAN_OPTION, MEMBERS_OPTION } from "../../constants/OptionsConstants";
import OptionSelectComponent from "../../components/Generic/OptionSelectComponent";

const SettingsOptionsLine = ({ selectedOption, setSelectedOption }) => {
    const OPTIONS_LIST = [
        {
            text: "Plan",
            value: PLAN_OPTION,
        },
        {
            text: "Members",
            value: MEMBERS_OPTION,
        },
    ];


    return (
        <Container>
            <OptionSelectComponent
                optionsList={OPTIONS_LIST}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
            />

        </Container>
    );
};

export default SettingsOptionsLine;
const Container = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
`;
