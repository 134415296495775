import React from "react";
import styled from "styled-components";
import { lightThemeColors } from "../../assets/styles/colors";
import { ReactComponent as SearchIcon } from "../../assets/icons/generic/SearchIcon.svg";

const CustomSearchInput = ({ style, value, setValue, palceholder, ...props }) => {
  return (
    <Container style={style}>
      <SearchIcon />
      <input
        {...props}
        placeholder={palceholder}
        value={value}
        onChange={(e) => setValue(e.currentTarget.value)}
      />
    </Container>
  );
};

export default CustomSearchInput;

const Container = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  background: ${lightThemeColors.menuBackground};
  color: ${lightThemeColors.darkColorFaded};
  padding: 8px;
  border-radius: 5px;
  width: 100%;

  svg {
    margin-left: 12px;
  }

  input {
    color: ${lightThemeColors.darkColorFaded};
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    text-align: left;
    background: none;
    width: 100%;
    outline: none;
    border: none;
  }
`;
