import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { lightThemeColors } from "../../assets/styles/colors";
import SecondaryButton from "../Generic/SecondaryButton";
import OptionSelectComponent from "../Generic/OptionSelectComponent";
import { DESKTOP, MOBILE, WEB } from "../../constants/OptionsConstants";
import { ReactComponent as CloseIconWhite } from "../../assets/icons/generic/CloseIconWhite.svg";
import PrimaryButton from "../../components/Generic/PrimaryButton";

const PreviewComponent = ({
  ref,
  handleClosePreview,
  generatedHtmlPreview,
  pageBackground,

}) => {
  const [menuOption, setMenuOption] = useState(DESKTOP);
  const iframeRef = useRef();


  const OPTIONS_LIST = [
    {
      text: "Desktop",
      value: DESKTOP,
    },
    {
      text: "Mobile",
      value: MOBILE,
    },
  ];

  const displayHtmlInIframe = () => {
    iframeRef.current.contentWindow.document.open();
    iframeRef.current.contentWindow.document.write(generatedHtmlPreview);
    iframeRef.current.contentWindow.document.close();
  };



  useEffect(() => {
    displayHtmlInIframe();
  }, [menuOption]);

  return (
    <FullscreenContainer>
      <Header>
        <Title>Message Preview </Title>
        <PrimaryButton
          bgVariant={"transparent"}
          styled={{ padding: "0" }}
          icon={
            <CloseIconWhite style={{ minWidth: "25px", minHeight: "25px" }} />
          }
          onClick={() => handleClosePreview()}
        />
      </Header>

      <SelectorContainer>
        <OptionSelectComponent
          optionsList={OPTIONS_LIST}
          selectedOption={menuOption}
          setSelectedOption={setMenuOption}
        />
      </SelectorContainer>
      <Container
        ref={ref}
        pageBackground={menuOption === DESKTOP && pageBackground}
      >
        {menuOption === DESKTOP && (
          <DesktopView
            ref={iframeRef}
            pageBackground={pageBackground}
          />
        )}
        {menuOption === MOBILE && (
          <MobileView
            height={"640"}
            width={"360"}
            ref={iframeRef}
            pageBackground={pageBackground}
          />
        )}
      </Container>
    </FullscreenContainer>
  );
};

export default PreviewComponent;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: auto;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  background: ${(props) => props.pageBackground};
`;

const SelectorContainer = styled.div`
  display: flex;
  align: center;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 20px;
`;

const FullscreenContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 4;
  //   background: ${lightThemeColors.menuBorder};
  background: rgba(248, 248, 248, 1);
`;

const Header = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 7.5px 40px;
  gap: 20px;
  background: ${lightThemeColors.primaryColor};
  width: 100%;
`;

const Title = styled.h1`
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  color: ${lightThemeColors.background};
`;
const DesktopView = styled.iframe`
  height: 100%;
  width: 100%;
  border:none;
`;
const MobileView = styled.iframe`
  max-width: 360px;
  width: 100%;
  height: 100%;
  max-height: 640px;
  border: 1px solid rgba(19, 36, 65, 0.2);
  margin: auto;
  overflow: auto;
  height: 100%;
  width: 100%;
  background: ${(props) => props.pageBackground};
`;
