import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { lightThemeColors } from "../../assets/styles/colors";
import ConfigurationSectionHeader from "../configurationComponents/ConfigurationSectionHeader";
import InputFieldWithValidation from "../../components/Generic/InputFieldWithValidation";
import { useExpanded } from "../../hooks/useExpanded";
import SecondaryButton from "../Generic/SecondaryButton";

const CreateNewMessageModalContent = ({
    noLabel,
    setIsPreviewOpen,
    handleAddContentButton,
    generatedHtml,
    generatedHtmlPreview,
    readMessage
}) => {

    const [expanded, expand] = useExpanded(true);

    const handlePreviewContentButton = () => {
        setIsPreviewOpen(true);
    }

    return (
        <Container>
            <ConfigurationSectionHeader
                title={"Content"}
                handleExpand={expand}
                expanded={expanded}
                requiredLabel={!noLabel}
                isValid={readMessage ? generatedHtmlPreview : generatedHtml}
                showValidation
            />

            {expanded && (
                <ContentContainer>

                    <SubcategoryContent>
                        <Line></Line>
                        <Line style={{ gap: "50px" }}>
                            <PreviewPlaceHolder srcDoc={readMessage ? generatedHtmlPreview : generatedHtml}>

                            </PreviewPlaceHolder>

                            <ColumnGroup>

                                <InfoText>
                                    This is the content of your message, you can add text, images, videos and links. This will be shown when the user opens the message.
                                </InfoText>
                                {!readMessage ? (
                                    <>
                                        <SecondaryButton
                                            onClick={() => handleAddContentButton()}
                                            text={"Add content"}
                                        />
                                        <SecondaryButton
                                            disabled={!generatedHtml}
                                            onClick={() => handlePreviewContentButton()}
                                            text={"Preview content"}
                                        />
                                    </>
                                )
                                    :
                                    (
                                        <SecondaryButton
                                            disabled={!generatedHtmlPreview}
                                            onClick={() => handlePreviewContentButton()}
                                            text={"Preview content"}
                                        />
                                    )
                                }
                            </ColumnGroup>
                        </Line>

                    </SubcategoryContent>
                </ContentContainer>
            )}
        </Container>
    );
};

export default CreateNewMessageModalContent;

const InfoText = styled.p`
  color: ${lightThemeColors.inputSubtitleColor};
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: ${lightThemeColors.background};
  width: 100%;
  gap: 10px;
`;

const ContentContainer = styled.div`
  display: flex;
  overflow: hidden;
  flex-wrap: wrap;
  padding-bottom: 20px;
  row-gap: 20px;
`;
const SubcategoryContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
  max-width: 620px;
`;

const ColumnGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
`;

const Line = styled.div`
  display: flex;
  align-items: center;
  gap: 0px;
`;
const PreviewPlaceHolder = styled.iframe`
display:flex;
max-height:250px;
min-height:250px;
height:100%;
width:100%;
max-width:200px;
border:1px solid ${lightThemeColors.darkColorFullFaded};
background:${lightThemeColors.menuBackground};
border-radius:5px;
`