import React, { useEffect, useReducer, useRef } from "react";
import styled from "styled-components";
import { lightThemeColors } from "../../assets/styles/colors";
import { ReactComponent as ValidIcon } from "../../assets/icons/generic/validCircle.svg";
const InputWithFixSection = ({
  title,
  subtitle,
  label,
  inputValue,
  labelAction,
  handleInputChange,
  inputName,
  valid,
  style,
  placeholder,
  noValidation,
  type,
  readOnly,
  fixInputValue,
}) => {

  const ref = useRef();
  const spanRef = useRef();

  useEffect(() => {
    if (spanRef.current && ref.current) {
      ref.current.style.width = `${spanRef.current.offsetWidth - 22}px`;
    }
  }, [inputValue]);

  return (
    <Container style={style} onClick={(e) => ref.current.focus()}>
      {title && <h1>{title}</h1>}
      {subtitle && <h2>{subtitle}</h2>}
      <InputContainer>
        <AutoGrowInputContainer>
          <AutoGrouInput
            ref={ref}
            type="text"
            value={inputValue}
            onChange={handleInputChange}
          />
          <AutoGrowSpan ref={spanRef}>  {inputValue || ' '}</AutoGrowSpan>
        </AutoGrowInputContainer>
        <FixedContainer>
          <p>.{fixInputValue}</p>
        </FixedContainer>
        {!noValidation ? (
          valid ? (
            <ValidIcon style={{ color: lightThemeColors.validGreen }} />
          ) : (
            <ValidIcon style={{ color: lightThemeColors.darkColorFullFaded }} />
          )
        ) : (
          ""
        )}
      </InputContainer>
      {label && <label onClick={labelAction}>{label}</label>}
    </Container>
  );
};

export default InputWithFixSection;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  h1 {
    color: ${lightThemeColors.darkColorFaded};
    font-size: 18px;
    font-weight: 700;
    line-height: 27px;
    margin-bottom: 5px;
  }
  h2 {
    color: ${lightThemeColors.inputSubtitleColor};
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
  label {
    cursor: pointer;
    color: ${lightThemeColors.primaryColor};
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
  }
`;

const InputContainer = styled.div`
  position: relative;
  display:flex;
  align-items: center;
  padding: 7px 15px;
  border-radius: 5px;
  border: 1px solid ${lightThemeColors.darkColorFullFaded};
  background: ${(props) =>
    props.readOnly
      ? lightThemeColors.menuBackground
      : lightThemeColors.background};
  svg {
    margin-left: auto;
    max-height: 20px;
    max-width: 20px;
  }
`;

const AutoGrowInputContainer = styled.div`
  position: relative;
  display: inline-block;

`;

const AutoGrouInput = styled.input`
  width: 1px; /* Start with a minimal width */
  box-sizing: content-box;
  border: 1px solid #ccc;
  padding: 4px 2px;
  font-size: 16px;
  border:none;
outline:none;
`;
const AutoGrowSpan = styled.span`
  visibility: hidden;
  white-space: pre;
  font-size: 16px;
  padding: 4px 8px;
  position: absolute;
  left: 0;
  top: 0;
  height: 0;
  overflow: hidden;
`;
const FixedContainer = styled.div`
  display: flex;
  min-width: fit-content;
  margin-right: auto;
  p {
    color: ${lightThemeColors.primaryColor};
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
  }
`;
