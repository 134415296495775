import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { lightThemeColors } from "../../assets/styles/colors";
import ConfigurationSectionHeader from "../configurationComponents/ConfigurationSectionHeader";
import InputFieldWithValidation from "../../components/Generic/InputFieldWithValidation";
import LableWithArrow from "../configurationComponents/LableWithArrow";
import FacebookCardPreview from "../configurationComponents/FacebookCardPreview";
import SocialMediaButtonsGroup from "../configurationComponents/SocialMediaButtonsGroup";
import {
  FACEBOOK,
  FILE,
  LINK,
  LINKEDIN,
  X,
} from "../../constants/OptionsConstants";
import { ReactComponent as CloseIconModal } from "../../assets/icons/generic/CloseModalIcon.svg";
import SecondaryButton from "../Generic/SecondaryButton";
import OptionSelectComponent from "../Generic/OptionSelectComponent";
import { useExpanded } from "../../hooks/useExpanded";
import XCardPreview from "../configurationComponents/XCardPreview";
import LinkedInCardPreview from "../configurationComponents/LinkedInCardPreview";
import isURL from "validator/lib/isURL";
import { ReactComponent as PlusIcon } from "../../assets/icons/generic/plus.svg";


const SocialMediaNewLink = ({
  socialMediaTitle,
  setSocialMediaTitle,
  socialMediaSubTitle,
  setSocialMediaSubTitle,
  imageFile,
  setImageFile,
  imageLink,
  setImageLink,
  imageType,
  setImageType,
  domain,
  path,
  imagePreview,
  handleSetImage,
  noLabel,
  disabledActions,
  replaceImage,
  handleSetReplaceImage
}) => {
  const socialMediaButtonsList = [
    {
      value: FILE,
      text: "Upload File",
    },
    {
      value: LINK,
      text: "Link",
    },
  ];

  const [expanded, expand] = useExpanded(false);

  const [socialMediaCardType, setSocialMediaCardType] = useState(FACEBOOK);

  const inputFileRef = useRef();

  const handleInputFile = () => {
    inputFileRef.current.click();
  };

  const handleRemoveImageFile = () => {
    inputFileRef.current.value = null;
    setImageFile(null);
  };

  const displaySocialMediaCard = () => {
    var displayCard = <></>;
    switch (socialMediaCardType) {
      case FACEBOOK:
        displayCard = (
          <FacebookCardPreview
            img={imagePreview}
            title={socialMediaTitle}
            domain={domain + "/" + path}
            subtitle={socialMediaSubTitle}
          />
        );
        break;
      case X:
        displayCard = (
          <XCardPreview
            img={imagePreview}
            title={socialMediaTitle}
            domain={domain + "/" + path}
            subtitle={socialMediaSubTitle}
          />
        );

        break;
      case LINKEDIN:
        displayCard = (
          <LinkedInCardPreview
            img={imagePreview}
            title={socialMediaTitle}
            domain={domain + "/" + path}
            subtitle={socialMediaSubTitle}
          />
        );
        break;

      default:
        break;
    }
    return displayCard;
  };

  return (
    <Container>
      <ConfigurationSectionHeader
        title={"Social Media Preview"}
        handleExpand={expand}
        expanded={expanded}
        optionalLabel={!noLabel}
      />

      {expanded && (
        <ContentContainer>
          <SubcategoryContent>
            <ColumnGroup>
              <InputFieldWithValidation
                title={"Title"}
                placeholder={"Enter a link title, or the default will be used"}
                noValidation
                inputValue={socialMediaTitle}
                handleInputChange={(e) =>
                  setSocialMediaTitle(e.currentTarget.value)
                }
                readOnly={disabledActions}
              />

              <InputFieldWithValidation
                title={"Subtitle"}
                placeholder={
                  "Enter a link subtitle, or the default will be used"
                }
                noValidation
                inputValue={socialMediaSubTitle}
                handleInputChange={(e) =>
                  setSocialMediaSubTitle(e.currentTarget.value)
                }
                readOnly={disabledActions}
              />

              {replaceImage ?
                <>
                  <UploadSectionWrapper>
                    <Title>Image</Title>

                    <OptionSelectComponent
                      optionsList={socialMediaButtonsList}
                      selectedOption={imageType}
                      setSelectedOption={setImageType}
                      disabled={disabledActions}
                    />
                  </UploadSectionWrapper>

                  {imageType === FILE && (
                    <UploadSectionWrapper>
                      {imageFile && (
                        <FilePreviewContainer>
                          <ImgWrapper>
                            <img src={imagePreview} alt="preview" />
                            <CloseBtn onClick={() => handleRemoveImageFile()}>
                              <CloseIconModal />
                            </CloseBtn>
                          </ImgWrapper>
                        </FilePreviewContainer>
                      )}
                      <h1>Upload file</h1>
                      <InfoText>
                        The image file must have a minimum dimension of 200 x 200
                        pixels and needs to be a .jpg or .jpeg file.
                      </InfoText>
                      <div style={{ marginTop: "10px" }}>
                        <SecondaryButton
                          style={{ width: "100%" }}
                          onClick={() => handleInputFile()}
                          text={"Choose File"}
                        />
                      </div>
                      <input
                        type="file"
                        ref={inputFileRef}
                        accept="image/*"
                        style={{ display: "none" }}
                        onChange={(e) => handleSetImage(e)}
                      />
                    </UploadSectionWrapper>
                  )}

                  {imageType === LINK && (
                    <div>
                      <InputFieldWithValidation
                        title={"Image Link"}
                        placeholder={"Enter an image URL..."}
                        valid={imageLink && isURL(imageLink)}
                        inputValue={imageLink}
                        handleInputChange={(e) =>
                          setImageLink(e.currentTarget.value)
                        }
                        readOnly={disabledActions}
                      />
                      <InfoText>
                        The image will be fetched from the provided link
                      </InfoText>
                    </div>
                  )}
                </>

                :

                <UploadSectionWrapper>
                  <Title>Replace Image</Title>
                  <SecondaryButton
                    text={"Replace"}
                    icon={<PlusIcon />}
                    onClick={() => handleSetReplaceImage(true)}
                  />

                </UploadSectionWrapper>
              }
            </ColumnGroup>
            <SeparatorNoMargin />
          </SubcategoryContent>

          <SubcategoryContent>
            <ColumnGroup>
              <Line>
                <Title>Social Media Preview</Title>
              </Line>
              <LableWithArrow
                text={"Here's a preview of your social media appearance."}
                arrowDirection={"down"}
                reverse
              />
              <SocialMediaButtonsGroup
                selectedOption={socialMediaCardType}
                setSelectedOption={setSocialMediaCardType}
              />
              {displaySocialMediaCard()}
            </ColumnGroup>
          </SubcategoryContent>
        </ContentContainer>
      )}
    </Container>
  );
};

export default SocialMediaNewLink;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: ${lightThemeColors.background};
  width: 100%;
  gap: 10px;
`;

const ContentContainer = styled.div`
  display: flex;
  overflow: hidden;
  flex-wrap: wrap;
  padding-bottom: 20px;
  row-gap: 20px;
`;
const SubcategoryContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
  max-width: 620px;
`;
const Line = styled.div`
  display: flex;
  align-items: center;
  gap: 0px;
`;

const SeparatorNoMargin = styled.div`
  display: flex;
  height: 1px;
  width: 100%;
  background: ${lightThemeColors.darkColor};
  opacity: 0.1;
`;
const ColumnGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
`;

const Title = styled.h1`
  color: ${lightThemeColors.darkColorFaded};
  font-size: 15px;
  font-weight: 600;
  line-height: 30px;
  text-align: left;
`;

const UploadSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  h1 {
    color: ${lightThemeColors.darkColor};
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
  }
  h2 {
    color: ${lightThemeColors.darkColorFaded30};
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
  }
`;

const ImageInputSpan = styled.div`
  display: flex;
  padding: 9px 10px;
  border-radius: 5px;
  gap: 10px;
  border: 1px solid ${lightThemeColors.darkColorFullFaded};
  min-height: 50px;
`;

const RemoveFileButton = styled.button`
  border: none;
  cursor: pointer;
  background: none;
  margin-left: auto;
`;

const FilePreviewContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-radius: 5px;
  border: 1px dashed ${lightThemeColors.primaryColor};
  max-width: 280px;
  width: fit-content;
  height: fit-content;
  max-height: 190px;
  position: relative;
  img {
    max-width: 240px;
    max-height: 150px;
    object-fit: cover;
    position: relative;
  }
`;
const CloseBtn = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background: none;
  height: 15px;
  width: 15px;
  cursor: pointer;
  background: ${lightThemeColors.darkColor};
  border-radius: 2px;
  svg {
    height: 100%;
    width: 100%;
  }
`;
const ImgWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  img {
    max-width: 240px;
    max-height: 150px;
    object-fit: cover;
    position: relative;
  }
`;
const InfoText = styled.p`
  color: ${lightThemeColors.inputSubtitleColor};
  margin-top: 15px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
`;
