import React from "react";
import styled from "styled-components";
import { ReactComponent as SmallXIcon } from "../../assets/icons/generic/SmallX.svg";
import { lightThemeColors } from "../../assets/styles/colors";
const SelectedMetricTag = ({ tagName, handleRemove }) => {
  return (
    <Container onClick={handleRemove}>
      <p>{tagName}</p>
      <Separator />
      <RemoveBtn onClick={handleRemove}>
        <SmallXIcon />
      </RemoveBtn>
    </Container>
  );
};

export default SelectedMetricTag;

const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 12px;
  background: ${lightThemeColors.background};
  border: 1px solid ${lightThemeColors.darkColorFullFaded};
  border-radius: 5px;
  gap: 10px;

  p {
    text-transform: capitalize;
    color: ${lightThemeColors.darkColorFaded};
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
    text-align: center;
  }
`;
const RemoveBtn = styled.button`
  border: none;
  width: fit-content;
  display: flex;
  background: none;
  cursor: pointer;
  svg {
    color: ${lightThemeColors.darkColorFullFaded};
    height: 10px;
    width: 10px;
  }
`;
const Separator = styled.div`
  width: 1px;
  min-height: 1px;
  height: 20px;
  background: ${lightThemeColors.darkColorFullFaded};
`;
