import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { lightThemeColors } from "../../assets/styles/colors";
import ConfigurationSectionHeader from "../configurationComponents/ConfigurationSectionHeader";
import InputFieldWithValidation from "../../components/Generic/InputFieldWithValidation";
import { useExpanded } from "../../hooks/useExpanded";

const CreateNewMessageModalOverview = ({ title, setTitle, subtitle, setSubtitle, noLabel,
    disabledActions
}) => {

    const [expanded, expand] = useExpanded(true);

    return (
        <Container>
            <ConfigurationSectionHeader
                title={"Overview"}
                handleExpand={expand}
                expanded={expanded}
                requiredLabel={!noLabel}
                isValid={title && subtitle}
                showValidation
            />

            {expanded && (
                <ContentContainer>
                    <SubcategoryContent>
                        <ColumnGroup>
                            <InputFieldWithValidation
                                title={"Title"}
                                placeholder={"Enter the title of the message"}
                                inputValue={title}
                                handleInputChange={(e) => setTitle(e.currentTarget.value)}
                                noValidation
                                readOnly={disabledActions}
                            />
                            <InfoText>
                                This will be used as a title of the push notification, and will be shown in the messages list.
                            </InfoText>
                        </ColumnGroup>

                        <ColumnGroup>
                            <InputFieldWithValidation
                                title={"Subtitle"}
                                placeholder={"Subtitle of the message"}
                                inputValue={subtitle}
                                handleInputChange={(e) => setSubtitle(e.currentTarget.value)}
                                noValidation
                                readOnly={disabledActions}
                            />
                            <InfoText>
                                This will be used as a subtitle of the push notification, and will be shown in the messages list.
                            </InfoText>
                        </ColumnGroup>
                    </SubcategoryContent>
                </ContentContainer>
            )}
        </Container>
    );
};

export default CreateNewMessageModalOverview;

const InfoText = styled.p`
  color: ${lightThemeColors.inputSubtitleColor};
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: ${lightThemeColors.background};
  width: 100%;
  gap: 10px;
`;

const ContentContainer = styled.div`
  display: flex;
  overflow: hidden;
  flex-wrap: wrap;
  padding-bottom: 20px;
  row-gap: 20px;
`;
const SubcategoryContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
  max-width: 620px;
`;

const ColumnGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
`;

const Line = styled.div`
  display: flex;
  align-items: center;
  gap: 0px;
`;