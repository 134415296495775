import React, { useEffect, useRef, useState } from "react";
import styled, { createGlobalStyle } from "styled-components";
import { lightThemeColors } from "../../assets/styles/colors";
import { v4 as uuidv4 } from "uuid";

import Canvas from "../../components/TemplaterComponents/Canvas";
import { DragDropContext } from "react-beautiful-dnd";
import DraggableList from "../../components/TemplaterComponents/DraggableList";
import {
  BLOCKS,
  BUTTON,
  CENTER,
  IMAGE,
  MEDIUM,
  MENU,
  ROUND,
  SEPARATOR,
  SETTING,
  SMALL,
  TEXT,
  VIDEO,
} from "../../constants/OptionsConstants";
import CustomButton from "../../components/TemplaterComponents/CustomButton";
import CustomEditorMenu from "../../components/TemplaterComponents/CustomEditorMenu";
import CustomEditorButtonMenu from "../../components/TemplaterComponents/CustomEditorButtonMenu";
import { cloneDeep, filter, result } from "lodash";
import CustomImage from "../../components/TemplaterComponents/CustomImage";
import CustomEditorImageMenu from "../../components/TemplaterComponents/CustomEditorImageMenu";
import PrimaryButton from "../../components/Generic/PrimaryButton";
import CustomSeparator from "../../components/TemplaterComponents/CustomSeparator";
import CustomEditorSeparatorMenu from "../../components/TemplaterComponents/CustomEditorSeparatorMenu";
import { ReactComponent as CloseModalIcon } from "../../assets/icons/generic/CloseModalIcon.svg";
import { ReactComponent as CloseIconWhite } from "../../assets/icons/generic/CloseIconWhite.svg";

import CustomVideo from "../../components/TemplaterComponents/CustomVideo";
import CustomEditorVideoMenu from "../../components/TemplaterComponents/CustomEditorVideoMenu";
import SettingsList from "../../components/TemplaterComponents/SettingsList";
import OptionSelectComponent from "../../components/Generic/OptionSelectComponent";
import { ServerStyleSheet } from "styled-components";
import ReactDOMServer from "react-dom/server";
import PreviewComponent from "../../components/TemplaterComponents/PreviewComponent";
import QuillCustomComponent from "../../components/QuillTextEditor/QuillCustomComponent";

const MessageCreator = ({
  setIsModalOpen,
  setGeneratedHtml,
  savedResultListRef,
  setShowPreview,
  savedContentListRef,
  savedPageSettingsRef,
  setGeneratedHtmlPreview
}) => {
  const [displayOption, setDisplayOption] = useState(MENU);
  const [menuOption, setMenuOption] = useState(BLOCKS);

  const [activeElementIndex, setActiveElementIndex] = useState();



  const defaultPageSettings = savedPageSettingsRef.current;

  const [pageBackground, setPageBackground] = useState(
    defaultPageSettings?.pageBackground || lightThemeColors.background
  );

  const [pFontSize, setPFontSize] = useState(
    defaultPageSettings?.pFontSize || 18
  );
  const [h1FontSize, setH1FontSize] = useState(
    defaultPageSettings?.h1FontSize || 42
  );
  const [h2FontSize, setH2FontSize] = useState(
    defaultPageSettings?.h2FontSize || 30
  );
  const [h3FontSize, setH3FontSize] = useState(
    defaultPageSettings?.h3FontSize || 24
  );

  const [resultList, setResultList] = useState([]);
  const resultListRef = useRef([]);
  const componentsListRef = useRef([]); // <--- used to get quill ref and access it's content to generate HTML

  const selectedItemRef = useRef();

  const OPTIONS_LIST = [
    {
      text: "Components",
      value: BLOCKS,
    },
    {
      text: "Page Settings",
      value: SETTING,
    },
  ];

  const handleSelectCurrentNode = (node, option) => {
    if (!node) {
      return;
    }

    setDisplayOption(option);
    selectedItemRef.current = node;
  };

  const handleItemChanges = (id, field, value) => {
    let listToChange = cloneDeep(resultListRef.current);

    let found = listToChange.findIndex((item) => item.id === id);
    listToChange[found].content.props.state[field] = value;

    resultListRef.current = listToChange;
    setResultList(listToChange);
  };

  const handlePageSettingsChange = (field, value) => {
    let clone = cloneDeep(resultList);

    clone.map((item) => {
      if (item.type === "Text") {
        item.content.props.state[field] = value;
      } else {
        return item;
      }
    });
    setResultList(clone);
  };

  const handleRemoveNode = (id) => {
    const foundItemIndex = resultListRef.current.findIndex(
      (item) => item.id === id
    );

    if (foundItemIndex >= 0) {
      let filteredList = resultListRef.current.filter(
        (item, index) => index !== foundItemIndex
      );

      componentsListRef.current = componentsListRef.current.filter(
        (item, index) => index !== foundItemIndex
      );

      resultListRef.current = filteredList;
      setResultList(filteredList);
      setActiveElementIndex(null);
      setDisplayOption(MENU);
    }
  };

  const GlobalStyle = createGlobalStyle`
   *{
     box-sizing: border-box;
    margin: 0;
    padding: 0;
     }

    html, body {
      margin: 0;
      padding: 0;
      font-family: 'Lato', sans-serif;
      width: 100%;
      height: 100%;
      overflow: hidden;
      position:relative;
      -moz-osx-font-smoothing: antialiased;
      -webkit-font-smoothing: antialiased;
      text-rendering: optimizelegibility;
    }

    .container{
    display:flex;
    flex-direction:column;
    height:100%;
    overflow:auto;
    position:relative;
    margin-left:auto;
    margin-right:auto;
    max-width:1440px;
    padding:20px;
    background:${pageBackground};
    }
  `;

  const defaultBtnState = {
    size: MEDIUM,
    position: CENTER,
    form: ROUND,
    background: lightThemeColors.primaryColor,
    textColor: lightThemeColors.background,
    paddingTop: "20",
    paddingBottom: "20",
    paddingLeft: "30",
    paddingRight: "30",
    text: "Your text here....",
  };

  const defaultImgState = {
    src: "",
    alt: "",
    title: "",
    size: SMALL,
    position: CENTER,
  };

  const defaultVideoStyle = {
    src: "",
    size: MEDIUM,
    position: CENTER,
  };

  const defaultSeparatorState = {
    minHeight: "2",
    background: lightThemeColors.menuBackground,
    form: ROUND,
    marginTop: "0",
    marginBottom: "0",
  };

  const defaultQuillStyle = {
    h1FontSize: h1FontSize,
    h2FontSize: h2FontSize,
    h3FontSize: h3FontSize,
    pFontSize: pFontSize,
  };

  const componentsList = [
    {
      id: uuidv4(),
      name: "text-btn",
      type: TEXT,
      content: <CustomMenuButton>{TEXT}</CustomMenuButton>,
    },
    {
      id: uuidv4(),
      name: "button-btn",
      type: BUTTON,
      content: <CustomMenuButton>{BUTTON}</CustomMenuButton>,
    },
    {
      id: uuidv4(),
      name: "image-btn",
      type: IMAGE,
      content: <CustomMenuButton>{IMAGE}</CustomMenuButton>,
    },
    // {
    //   id: uuidv4(),
    //   name: "video-btn",
    //   type: VIDEO,
    //   content: <CustomMenuButton>{VIDEO}</CustomMenuButton>,
    // },
    {
      id: uuidv4(),
      name: "separator-btn",
      type: SEPARATOR,
      content: <CustomMenuButton>{SEPARATOR}</CustomMenuButton>,
    },
  ];

  const handleAddtoRefList = (refItem, index) => {
    const componentListRefCopy = Array.from(componentsListRef.current);
    componentListRefCopy.splice(index, 0, refItem);
    componentsListRef.current = componentListRefCopy;
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;

    // If no destination, exit the function
    if (!destination) {
      return;
    }

    // Adding items from list1 to list2 without removing them from list1
    if (
      source.droppableId === "componentsListId" &&
      destination.droppableId === "board"
    ) {
      const copiedItem = componentsList[source.index];
      let itemToAdd = copiedItem;

      if (source.index === 0) {
        itemToAdd.content = (
          <QuillCustomComponent
            handleSelectCurrentNode={handleSelectCurrentNode}
            state={defaultQuillStyle}
            handleAddtoRefList={handleAddtoRefList}
            dropIndex={destination.index}
          />
        );
      }

      if (source.index === 1) {
        itemToAdd.content = (
          <CustomButton
            handleSelectCurrentNode={handleSelectCurrentNode}
            state={defaultBtnState}
            handleAddtoRefList={handleAddtoRefList}
            dropIndex={destination.index}
          />
        );
      }
      if (source.index === 2) {
        itemToAdd.content = (
          <CustomImage
            handleSelectCurrentNode={handleSelectCurrentNode}
            state={defaultImgState}
            handleAddtoRefList={handleAddtoRefList}
            dropIndex={destination.index}
          />
        );
      }
      // if (source.index === 3) {
      //   itemToAdd.content = (
      //     <CustomVideo
      //       handleSelectCurrentNode={handleSelectCurrentNode}
      //       state={defaultVideoStyle}
      //       handleAddtoRefList={handleAddtoRefList}
      //       dropIndex={destination.index}
      //     />
      //   );
      // }
      if (source.index === 3) {
        itemToAdd.content = (
          <CustomSeparator
            handleSelectCurrentNode={handleSelectCurrentNode}
            state={defaultSeparatorState}
            handleAddtoRefList={handleAddtoRefList}
            dropIndex={destination.index}
          />
        );
      }

      const updatedResultList = Array.from(resultListRef.current);
      updatedResultList.splice(destination.index, 0, itemToAdd); // Add to the specified position in list2
      resultListRef.current = updatedResultList;
      setResultList(updatedResultList);

    } else if (
      source.droppableId === "board" &&
      destination.droppableId === "board"
    ) {

      const updatedResultList = Array.from(resultListRef.current);
      const [movedItem] = updatedResultList.splice(source.index, 1);
      updatedResultList.splice(destination.index, 0, movedItem);
      resultListRef.current = updatedResultList;
      setResultList(updatedResultList);
      console.log(resultListRef.current, "ListRef.current")

      const componentListRefCopy = Array.from(componentsListRef.current);
      const [movedItemRef] = componentListRefCopy.splice(source.index, 1);
      componentListRefCopy.splice(destination.index, 0, movedItemRef);
      componentsListRef.current = componentListRefCopy;

      console.log(componentsListRef.current, "componentsListRef.current")

    }
  };

  function decodeHtmlEntities(encodedString) {
    const textArea = document.createElement("textarea");
    textArea.innerHTML = encodedString;
    return textArea.value;
  }

  const getQuillContentAsHtml = (editor) => {
    const thisEditor = editor.getEditor();
    thisEditor.enable(false);
    let returnHtml = decodeHtmlEntities(thisEditor.container.innerHTML);

    thisEditor.enable(true);
    return returnHtml;
  };

  function CombinedComponent({ elements }) {
    return (
      <div>
        {elements.map((element, index) => (
          <div key={index} style={{ marginBottom: "10px" }}>
            {element}
          </div>
        ))}
      </div>
    );
  }

  // Convert JSX array to HTML string
  const convertJSXArrayToHTML = (components) => {

    const sheet = new ServerStyleSheet();
    try {
      const elements = components.map((component, index) => {
        if (component.type === "Text") {
          // Get the Quill content as HTML
          let quillContent = getQuillContentAsHtml(
            componentsListRef.current[index]
          );
          // Return the actual quill content, not the string literal "quillContent"
          return <div dangerouslySetInnerHTML={{ __html: quillContent }} />;
        } else {
          // Render the static markup of the component's content
          const htmlString = ReactDOMServer.renderToStaticMarkup(
            sheet.collectStyles(component.content)
          );

          // Return static HTML string, ensuring React can handle it
          return <div dangerouslySetInnerHTML={{ __html: htmlString }} />;
        }
      });
      // Get the collected style tags (including global and component-specific)

      const htmlString = ReactDOMServer.renderToStaticMarkup(
        sheet.collectStyles(
          <>
            <GlobalStyle />
            <CombinedComponent elements={elements} />
          </>
        )
      );

      const styleTags = sheet.getStyleTags();

      return { html: htmlString, styleTags };
    } finally {
      sheet.seal();
    }
  };

  // Function to generate full HTML with global and component styles
  const handleSave = (preview) => {
    const { html: htmlString, styleTags } = convertJSXArrayToHTML(resultListRef.current);

    // Full HTML string with styles and components
    const fullHTML = `
        <!DOCTYPE html>
        <html>
        <head>
          <title>Message</title>
          <link
            href="https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap"
            rel="stylesheet"
          />
        <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/quill@2.0.2/dist/quill.core.css" />
      <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1">
    <style>
        .ql-editor {
            padding: 0;
        user-select: text; /* Allow text selection */
        cursor: text; /* Show text selection cursor */

        }
        .ql-container {
            border: none;
        }
        .ql-editor p {
            font-size: ${pFontSize}px;
        }
        .ql-editor h1 {
            font-size: ${h1FontSize}px;
        }
        .ql-editor h2 {
            font-size: ${h2FontSize}px;
        }
        .ql-editor h3 {
            font-size: ${h3FontSize}px;
        }
        </style>

          ${styleTags} <!-- Includes both global and component styles -->
        </head>
        <body>
        <div class="container">
          ${htmlString} <!-- Includes the rendered component content -->
          </div>
        </body>
          <script src='https://cdn.jsdelivr.net/npm/react-player/dist/ReactPlayer.standalone.js'></script>
        </html>
      `;

    if (preview) {
      setGeneratedHtmlPreview(fullHTML);
      return;
    }

    setGeneratedHtml(fullHTML); // Save the generated HTML for further use

    savedResultListRef.current = resultListRef.current;
    setItemsContentForSaving(componentsListRef.current);

    setIsModalOpen(false);
  };

  const handleDisplayPreview = () => {
    handleSave(true);
    setShowPreview(true);
  };

  const handleCloseMessageCreator = () => {
    setIsModalOpen(false);
  };

  const setItemsContentForSaving = (refItemList) => {
    let newContentList = refItemList.map((item, index) => {
      if (item.editor) {
        return item.value;
      }
      return undefined;
    });
    savedContentListRef.current = newContentList;

    let pageSettingsItem = {
      pFontSize: pFontSize,
      h1FontSize: h1FontSize,
      h2FontSize: h2FontSize,
      h3FontSize: h3FontSize,
      pageBackground: pageBackground,
    };
    savedPageSettingsRef.current = pageSettingsItem;
  };

  useEffect(() => {
    let newSavedResultListRef = cloneDeep(savedResultListRef.current);

    newSavedResultListRef.map((item, index) => {
      item.content.props.handleSelectCurrentNode = handleSelectCurrentNode;
      item.content.props.handleAddtoRefList = handleAddtoRefList;
      item.content.props.dropIndex = index
      if (item.type === "Text") {
        let textItemContent = savedContentListRef.current[index];
        item.content.props.initialContent = textItemContent;
      }
    });

    setResultList(newSavedResultListRef);

    resultListRef.current = newSavedResultListRef;

  }, []);

  return (
    <FullscreenContainer>


      <Content>
        <SaveMenu>
          <Title>Message content</Title>
          <PrimaryButton
            bgVariant={lightThemeColors.background}
            styled={{
              padding: "2px 12px",
              marginLeft: "auto",
              color: lightThemeColors.primaryColor,
            }}
            text={"Preview"}
            onClick={() => handleDisplayPreview()}
          />
          <PrimaryButton
            bgVariant={lightThemeColors.background}
            styled={{
              padding: "2px 12px",
              color: lightThemeColors.primaryColor,
            }}
            text={"Save"}
            onClick={() => handleSave()}
          />
          <PrimaryButton
            bgVariant={"transparent"}
            styled={{ padding: "0" }}
            icon={
              <CloseIconWhite style={{ minWidth: "25px", minHeight: "25px" }} />
            }
            onClick={() => handleCloseMessageCreator()}
          />
        </SaveMenu>
        <TemplaterWrapper>
          <DragDropContext onDragEnd={onDragEnd}>
            <Canvas
              items={resultList}
              activeElementIndex={activeElementIndex}
              setActiveElementIndex={setActiveElementIndex}
              handleRemoveNode={handleRemoveNode}
              pageBackground={pageBackground}
              handleSelectCurrentNode={handleSelectCurrentNode}
            />

            {displayOption === MENU && (
              <CreatorMenu>
                <CreatorMenuHeader>
                  <OptionSelectComponent
                    optionsList={OPTIONS_LIST}
                    selectedOption={menuOption}
                    setSelectedOption={setMenuOption}
                  />
                </CreatorMenuHeader>
                {menuOption === BLOCKS && (
                  <DraggableList items={componentsList} />
                )}
                {menuOption === SETTING && (
                  <SettingsList
                    pageBackground={pageBackground}
                    setPageBackground={setPageBackground}
                    h1FontSize={h1FontSize}
                    h2FontSize={h2FontSize}
                    h3FontSize={h3FontSize}
                    pFontSize={pFontSize}
                    setPFontSize={setPFontSize}
                    setH1FontSize={setH1FontSize}
                    setH2FontSize={setH2FontSize}
                    setH3FontSize={setH3FontSize}
                    handlePageSettingsChange={handlePageSettingsChange}
                  />
                )}
              </CreatorMenu>
            )}

            {displayOption === TEXT && (
              <CustomEditorMenu
                quillRef={selectedItemRef.current}
                setDisplayOption={setDisplayOption}
                setActiveElementIndex={setActiveElementIndex}
              />
            )}

            {displayOption === BUTTON && (
              <CustomEditorButtonMenu
                resultList={resultList}
                setDisplayOption={setDisplayOption}
                activeElementIndex={activeElementIndex}
                setActiveElementIndex={setActiveElementIndex}
                handleItemChanges={handleItemChanges}
              />
            )}

            {displayOption === IMAGE && (
              <CustomEditorImageMenu
                resultList={resultList}
                setDisplayOption={setDisplayOption}
                activeElementIndex={activeElementIndex}
                setActiveElementIndex={setActiveElementIndex}
                handleItemChanges={handleItemChanges}
              />
            )}

            {displayOption === VIDEO && (
              <CustomEditorVideoMenu
                resultList={resultList}
                setDisplayOption={setDisplayOption}
                activeElementIndex={activeElementIndex}
                setActiveElementIndex={setActiveElementIndex}
                handleItemChanges={handleItemChanges}
              />
            )}
            {displayOption === SEPARATOR && (
              <CustomEditorSeparatorMenu
                resultList={resultList}
                setDisplayOption={setDisplayOption}
                activeElementIndex={activeElementIndex}
                setActiveElementIndex={setActiveElementIndex}
                handleItemChanges={handleItemChanges}
              />
            )}
          </DragDropContext>
        </TemplaterWrapper>
      </Content>
    </FullscreenContainer>
  );
};

export default MessageCreator;

const TemplaterWrapper = styled.div`
  display: flex;
  overflow: hidden;
  padding: 0;
  position: relative;
  height: 100%;
  width: 100%;
`;

const FullscreenContainer = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 4;
  background: ${lightThemeColors.background};
`;
const Line = styled.div`
  display: flex;
  gap: 30px;
  align-items: center;
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const SaveMenu = styled.div`
  display: flex;
  width: 100%;
  padding: 10px 40px;
  gap: 20px;
  background: ${lightThemeColors.primaryColor};
`;
const Title = styled.h1`
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  color: ${lightThemeColors.background};
`;
const CreatorMenu = styled.div`
  max-width: 600px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  top: 0;
  background: ${lightThemeColors.menuBackground};
  position: relative;
  padding: 40px;
`;
const CreatorMenuHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const CustomMenuButton = styled.div`
  border-radius: 10px;
  border: 1px solid rgba(85, 158, 255, 0.2);
  padding: 10px;
  background-color: #fff;
  color: ${lightThemeColors.primaryColor};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 5px;
  font-size: 16px;
  font-weight: 400;
  width: 100%;
  cursor: pointer;
  ${(props) =>
    props.selected
      ? `
    background:${lightThemeColors.darkColor};
    color:${lightThemeColors.background};
    `
      : ""}
`;
