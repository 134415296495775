import React from 'react'
import styled from 'styled-components'
import PrimaryButton from './PrimaryButton'
import { ReactComponent as PlusIcon } from "../../assets/icons/generic/plus.svg"
import { lightThemeColors } from '../../assets/styles/colors'
import { useOutletContext } from 'react-router-dom'
import AccountButton from './AccountButton'

const PageHeader = ({ option, setOption }) => {
    const { setAddNewLinkModalOpen } = useOutletContext();
    return (
        <HeaderWrapper>
            <div style={{ marginLeft: 'auto' }}>
                <PrimaryButton
                    icon={<PlusIcon />}
                    text={"New Link"}
                    onClick={() => setAddNewLinkModalOpen(true)}
                />
            </div>
            <AccountButton />
        </HeaderWrapper>
    )
}

export default PageHeader
const HeaderWrapper = styled.div`
display:flex;
justify-content:space-between;
align-items:center;
background:${lightThemeColors.background};
padding:15px 50px;
gap:30px;
`