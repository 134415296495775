import { GET, POST } from "../API";

let eventSearchSource;
let eventOverviewSource;

export const getEventsForSearchParamsAPICall = (
    projectId,
    data,
    onSuccess,
    onError
) => {

    eventSearchSource = POST(
        process.env.REACT_APP_API_PATH + `/projects/${projectId}/events_for_search_params`,
        data,
        onSuccess,
        onError,
        true,
        eventSearchSource
    );
};

export const addEventAPICall = (
    event,
    created_at,
    link_id,
    engagement_time,
    app_version,
    platform,
    build,
    project_id,
    onSuccess,
    onError
) => {
    const data = {
        event: event,
        created_at: created_at,
        link_id: link_id,
        engagement_time: engagement_time,
        app_version: app_version,
        platform: platform,
        build: build,
        project_id: project_id,
    }

    eventSearchSource = POST(
        process.env.REACT_APP_API_PATH + `/projects/add_event`,
        data,
        onSuccess,
        onError,
        true,
        eventSearchSource
    );
};

export const getEventsSortedByParamsAPICall = (
    project_id,
    data,
    onSuccess,
    onError
) => {

    // const data = {
    //     event_type: event_type,
    //     ascendent: ascendent,
    //     page: page,
    // }
    eventSearchSource = POST(
        process.env.REACT_APP_API_PATH + `/projects/${project_id}/events_sorted_by_param`,
        data,
        onSuccess,
        onError,
        true,
        eventSearchSource
    );
};

export const getMetricsValuesForEventsAPICall = (
    project_id,
    onSuccess,
    onError
) => {

    GET(
        process.env.REACT_APP_API_PATH + `/projects/${project_id}/metrics_values_for_events`,
        onSuccess,
        onError,

    );
};

export const getEventsForOverviewAPICall = (
    project_id,
    data,
    onSuccess,
    onError
) => {

    eventOverviewSource = POST(
        process.env.REACT_APP_API_PATH + `/projects/${project_id}/events_for_overview`,
        data,
        onSuccess,
        onError,
        true,
        eventOverviewSource
    );
};
export const getEventsForPaymentScreenAPICall = (
    project_id,
    data,
    onSuccess,
    onError
) => {

    eventOverviewSource = POST(
        process.env.REACT_APP_API_PATH + `/projects/${project_id}/events_for_payment_screen`,
        data,
        onSuccess,
        onError,
        true,
        eventOverviewSource
    );
};
