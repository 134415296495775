import React from 'react'
import styled from 'styled-components'
import { lightThemeColors } from '../../assets/styles/colors'
import { DEFAULT_PALCEHOLDER_LINK } from '../../constants/OptionsConstants'
import { useInstanceContext } from '../../context/useInstanceContext'
import { useConfigurationContext } from '../../context/useConfigurationContext'

const XCardPreview = ({ domain, title, subtitle, img }) => {
    const { selectedProject } = useInstanceContext()
    const { projectDomain } = useConfigurationContext()

    const displayTitle = () => {
        if (title) {
            return title
        } else {
            if (projectDomain.generic_title) {
                return projectDomain.generic_title
            } else {
                return selectedProject.name
            }
        }
    }
    const displaySubTitle = () => {

        if (subtitle) {
            return subtitle
        } else {
            if (projectDomain.generic_subtitle) {
                return projectDomain.generic_subtitle
            } else {
                return "Dynamic links, attributions, and referrals across mobile and web platforms."
            }
        }
    }

    const displayImage = () => {
        if (img) {
            return img
        } else {
            if (projectDomain.generic_image_url) {
                return projectDomain.generic_image_url
            } else {
                return DEFAULT_PALCEHOLDER_LINK
            }
        }
    }

    return (
        <Card>
            <ImageWrapper>
                <img src={displayImage()} alt="social-media-preview" />
            </ImageWrapper>
            <InfoWrapper>
                <Title>{displayTitle()}</Title>
                <Subtitle>{displaySubTitle()}</Subtitle>
                <Domain>{domain}</Domain>

            </InfoWrapper>

        </Card>
    )
}

export default XCardPreview

const Card = styled.div`
display:flex;
flex-direction:column;
max-width:343px;
max-height:260px;


`
const InfoWrapper = styled.div`
display:flex;
flex-direction:column;
border:1px solid rgba(0,0,0,0.15);
gap:1px;
padding:6px 8px;
border-radius: 0 0 5px 5px;
background:${lightThemeColors.socialMediaPreviewCard.xCardInfoBg};
`
const ImageWrapper = styled.div`
display:flex;
min-height:200px;

img{
border-radius:5px 5px 0 0;
    width:100%;
    height:100%;
    object-fit:cover;
}
`
const Domain = styled.p`
color:${lightThemeColors.socialMediaPreviewCard.xCardDomainColor};
font-size: 9.16px;
font-weight: 400;
line-height: 12.42px;
text-transform:uppercase;
`
const Title = styled.h1`
color:${lightThemeColors.socialMediaPreviewCard.primaryColor};
font-size: 12px;
font-weight: 600;
line-height: 16px;
`
const Subtitle = styled.p`
color:${lightThemeColors.socialMediaPreviewCard.primaryColor};
font-size: 9px;
font-weight: 500;
line-height: 12px;
text-align: left;
`
