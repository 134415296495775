import React, { useEffect, useState } from "react";
import styled from "styled-components";
import InputFieldWithValidation from "../Generic/InputFieldWithValidation";
import { ReactComponent as RemoveIcon } from "../../assets/icons/generic/removeIcon.svg";
import { ReactComponent as PointSeparatorIcon } from "../../assets/icons/generic/PointSeparatorIcon.svg";

const KeyPairValues = ({
  keyValueItem,
  handleRemove,
  handleChangeKeyValuePair,
  index,
  placeholderKey,
  placeholderValue,
  disabled
}) => {

  const [inputValues, setInputValues] = useState({
    key: keyValueItem.key,
    value: keyValueItem.value,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.currentTarget;
    setInputValues({
      ...inputValues,
      [name]: value,
    });
  };


  useEffect(() => {

    setInputValues({
      key: keyValueItem.key,
      value: keyValueItem.value,
    })

  }, [keyValueItem])

  useEffect(() => {
    handleChangeKeyValuePair(index, inputValues);
  }, [inputValues.key, inputValues.value]);

  return (
    <Container>
      <InputFieldWithValidation
        noValidation
        inputName={"key"}
        placeholder={placeholderKey}
        inputValue={keyValueItem.key}
        handleInputChange={handleInputChange}
      />
      <PointSeparatorIcon />
      <InputFieldWithValidation
        noValidation
        inputName={"value"}
        placeholder={placeholderValue}
        inputValue={keyValueItem.value}
        handleInputChange={handleInputChange}
      />
      <RemoveBtn onClick={() => handleRemove(keyValueItem)} disabled={disabled}>
        <RemoveIcon />
      </RemoveBtn>
    </Container>
  );
};

export default KeyPairValues;
const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 25px;
  svg {
    min-width: 4px;
  }
`;
const RemoveBtn = styled.button`
  border: none;
  cursor: pointer;
  background: none;
  margin-left: auto;
&:disabled{
    opacity:0.3;
}
`;
