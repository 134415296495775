import { DELETE, GET, POST, PUT } from "../API";

export const createInstanceAPICall = (
    name,
    members,
    onSuccess,
    onError
) => {

    let data = {
        name: name,
        members: members
    };

    POST(
        process.env.REACT_APP_API_PATH + "/instances/create",
        data,
        onSuccess,
        onError
    );
};


export const editInstanceAPICall = (
    instanceId,
    name,
    onSuccess,
    onError
) => {
    const data = {
        name: name,
    };

    PUT(
        process.env.REACT_APP_API_PATH + `/instances/${instanceId}`,
        data,
        onSuccess,
        onError
    );
};

export const getInstancesAPICall = (
    onSuccess,
    onError
) => {
    GET(
        process.env.REACT_APP_API_PATH + `/instances`,
        onSuccess,
        onError
    );
};

export const getMembersForInstanceeAPICall = (
    instanceId,
    onSuccess,
    onError
) => {

    GET(
        process.env.REACT_APP_API_PATH + `/instances/${instanceId}/members`,
        onSuccess,
        onError
    );
};

export const addMemberToInstanceAPICall = (
    instanceId,
    email,
    role,
    onSuccess,
    onError
) => {
    const data = {
        email: email,
        role: role,
    };

    POST(
        process.env.REACT_APP_API_PATH + `/instances/${instanceId}/add_member`,
        data,
        onSuccess,
        onError
    );
};

export const removedMemberFromInstanceAPICall = (
    instanceId,
    email,
    onSuccess,
    onError
) => {
    const data = {
        email: email,
    };

    POST(
        process.env.REACT_APP_API_PATH + `/instances/${instanceId}/remove_member`,
        data,
        onSuccess,
        onError
    );
};

export const deleteInstanceAPICall = (
    instanceId,
    onSuccess,
    onError
) => {

    DELETE(
        process.env.REACT_APP_API_PATH + `/instances/${instanceId}`,
        onSuccess,
        onError
    );
};

export const currentUserRoleForInstanceAPICall = (instanceId, onSuccess, onError) => {
    GET(
        process.env.REACT_APP_API_PATH + `/instances/${instanceId}/user_role`,
        onSuccess,
        onError
    );
};

