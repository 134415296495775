import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { lightThemeColors } from "../../assets/styles/colors";

import PlanContent from "../../components/subscriptionComponents/PlanContent";
import BillingDetails from "../../components/subscriptionComponents/BillingDetails";
import EngagementOverview from "../../components/subscriptionComponents/EngagementOverview";
import ChangePlanModal from "./ChangePlanModal";
import InvoiceModal from "./InvoiceModal";
import { useOutletContext } from "react-router-dom";
import DeleteProject from "../../components/settingsSomponents.js/DeleteProject";
import { usePaymentsContext } from "../../context/usePaymentsContext";
import { showGenericError } from "../../helper/Notifications";
import { useInstanceContext } from "../../context/useInstanceContext";
import { AnimatePresence } from "framer-motion";

const SubscriptionPage = () => {
  const [isPlanModalOpen, setIsPlanModalOpen] = useState(false);
  const [isCancelCOnfirmationOpen, setIsCancelConfirmationOpen] =
    useState(false);
  const [isInvoiceModalOpen, setIsInvoiceModalOpen] = useState(false);

  const {
    subscription,
    createSubscription,
    cancelSubscription,
    getSubscriptionDetails,
    getCurrentMau,
  } = usePaymentsContext();

  const { selectedInstance } = useInstanceContext();
  const { setLoading, setContactSaleModalOpen } = useOutletContext();

  const handleUpgradeSubscription = () => {
    setLoading(true);
    createSubscription(
      selectedInstance.id,
      (response) => {
        const link = response.data.url;
        window.location = link;
        setLoading(false);
      },
      () => {
        showGenericError();
        setLoading(false);
      }
    );
  };

  const handleCancelSubscription = () => {
    setLoading(true);
    cancelSubscription(
      selectedInstance.id,
      (response) => {

        setTimeout(() => {
          handleGeSubscriptionDetails();
        }, 2500)

      },
      () => {
        showGenericError();
        setLoading(false);
      }
    );
  };

  const handleGeSubscriptionDetails = () => {
    setLoading(true);
    getSubscriptionDetails(
      selectedInstance.id,
      (response) => {
        setLoading(false);
      },
      (error) => {
        if (error.response?.data?.message !== "No active subscriptions") {
          showGenericError();
        }
        setLoading(false);
      }
    );
  };

  const handleContactSales = () => {
    setContactSaleModalOpen(true);
    setIsPlanModalOpen(false);
  };

  return (
    <Container>
      <EngagementOverview />
      <Separator />
      <Line>
        <PlanContent
          plan={subscription}
          isCancelCOnfirmationOpen={isCancelCOnfirmationOpen}
          setIsCancelConfirmationOpen={setIsCancelConfirmationOpen}
          setIsModalOpen={setIsPlanModalOpen}
          handleCancelSubscription={handleCancelSubscription}
        />

        {subscription && <VerticalSeparator />}
        {subscription && (
          <BillingDetails
            setIsModalOpen={setIsInvoiceModalOpen}
          />
        )}
      </Line>
      <Separator />
      <DeleteProject />
      <AnimatePresence>
        {isPlanModalOpen && (
          <ChangePlanModal
            setIsModalOpen={setIsPlanModalOpen}
            handleUpgradeSubscription={handleUpgradeSubscription}
            handleContactSales={handleContactSales}
          />
        )}
      </AnimatePresence>

      {isInvoiceModalOpen && (
        <InvoiceModal setIsModalOpen={setIsInvoiceModalOpen} />
      )}
    </Container>
  );
};

export default SubscriptionPage;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 30px;
  gap: 40px;

  h2 {
    width: 100%;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  h1 {
    font-size: 24px;
    font-weight: 700;
    color: ${lightThemeColors.darkColor};
  }
  p {
    color: ${lightThemeColors.darkColorFaded30};
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
  }
`;
const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  overflow: auto;
  padding-bottom: 30px;
  padding: 0 50px;
  position: relative;
  height: 100%;
`;
const Separator = styled.div`
  display: flex;
  min-height: 1px;
  height: 1px;
  width: 100%;
  background: ${lightThemeColors.darkColor};
  opacity: 0.1;
`;
const VerticalSeparator = styled.div`
  display: flex;
  height: 100%;
  min-width: 1px;
  background: ${lightThemeColors.darkColor};
  opacity: 0.1;
`;

const Line = styled.div`
  display: flex;
  gap: 30px;
  width: 100%;
`;
