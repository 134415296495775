import React, { useState } from 'react'
import styled from 'styled-components'
import { lightThemeColors } from '../../assets/styles/colors'
import { ReactComponent as HiddenIcon } from "../../assets/icons/generic/HiddenIcon.svg"
import { ReactComponent as ShowIcon } from "../../assets/icons/generic/ShowIcon.svg"

const InputFieldWithHiddenContent = ({
    title,
    subtitle,
    inputValue,
    handleInputChange,
    inputName,
    style,
    placeholder,
    customButton,
    readOnly
}) => {

    const [hiddenContent, setHiddenContent] = useState(true)

    return (


        <Container style={style}>
            {title && <h1>{title}</h1>}
            {subtitle && <h2>{subtitle}</h2>}
            <Line>
                <InputContainer>
                    <input
                        readOnly={readOnly}
                        type={hiddenContent ? "password" : "text"}
                        value={inputValue}
                        onChange={handleInputChange}
                        name={inputName}
                        placeholder={placeholder}
                    />
                    <ToggleHiddenWrapper>
                        {hiddenContent ?
                            <HiddenIcon onClick={() => setHiddenContent(!hiddenContent)} />
                            :
                            <ShowIcon onClick={() => setHiddenContent(!hiddenContent)} />}

                    </ToggleHiddenWrapper>

                </InputContainer>
                {customButton && customButton}
            </Line>
        </Container>
    )
}

export default InputFieldWithHiddenContent
const Container = styled.div`
display:flex;
flex-direction:column;
gap:10px;
width:100%;
h1{
    color:${lightThemeColors.darkColorFaded};
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
}
    h2{
    color:${lightThemeColors.darkColorFaded};
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
}
label{
    cursor:pointer;
    color:${lightThemeColors.primaryColor};
font-size: 16px;
font-weight: 400;
line-height: 24px;
text-align: left;

}
`

const InputContainer = styled.div`
width:100%;
position:relative;
display:flex;
align-items:center;
padding:7px 15px;
border-radius:5px;
border:1px solid ${lightThemeColors.darkColorFullFaded};
gap:5px;
input{


    width:100%;
    outline:none;
    border:none;
color:${lightThemeColors.darkColorFaded};
font-size: 16px;
font-weight: 500;
line-height: 24px;
text-align: left;
}

:: placeholder{
    opacity:0.3;
 }

`
const ToggleHiddenWrapper = styled.div`
cursor:pointer;
display:flex;
align-items:center;
justify-content:center;

svg{
    max-height:20px;
    max-width:20px;
}
`
const Line = styled.div`
display:flex;
align-items:center;
gap:20px;
`