import React from "react";
import styled from "styled-components";
import { lightThemeColors } from "../../assets/styles/colors";
import { ReactComponent as InfoIcon } from "../../assets/icons/sidebar/InfoIcon.svg";
const AudienceDisplayInfoFields = ({ title, value, floatingInfo }) => {
  return (
    <Container>
      <LeftSide>
        <InfoIcon
          style={{ minWidth: "13px" }}
          data-tooltip-id={"audience-tooltip"}
          data-tooltip-content={floatingInfo}
        />
        <p>{title}</p>
      </LeftSide>
      <RightSide>
        <p>{value}</p>
      </RightSide>
    </Container>
  );
};

export default AudienceDisplayInfoFields;

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const LeftSide = styled.div`
  border: 1px solid ${lightThemeColors.menuBorder};
  border-radius: 4px 0 0 4px;
  background: ${lightThemeColors.menuBackground};
  //   background: rgba(244, 249, 255, 1);
  padding: 6px 10px;
  align-items: center;
  display: flex;
  gap: 10px;
  width: 250px;
  p {
    color: ${lightThemeColors.darkColorFaded};
    font-size: 15px;
    font-weight: 500;
    line-height: 16px;
    text-align: left;
  }
`;

const RightSide = styled(LeftSide)`
  background: ${lightThemeColors.background};
  border-radius: 0 4px 4px 0;

  p {
    color: ${lightThemeColors.darkColorFaded};
    font-size: 15px;
    font-weight: 600;
    line-height: 16px;
    text-align: left;
  }
`;
