import React, { useEffect } from 'react'
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import LocalStorage from '../helper/LocalStorage';
import { useUserContext } from '../context/useUserContext';
import LoaderComponent from '../components/Generic/LoaderComponent';

const ProtectedRoute = ({ children, redirectPath = "/login" }) => {

    const { fetchCurrentUser, userRef } = useUserContext();
    const location = useLocation();
    const navigate = useNavigate();


    const authToken = LocalStorage.getAuthenticationToken();


    useEffect(() => {
        if (authToken !== null) {
            fetchCurrentUser(
                function (response) {

                    navigate({ redirectPath, search: location.search });
                },
                function (error) {
                    navigate("/login", { state: { backTo: `${location.pathname}` } })
                }
            );
        }
    }, [])

    if (authToken == null) {
        return (
            <Navigate to={redirectPath} state={{ backTo: `${location.pathname}` }} />
        );
    }

    if (authToken) {
        return <>
            {userRef.current ? children : <LoaderComponent loading={true} />}

        </>;
    }
}

export default ProtectedRoute