import React, { useEffect, useRef } from 'react'
import styled, { keyframes } from 'styled-components'
import { lightThemeColors } from '../../assets/styles/colors';
import { ReactComponent as CloseModalIcon } from "../../assets/icons/generic/CloseModalIcon.svg"
import moment from 'moment';
import { ReactComponent as DownloadIcon } from "../../assets/icons/generic/Download.svg"

import {
    Table,
    Header,
    HeaderRow,
    Body,
    Row,
    HeaderCell,
    Cell,
} from "@table-library/react-table-library/table";
import { useTheme } from "@table-library/react-table-library/theme";


const DisplayCellBasedOnType = ({ type, value, onClick }) => {
    var display =
        <CustomCell >
            <p>{value}</p>
        </CustomCell>

    const leftContentCell =
        <LeftContentCell >
            <p>{value}</p>
        </LeftContentCell>

    const actionCell =
        <ActionCell onClick={onClick}>
            <DownloadIcon />
        </ActionCell>


    switch (type) {
        case "Name":
            display = leftContentCell
            break;
        case "Date":
            display = leftContentCell
            break;
        case "action_cell":
            display = actionCell
            break;

        default:
            break;
    }

    return (
        <>
            {display}
        </>
    )
}

const InvoiceModal = ({ setIsModalOpen }) => {

    const ref = useRef();



    const nodes = [
        {
            id: '0',
            name: 'John Doe',
            date: moment().format("MMM DD,YYYY"),



        }
        ,
        {
            id: '1',
            name: 'John Doe2',
            date: moment().format("MMM DD,YYYY"),


        }
        ,
        {
            id: '2',
            name: 'John Doe3',
            date: moment().format("MMM DD,YYYY"),

        }
        ,
    ];

    const COLUMNS = [
        {
            label: "Invoice",
            sortKey: "name",
            renderCell: (item) => item.name,
            type: "Name"
        },
        {
            label: "Date",
            sortKey: "date",
            renderCell: (item) => moment(item.date).format("MMM DD, YYYY"),
            type: "Date"
        },

        {
            label: "",
            sortKey: "action",
            renderCell: () => <div>action</div>,
            type: "action_cell"

        },

    ]
    const data = { nodes };
    const theme = useTheme({
        HeaderRow: `
        width:100%;
        background:${lightThemeColors.menuBackground};
        border-radius:5px;
        overflow:hidden;
        `,

        HeaderCell: `
        font-size:16px;
        font-weight:700;
        color:${lightThemeColors.darkColorFaded};
        padding:14px;

        :first-child{
            border-radius:5px;
        }
        :last-child{
            border-radius:5px;
        }
        `,
        Cell: `
        padding:14px;
        gap:20px;
        color:${lightThemeColors.darkColorFaded};
        font-size:16px;
        font-weight:${props => props.lightText ? 500 : 700};
        border-bottom:1px solid ${lightThemeColors.menuBackground};
        `,

    })

    const checkCenter = (type) => {
        if (type === "Name" || type === "Date") {
            return "start"
        } else {
            return "center"
        }
    }





    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            if (setIsModalOpen && ref.current && !ref.current.contains(e.target)) {
                setIsModalOpen(false);
            }
        };
        document.addEventListener("mousedown", checkIfClickedOutside);
        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, [setIsModalOpen]);

    useEffect(() => {
        const close = (e) => {
            if (e.keyCode === 27) {
                setIsModalOpen(false)
            }
        }
        window.addEventListener('keydown', close)
        return () => window.removeEventListener('keydown', close)
    }, [])


    return (
        <FullscreenContainer >
            <InviteModal ref={ref}>
                <CloneBtn onClick={() => setIsModalOpen(false)}>
                    <CloseModalIcon />
                </CloneBtn>
                <HeadWrapper>
                    <h1>Invoice History</h1>
                </HeadWrapper>

                <Table data={data} theme={theme} columns={COLUMNS} >
                    {(tableList) => (
                        <>
                            <Header>
                                <HeaderRow >
                                    {COLUMNS.map((column, index) => (
                                        <HeaderCell
                                            key={column.label}
                                            center={checkCenter(column.label)}


                                        >
                                            {column.label}
                                        </HeaderCell>
                                    ))}
                                </HeaderRow>
                            </Header>

                            <Body>
                                {tableList.map((item) => (
                                    <Row key={item.id} item={item}>
                                        {COLUMNS.map((column, index) => (
                                            <DisplayCellBasedOnType
                                                key={column.label + item.id}
                                                value={column.renderCell(item)}
                                                type={column.type}
                                                onClick={() => alert("implement action")}
                                            />
                                        )
                                        )}
                                    </Row>
                                ))}
                            </Body>
                        </>
                    )}
                </Table>


            </InviteModal>

        </FullscreenContainer>
    )
}

export default InvoiceModal
const FullscreenContainer = styled.div`
display:flex;
position:fixed;
top:0;
left:0;
width:100%;
height:100%;
background:rgba(0,0,0,0.1);
z-index:3;
`

const expandAnimation = keyframes`
0% { transform:translateX(100%)}

100% {
   transform: translateX(0)
}

`

const InviteModal = styled.div`
position:relative;
display:flex;
flex-direction:column;
background:${lightThemeColors.background};
max-width:750px;
width:100%;
height:100%;
margin-left:auto;
padding:40px;
animation:${expandAnimation} 400ms linear;
gap:50px;
.control{
  border-radius:5px;
}
`

const CloneBtn = styled.button`
display:flex;
align-items:center;
justify-content:center;
width:fit-content;
border:none;
background:none;
cursor:pointer;
position:absolute;
top:30px;
right:30px;
`
const HeadWrapper = styled.div`
display:flex;
flex-direction:column;
gap:10px;
h1{
color:${lightThemeColors.darkColorFaded};
font-size: 25px;
font-weight: 600;
line-height: 37.5px;
}

`



const CustomCell = styled.td`
padding:14px;
gap:20px;
border-bottom:1px solid ${lightThemeColors.menuBackground};
display:flex;

p{
width:100%;
color:${lightThemeColors.links.tableCellColor};
font-size: 16px;
font-weight: 600;
line-height: 24px;
text-align: center;
}

`

const LeftContentCell = styled(CustomCell)`
justify-content:start;
p{
text-align: start;
}
`
const ActionCell = styled(CustomCell)`
justify-content:center;
display:flex;
cursor:pointer;
`
