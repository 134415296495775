import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { SEPARATOR } from "../../constants/OptionsConstants";
import { handleSeparatorForm } from "../../helper/TemplateCreatorHelpers";

const CustomSeparator = ({
  state,
  handleSelectCurrentNode,
  handleAddtoRefList,
  dropIndex,
}) => {
  const [minHeight, setMinHeight] = useState(state.minHeight);
  const [background, setBackground] = useState(state.background);
  const [form, setForm] = useState(state.form);
  const [marginTop, setMarginTop] = useState(state.marginTop);
  const [marginBottom, setMarginBottom] = useState(state.marginBottom);

  const ref = useRef();

  useEffect(() => {
    setMinHeight(state.minHeight);
    setBackground(state.background);
    setForm(state.form);
    setMarginBottom(state.marginBottom);
    setMarginTop(state.marginTop);
  }, [state]);

  useEffect(() => {
    handleAddtoRefList(ref.current, dropIndex);
  }, []);
  return (
    <Wrapper onClick={() => handleSelectCurrentNode(ref.current, SEPARATOR)}>
      <Separator
        ref={ref}
        form={handleSeparatorForm(form)}
        minHeight={minHeight}
        marginBottom={marginBottom}
        marginTop={marginTop}
        background={background}
      ></Separator>
    </Wrapper>
  );
};

export default CustomSeparator;
const Separator = styled.div`
  display: flex;
  width: 100%;
  min-height: ${(props) => props.minHeight}px;
  background: ${(props) => props.background};
  border-radius: ${(props) => props.form};
  margin-bottom: ${(props) => props.marginBottom}px;
  margin-top: ${(props) => props.marginTop}px;
`;
const Wrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 10px 0px;
`;
