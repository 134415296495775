import React from "react";
import styled from "styled-components";
import { lightThemeColors } from "../../assets/styles/colors";

const CustomEditorMenuButton = ({ children, ...props }) => {
  return <CustomMenuButton {...props}>{children}</CustomMenuButton>;
};

export default CustomEditorMenuButton;

const CustomMenuButton = styled.button`
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  cursor: pointer;
  ${(props) =>
    props.selected
      ? `
    background:${lightThemeColors.primaryColor};
    color:${lightThemeColors.background};
    `
      : ` background:${lightThemeColors.background};
    color:${lightThemeColors.darkColor};
`}
  border: 1px solid rgba(85, 158, 255, 0.2);
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  text-align: center;
`;
