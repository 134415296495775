import React, { useEffect, useRef } from 'react'
import styled, { keyframes } from 'styled-components'
import { lightThemeColors } from '../../assets/styles/colors';
import { ReactComponent as CloseModalIcon } from "../../assets/icons/generic/CloseModalIcon.svg"
import SubscriptionPlanCard from '../../components/subscriptionComponents/SubscriptionPlanCard';
import { usePaymentsContext } from '../../context/usePaymentsContext';
import { motion } from 'framer-motion';


const ChangePlanModal = ({ setIsModalOpen, handleUpgradeSubscription, handleContactSales }) => {
    const { subscription } = usePaymentsContext()
    const ref = useRef();

    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            if (setIsModalOpen && ref.current && !ref.current.contains(e.target)) {
                setIsModalOpen(false);
            }
        };
        document.addEventListener("mousedown", checkIfClickedOutside);
        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, [setIsModalOpen]);

    useEffect(() => {
        const close = (e) => {
            if (e.keyCode === 27) {
                setIsModalOpen(false)
            }
        }
        window.addEventListener('keydown', close)
        return () => window.removeEventListener('keydown', close)
    }, [])


    return (
        <FullscreenContainer >
            <InviteModal ref={ref}
                as={motion.div}
                initial={{ translateX: "100%" }}
                animate={{ translateX: "0" }}
                exit={{ translateX: "100%" }}
                transition={{ duration: 0.4 }}
            >
                <HeadWrapper>
                    <h1>Change Your Plan</h1>
                    <CloneBtn onClick={() => setIsModalOpen(false)}>
                        <CloseModalIcon />
                    </CloneBtn>
                </HeadWrapper>
                <PlansWrapper>
                    <SubscriptionPlanCard
                        plan="scale_up"
                        handleUpgradeSubscription={handleUpgradeSubscription}
                        currentPlan={subscription}
                        handleContactSales={handleContactSales}
                    />
                    <Separator />
                    <SubscriptionPlanCard
                        plan="enterprise"
                        handleContactSales={handleContactSales}
                    />
                </PlansWrapper>


            </InviteModal>

        </FullscreenContainer>
    )
}

export default ChangePlanModal
const FullscreenContainer = styled.div`
display:flex;
position:fixed;
top:0;
left:0;
width:100%;
height:100%;
background:rgba(0,0,0,0.1);
z-index:3;
`

const expandAnimation = keyframes`
0% { transform:translateX(100%)}

100% {
   transform: translateX(0)
}

`

const InviteModal = styled.div`
position:relative;
display:flex;
flex-direction:column;
background:${lightThemeColors.background};
max-width:750px;
width:100%;
height:100%;
margin-left:auto;
padding:40px 0;

gap:50px;
.control{
  border-radius:5px;
}
`

const CloneBtn = styled.button`
display:flex;
align-items:center;
justify-content:center;
width:fit-content;
border:none;
background:none;
cursor:pointer;
margin-left:auto;
`
const HeadWrapper = styled.div`
display:flex;
width:100%;
gap:10px;
padding:0 40px;
h1{
color:${lightThemeColors.darkColorFaded};
font-size: 25px;
font-weight: 600;
line-height: 37.5px;
}

`


const PlansWrapper = styled.div`
position:relative;
display:flex;
flex-direction:column;
width:100%;
height:100%;
gap:50px;
overflow:auto;
padding:0 40px;
`
const Separator = styled.div`
display:flex;
min-height:1px;
width:100%;
background:${lightThemeColors.darkColor};
opacity:0.1;
`