import React, { useEffect, useReducer, useRef, useState } from "react";
import styled, { keyframes } from "styled-components";
import { lightThemeColors } from "../../assets/styles/colors";
import { ReactComponent as CloseModalIcon } from "../../assets/icons/generic/CloseModalIcon.svg";
import InputFieldWithValidation from "../../components/Generic/InputFieldWithValidation";
import Select from "react-select";
import PrimaryButton from "../../components/Generic/PrimaryButton";
import { isEmailValid, isNameValid } from "../../helper/ValidatorsHelper";
import CustomTextArea from "../Generic/CustomTextArea";
import { motion } from "framer-motion";
import { contactLinkSquaredAPICall } from "../../api/contact/contactService";
import { showGenericError, showSuccessNotification } from "../../helper/Notifications";

const ContactSaleModal = ({ isMoldaOpen, setIsModalOpen, setLoading }) => {

    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [reason, setReason] = useState("");
    const ref = useRef();

    const handleContactSale = () => {
        const data = {
            name: name,
            email: email,
            company: companyName,
            description: reason,
        }
        setLoading(true)
        contactLinkSquaredAPICall(
            data,
            (response) => {
                setLoading(false)
                setIsModalOpen(false)
                showSuccessNotification("Your message has been sent! We’ll get back to you shortly.")
            },
            (error) => {
                setLoading(false)
                showGenericError()
            },

        )
    }
    const isSubmitDisabled = () => {
        if (!isEmailValid(email) ||
            !isNameValid(name) ||
            !isNameValid(companyName) ||
            reason.length < 10
        ) {
            return true
        } else {
            return false
        }
    }
    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            if (setIsModalOpen && ref.current && !ref.current.contains(e.target)) {
                setIsModalOpen(false);
            }
        };
        document.addEventListener("mousedown", checkIfClickedOutside);
        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, [setIsModalOpen]);

    useEffect(() => {
        const close = (e) => {
            if (e.keyCode === 27) {
                setIsModalOpen(false);
            }
        };
        window.addEventListener("keydown", close);
        return () => window.removeEventListener("keydown", close);
    }, []);

    return (
        <FullscreenContainer>
            <InviteModal ref={ref}
                as={motion.div}
                initial={{ translateX: "100%" }}
                animate={{ translateX: "0" }}
                exit={{ translateX: "100%" }}
                transition={{ duration: 0.4 }}
            >
                <Line>



                    <HeadWrapper>
                        <h1>Contact Sales</h1>
                    </HeadWrapper>
                    <CloneBtn
                        onClick={() => setIsModalOpen(false)}
                        style={{ marginLeft: "auto" }}
                    >
                        <CloseModalIcon />
                    </CloneBtn>
                </Line>
                <ColumnGroup>
                    <InputFieldWithValidation
                        title={"Name*"}
                        inputValue={name}
                        placeholder={"First and last name"}
                        noValidation
                        inputName={"name"}
                        handleInputChange={(e) => setName(e.currentTarget.value)}
                    />
                    <InputFieldWithValidation
                        title={"Work email*"}
                        inputValue={email}
                        placeholder={"name@example.com"}
                        type={"email"}
                        noValidation
                        inputName={"email"}
                        handleInputChange={(e) => setEmail(e.currentTarget.value)}
                    />
                    <InputFieldWithValidation
                        title={"Company name*"}
                        placeholder={"Example, Inc."}
                        inputValue={companyName}
                        noValidation
                        inputName={"company_name"}
                        handleInputChange={(e) => setCompanyName(e.currentTarget.value)}
                    />
                    <CustomTextArea
                        title={"How can we help?*"}
                        value={reason}
                        onChange={(e) => setReason(e.currentTarget.value)}
                    >

                    </CustomTextArea>


                </ColumnGroup>
                <Line>
                    <PrimaryButton
                        disabled={isSubmitDisabled()}
                        styled={{ width: "100%", padding: "12px" }}
                        selectOptions
                        text={"Submit"}
                        onClick={() => handleContactSale()}
                    />
                </Line>

            </InviteModal>
        </FullscreenContainer>
    );
};

export default ContactSaleModal;
const FullscreenContainer = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  z-index: 3;
`;
const expandAnimation = keyframes`
0% { transform:translateX(100%)}

100% {
   transform: translateX(0)
}

`;

const InviteModal = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background: ${lightThemeColors.background};
  max-width: 600px;
  width: 100%;
  height: 100%;
  margin-left: auto;
  padding: 40px 0;
//   animation: ${expandAnimation} 400ms linear;
  gap: 50px;
  .control {
    border-radius: 5px;
  }
`;

const CloneBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  border: none;
  background: none;
  cursor: pointer;

`;
const HeadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  h1 {
    color: ${lightThemeColors.darkColor};
    font-size: 25px;
    font-weight: 600;
    line-height: 37.5px;
  }
  p {
    color: ${lightThemeColors.darkColorFaded30};
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }
`;
const ColumnGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
    overflow: auto;
padding:0 40px;
`;
const Subtitle = styled.p`
  color: ${lightThemeColors.darkColorFaded};
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;

`;
const Line = styled.div`
display: flex;
gap:10px;
padding:0 40px;
`