import {
    createContext,
    useState,
    useEffect,
    useContext,
    useCallback,
    useRef,
} from "react";


import { acceptInviteAPICall, changePasswordAPICall, createAccountAPICall, currentUserAPICall, enable2FAAPICall, getOTPQrcodeAPICall, isOtpEnabledAPICall, logoutAPICall, removeUserAPICAll, resetPasswordAPICall, signInAPICall } from "../api/auth/userService";
import LocalStorage from "../helper/LocalStorage";

export const UserContext = createContext({
    user: [],
    userRef: [],
    currentUser: () => { },
    handleSetUser: () => { },
    createUser: () => { },
    loginUser: () => { },
    logoutUser: () => { },
    removeAccount: () => { },
    resetPassword: () => { },
    changePassword: () => { },
    fetchCurrentUser: () => { },
    accpetInvitation: () => { },
    enable2FA: () => { },
    isOtpEnabled: () => { },
    getOTPQrcode: () => { },
});

const UserContextProvider = ({ children, setLoading }) => {
    const [user, setUser] = useState(undefined);

    const userRef = useRef(null);

    const currentUser = () => {
        return LocalStorage.getCurrentUser();
    };

    const handleSetUser = (user) => {
        setUser(user);
        LocalStorage.setCurrentUser(user);
    };

    const loginUser = useCallback(async (email, password, otpCode, onSuccess, onError) => {
        signInAPICall(
            email,
            password,
            otpCode,
            function (response) {
                handleLoggedInUserResponse(response);
                onSuccess(response);
            },
            onError
        );
    });

    const logoutUser = useCallback(async (token, onSuccess, onError) => {
        logoutAPICall(
            token,
            function (response) {
                LocalStorage.logoutUser();
                onSuccess(response);
            },
            onError
        );
    });

    const removeAccount = useCallback(async (email, onSuccess, onError) => {
        removeUserAPICAll(
            email,
            function (response) {
                LocalStorage.logoutUser();
                onSuccess(response);
            },
            onError
        );
    });

    const fetchCurrentUser = useCallback(async (onSuccess, onError) => {
        currentUserAPICall(function (response) {
            handleUserResponse(response);
            onSuccess(response);
        }, onError);
    });

    const createUser = useCallback(
        async (email, password, name, onSuccess, onError) => {
            createAccountAPICall(
                email,
                password,
                name,
                function (response) {
                    handleLoggedInUserResponse(response);
                    onSuccess(response);
                },
                onError
            );
        }
    );

    const accpetInvitation = useCallback(
        async (inviteToken, name, password, onSuccess, onError) => {
            acceptInviteAPICall(
                inviteToken, name, password,
                function (response) {
                    handleLoggedInUserResponse(response);
                    onSuccess(response);
                },
                onError
            );
        }
    );

    const resetPassword = useCallback(async (email, onSuccess, onError) => {
        resetPasswordAPICall(email, onSuccess, onError);
    });

    const changePassword = useCallback(
        async (token, password, onSuccess, onError) => {
            changePasswordAPICall(token, password, onSuccess, onError);
        }
    );

    const enable2FA = useCallback(
        async (enable, otpCode, onSuccess, onError) => {
            enable2FAAPICall(
                enable, otpCode,
                function (response) {
                    onSuccess(response);
                },
                onError
            );
        }
    );

    const isOtpEnabled = useCallback(
        async (email, onSuccess, onError) => {
            isOtpEnabledAPICall(
                email,
                function (response) {
                    onSuccess(response);

                },
                onError
            );
        }
    );

    const getOTPQrcode = useCallback(
        async (onSuccess, onError) => {
            getOTPQrcodeAPICall(
                function (response) {
                    onSuccess(response);
                },
                onError
            );
        }
    );

    const handleLoggedInUserResponse = (response) => {
        LocalStorage.setAuthenthicationToken(response.data.access_token);
        LocalStorage.setRefreshToken(response.data.refresh_token);
        handleUserResponse(response);
    };

    const handleUserResponse = (response) => {
        userRef.current = response.data.user;
        setUser(userRef.current)
        LocalStorage.setCurrentUser(response.data.user);
    };

    return (
        <UserContext.Provider
            value={{
                user,
                userRef,
                createUser,
                loginUser,
                logoutUser,
                removeAccount,
                resetPassword,
                changePassword,
                fetchCurrentUser,
                currentUser,
                handleSetUser,
                accpetInvitation,
                enable2FA,
                isOtpEnabled,
                getOTPQrcode,
            }}
        >
            {children}
        </UserContext.Provider>
    );
};

export const useUserContext = () => useContext(UserContext);

export default UserContextProvider;
