import React from "react";
import styled from "styled-components";
import { lightThemeColors } from "../../assets/styles/colors";

const ShowInitials = ({ name }) => {
  if (name == "Invited") {
    return
  }

  const getInitials = (name) => {
    let initials = "";
    name.split(" ").map((item) => {
      initials = initials + item[0];
    });
    return initials;
  };

  return (
    <Container>
      <p>{getInitials(name)}</p>
    </Container>
  );
};

export default ShowInitials;
const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${lightThemeColors.primaryColor};
  border-radius: 50px;
  height: 32px;
  width: 32px;
  p {
    color: ${lightThemeColors.background};
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
  }
`;
