import React, { useEffect, useReducer, useRef, useState } from "react";
import styled, { keyframes } from "styled-components";
import { lightThemeColors } from "../../assets/styles/colors";
import { ReactComponent as CloseModalIcon } from "../../assets/icons/generic/CloseModalIcon.svg";
import InputFieldWithValidation from "../../components/Generic/InputFieldWithValidation";
import Select from "react-select";
import PrimaryButton from "../../components/Generic/PrimaryButton";
import { isEmailValid } from "../../helper/ValidatorsHelper";
import { motion } from "framer-motion";

const InvitePeopleModal = ({ isMoldaOpen, setIsModalOpen, handleInviteMember }) => {

  const [role, setRole] = useState({ value: "member", label: "Member" });
  const [email, setEmail] = useState("");
  const ref = useRef();

  const selectOptions = [
    { value: "admin", label: "Administrator" },
    { value: "member", label: "Member" },
  ];



  const customStyles = {
    container: (provided) => ({
      ...provided,
      "&:focus-visible": {
        outline: "none",
      },
    }),
    control: (provided) => ({
      ...provided,
      borderRadius: "5px",
      borderColor: lightThemeColors.darkColorFullFaded,
      outline: "none",
      "&:hover": {
        borderColor: "none",
      },
    }),

    singleValue: (provided) => ({
      ...provided,
      fontSize: "16px",
      fontWeight: "500",
      color: lightThemeColors.darkColorFaded,
    }),
  };



  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (setIsModalOpen && ref.current && !ref.current.contains(e.target)) {
        setIsModalOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [setIsModalOpen]);

  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        setIsModalOpen(false);
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, []);

  return (
    <FullscreenContainer>
      <InviteModal ref={ref}
        as={motion.div}
        initial={{ translateX: "100%" }}
        animate={{ translateX: "0" }}
        exit={{ translateX: "100%" }}
        transition={{ duration: 0.4 }}
      >
        <CloneBtn onClick={() => setIsModalOpen(false)}>
          <CloseModalIcon />
        </CloneBtn>
        <HeadWrapper>
          <h1>Add new members</h1>
          <p>
            You can add new members to your project, giving them access to
            specific areas based on their assigned role. They will receive an
            email to create an account once invited.
          </p>
        </HeadWrapper>
        <ColumnGroup>
          <InputFieldWithValidation
            title={"E-mail"}
            type={"email"}
            placeholder={"Enter the email"}
            noValidation
            inputName={"email"}
            handleInputChange={(e) => setEmail(e.currentTarget.value)}
          />

          <Subtitle>Role</Subtitle>
          <Select
            styles={customStyles}
            value={role}
            options={selectOptions}
            onChange={(e) => setRole(e)}
          />
        </ColumnGroup>
        <PrimaryButton
          disabled={!isEmailValid(email)}
          styled={{ width: "100%", padding: "12px" }}
          selectOptions
          text={"Send Invitation"}
          onClick={() => handleInviteMember(email, role.value)}
        />
      </InviteModal>
    </FullscreenContainer>
  );
};

export default InvitePeopleModal;
const FullscreenContainer = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  z-index: 3;
`;

const InviteModal = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background: ${lightThemeColors.background};
  max-width: 600px;
  width: 100%;
  height: 100%;
  margin-left: auto;
  padding: 40px;
  gap: 30px;

  .control {
    border-radius: 5px;
  }
`;

const CloneBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  border: none;
  background: none;
  cursor: pointer;
  position: absolute;
  top: 30px;
  right: 30px;
`;
const HeadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  h1 {
    color: ${lightThemeColors.darkColor};
    font-size: 25px;
    font-weight: 600;
    line-height: 37.5px;
  }
  p {
    color: ${lightThemeColors.darkColorFaded30};
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }
`;
const ColumnGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;
const Subtitle = styled.p`
  color: ${lightThemeColors.darkColorFaded};
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;

`;