import React, { useRef, useState } from "react";
import styled from "styled-components";
import { lightThemeColors } from "../../assets/styles/colors";
import CustomInput from "./CustomInput";
import CustomBigSubmitButton from "./CustomBigSubmitButton";
import { useUserContext } from "../../context/useUserContext";
import { isPasswordValid, passwordsMatch } from "../../helper/ValidatorsHelper";
import { showGenericError } from "../../helper/Notifications";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ReactComponent as HideIcon } from "../../assets/icons/generic/eyeClosed.svg"
import { ReactComponent as ShowIcon } from "../../assets/icons/generic/eyeOpen.svg"
import ReactPasswordChecklist from "react-password-checklist";

const NewPasswordCard = ({ setIsLoading }) => {
  const { changePassword } = useUserContext();
  const navigate = useNavigate();
  const initialValues = {
    password: "",
    password_confirm: "",
  };

  const [inputValues, setInputvalues] = useState(initialValues);
  const [searchParams] = useSearchParams();
  const resetTokenRef = useRef();

  const [passwordRulesValid, setPasswordRulesValid] = useState(false)
  const [showConditions, setShowConditions] = useState(false);
  const [passFiedlType, setPassFieldType] = useState("password")
  const [passConfirmFiedlType, setPassConfirmFieldType] = useState("password")


  resetTokenRef.current = searchParams.get("token");

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputvalues({
      ...inputValues,
      [name]: value,
    });
  };

  const handleResetBtn = () => {

    if (!passwordRulesValid) {
      setShowConditions(true);
      return
    }

    if (
      passwordRulesValid &&
      resetTokenRef.current
    ) {
      setIsLoading(true);
      changePassword(
        resetTokenRef.current,
        inputValues.password,
        () => {
          setIsLoading(false);
          navigate("/login", { replace: true });
        },
        (error) => {
          showGenericError();
          setIsLoading(false);
        }
      );
    }
  };
  const handleShowPassword = () => {
    if (passFiedlType === "password") {
      setPassFieldType("text")
    } else {
      setPassFieldType("password")
    }

  }

  const handleShowPasswordConf = () => {
    if (passConfirmFiedlType === "password") {
      setPassConfirmFieldType("text")
    } else {
      setPassConfirmFieldType("password")
    }

  }

  const handleCreateAccountBtn = () => {
    navigate("/register");
  };


  return (
    <Card>
      <TitleContainer>
        <h2>New Password</h2>
      </TitleContainer>
      <Subtitle>
        <p>Pick a new password to use for your login credentials.</p>
      </Subtitle>
      <InputsWrapper>
        <CustomInput
          type={passFiedlType}
          label={"New Password"}
          placeholder="Enter your password"
          name={"password"}
          value={inputValues.password}
          onChange={handleInputChange}
          customButton={
            <ShowPasswordBtn onClick={() => handleShowPassword()}>
              {passFiedlType == "password" ?
                <HideIcon />
                :
                <ShowIcon />
              }
            </ShowPasswordBtn>
          }
        />

        <CustomInput
          type={passConfirmFiedlType}
          label={"Confirm Password"}
          placeholder="Confirm your password"
          name={"password_confirm"}
          value={inputValues.password_confirm}
          onChange={handleInputChange}
          customButton={
            <ShowPasswordBtn onClick={() => handleShowPasswordConf()}>
              {passConfirmFiedlType == "password" ?
                <HideIcon />
                :
                <ShowIcon />
              }
            </ShowPasswordBtn>
          }
        />
        <div style={showConditions ? { display: "flex" } : { display: "none" }}>
          <ReactPasswordChecklist
            iconSize={14}
            minLength={8}
            value={inputValues.password}
            valueAgain={inputValues.password_confirm}
            rules={["minLength", "specialChar", "capital", "lowercase", "number", "match"]}
            onChange={(isValid) => { setPasswordRulesValid(isValid) }}
            className="password-rules"
          />

        </div>
      </InputsWrapper>

      <SbmitWrapper>
        <CustomBigSubmitButton
          text={"Change password"}
          onClick={handleResetBtn}
        // disabled={!(isPasswordValid(inputValues.password) && isPasswordValid(inputValues.password_confirm))}
        />
        {/* <p>Not a member? <span onClick={() => handleCreateAccountBtn()}>Create an account</span></p> */}
      </SbmitWrapper>
    </Card>
  );
};

export default NewPasswordCard;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  padding: 50px;
  align-items: center;
  min-width: 530px;
  background: ${lightThemeColors.background};
  min-height: 550px;
  gap: 35px;
  border-radius: 10px;
  margin-left: auto;
  margin-right: auto;
  z-index:1;
      @media(max-width:650px){
  width:100%;
  min-width: unset;
  }
`;
const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 20px;
  width: 100%;
  h1 {
    color: ${lightThemeColors.darkColorFaded};
    font-weight: 900;
    font-size: 25px;
  }
  h2 {
    color: ${lightThemeColors.darkColorFaded};
    font-weight: 700;
    font-size: 25px;
    line-height: 38px;
  }
`;
const InputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
`;

const ForgotPwd = styled.div`
  width: 100%;
  margin-top: -25px;
  p {
    width: fit-content;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    color: ${lightThemeColors.primaryColor};
    cursor: pointer;
  }
`;
const SbmitWrapper = styled.div`
  margin-top: auto;

  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  p {
    color: ${lightThemeColors.darkColorFaded};
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
  }
  span {
    margin-left: 4px;
    font-weight: 700;
    color: ${lightThemeColors.primaryColor};
    cursor: pointer;
  }
`;

const Subtitle = styled.div`
  width: 100%;
  display: flex;
  p {
    color: ${lightThemeColors.darkColorFaded};
    opacity: 0.3;
    font-family: Lato;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.01em;
    text-align: center;
  }
`;
const ShowPasswordBtn = styled.button`
border:none;
background:none;
cursor:pointer;
display:flex;
align-items:center;
jutify-content:center;
svg{
width:16px;
height:16px;
color:${lightThemeColors.inputSubtitleColor}
}
`