import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { lightThemeColors } from "../../assets/styles/colors";
import TestProdHeader from "../../components/Generic/TestProdHeader";
import { useOutletContext } from "react-router-dom";
import RedirectConfig from "./RedirectConfig";
import DomainConfig from "./DomainConfig";
import SocialMediaConfig from "./SocialMediaConfig";
import { useConfigurationContext } from "../../context/useConfigurationContext";
import { useInstanceContext } from "../../context/useInstanceContext";
import { showGenericError } from "../../helper/Notifications";

const ConfigurationPage = () => {
  const { projectType, setProjectType, setLoading } = useOutletContext();
  const { getProjectDomains, getProjectRedirects } = useConfigurationContext();
  const { selectedInstance, selectedProject, getProjectConfiguration } =
    useInstanceContext();

  const handleGetProjectConfig = () => {
    setLoading(true);
    getProjectConfiguration(
      selectedInstance.id,
      (response) => {
        setLoading(false);
      },
      () => {
        showGenericError();
        setLoading(false);
      }
    );
  };

  const handleGetProjectRedirects = () => {
    setLoading(true);
    getProjectRedirects(
      selectedProject.id,
      (response) => {
        setLoading(false);
      },
      () => {
        showGenericError();
        setLoading(false);
      }
    );
  };

  const handleGetProjectDomains = () => {
    setLoading(true);
    getProjectDomains(
      selectedProject.id,
      (response) => {

        setLoading(false);
      },
      () => {
        showGenericError();
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    if (!selectedProject) {
      return;
    }

    // handleGetProjectConfig();
    handleGetProjectRedirects();
    handleGetProjectDomains();
  }, [selectedProject]);

  return (
    <Container>
      <TestProdHeader
        title={"Configuration"}
        option={projectType}
        setOption={setProjectType}
      />
      <ConfigList id={"configListId"}>
        <RedirectConfig />
        <DomainConfig />
        <SocialMediaConfig />
      </ConfigList>
    </Container>
  );
};

export default ConfigurationPage;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  overflow:hidden;
  h2 {
    width: 100%;
  }
  gap: 0px;
`;

const ConfigList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow: auto;
  padding: 0 50px;
  padding-bottom: 30px;
  position: relative;
`;
