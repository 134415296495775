import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { lightThemeColors } from "../../assets/styles/colors";
import ConfigurationSectionHeader from "../configurationComponents/ConfigurationSectionHeader";
import InputFieldWithValidation from "../../components/Generic/InputFieldWithValidation";
import TagElement from "../Generic/TagElement";
import { useExpanded } from "../../hooks/useExpanded";
import { cloneDeep } from "lodash";
import SecondaryButton from "../Generic/SecondaryButton";
import { ReactComponent as PlusIcon } from "../../assets/icons/generic/plus.svg";

const DetailsNewLink = ({ linkName, setLinkName, noLabel,
  tagInput,
  setTagInput,
  tagsList,
  setTagsList,
  disabledActions
}) => {
  const [expanded, expand] = useExpanded(true);


  const handleAddTag = (tag) => {
    if (tagInput == "") {
      return;
    }
    setTagsList((prev) => {
      return [...prev, tag];
    });
    setTagInput("");
  };

  const handleRemoveTag = (index) => {
    let list = cloneDeep(tagsList);
    list.splice(index, 1);
    setTagsList(list);
  };

  return (
    <Container>
      <ConfigurationSectionHeader
        title={"Details"}
        handleExpand={expand}
        expanded={expanded}
        requiredLabel={!noLabel}
      />

      {expanded && (
        <ContentContainer>
          <SubcategoryContent>
            <ColumnGroup>
              <InputFieldWithValidation
                title={"Link Name"}
                placeholder={"Enter link name..."}
                inputValue={linkName}
                handleInputChange={(e) => setLinkName(e.currentTarget.value)}
                noValidation
                readOnly={disabledActions}
              />
              <InfoText>
                This link name is for bookkeeping purposes only and will not be
                shown to end users.
              </InfoText>
            </ColumnGroup>

            <ColumnGroup>
              <InputFieldWithValidation
                title={"Tags"}
                placeholder={"Enter a new tag name..."}
                noValidation
                handleInputChange={(e) => setTagInput(e.currentTarget.value)}
                inputValue={tagInput}
                readOnly={disabledActions}
                customButton={
                  !disabledActions &&
                  <SecondaryButton
                    disabled={disabledActions}
                    icon={<PlusIcon />}
                    text={"Add tag"}
                    onClick={() => handleAddTag(tagInput)}
                  />
                }
              />
              <InfoText>
                Tags label links, helping you categorize and find them easily.
              </InfoText>

              <Line
                style={{ gap: "10px", flexWrap: "wrap" }}
              >
                {tagsList.map((item, index) => (
                  <TagElement
                    key={index}
                    tagName={item}
                    handleRemove={() => handleRemoveTag(index)}
                    disabled={disabledActions}
                  />
                ))}
              </Line>
            </ColumnGroup>
          </SubcategoryContent>
        </ContentContainer>
      )}
    </Container>
  );
};

export default DetailsNewLink;

const InfoText = styled.p`
  color: ${lightThemeColors.inputSubtitleColor};
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: ${lightThemeColors.background};
  width: 100%;
  gap: 10px;
`;

const ContentContainer = styled.div`
  display: flex;
  overflow: hidden;
  flex-wrap: wrap;
  padding-bottom: 20px;
  row-gap: 20px;
`;
const SubcategoryContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
  max-width: 620px;
`;

const ColumnGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
`;

const Line = styled.div`
  display: flex;
  align-items: center;
  gap: 0px;
`;