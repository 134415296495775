import { DELETE, POST } from "../API";
let notificationsSource;

export const getNotificationsAPICall = (
    project_id,
    data,
    onSuccess,
    onError
) => {

    notificationsSource = POST(
        process.env.REACT_APP_API_PATH + `/notifications/${project_id}/list`,
        data,
        onSuccess,
        onError,
        true,
        notificationsSource
    );
};

export const createNotificationsAPICall = (
    data,
    onSuccess,
    onError
) => {
    POST(
        process.env.REACT_APP_API_PATH + `/notifications/create`,
        data,
        onSuccess,
        onError,
    );
};

export const ArchiveNotificationsAPICall = (
    project_id,
    notification_id,
    onSuccess,
    onError
) => {
    DELETE(
        process.env.REACT_APP_API_PATH + `/notifications/${project_id}/archive/${notification_id}`,
        onSuccess,
        onError,
    );
};