import styled from "styled-components";
import { ALL_USERS, BUTTON, CENTER, END, FIT, FULL, IMAGE, MEDIUM, NEW_USERS, PILL, ROUND, SEPARATOR, SMALL, SQUARE, START, TEXT, VIDEO } from "../constants/OptionsConstants";
import { lightThemeColors } from "../assets/styles/colors";
import { v4 as uuidv4 } from "uuid";


export const parseDeliverTo = (deliverTo) => {
    switch (deliverTo) {
        case ALL_USERS:
            return "ALL existing users"
        case NEW_USERS:
            return "Future users"
        default:
            break;
    }
}


export const handleSize = (size) => {

    switch (size) {
        case FIT:
            return "fit-content"
        case MEDIUM:
            return "50%"
        case FULL:
            return "100%"

        default:
            break;
    }
}

export const handleImageSize = (size) => {

    switch (size) {
        case SMALL:
            return "30%"
        case MEDIUM:
            return "50%"
        case FULL:
            return "100%"

        default:
            break;
    }
}

export const handleVideoSize = (size) => {

    switch (size) {
        case SMALL:
            return "30%"
        case MEDIUM:
            return "50%"
        case FULL:
            return "100%"

        default:
            break;
    }
}

export const handlePosition = (position) => {
    switch (position) {
        case START:
            return {
                marginLeft: "unset",
                marginRight: "auto"
            }
        case CENTER:
            return {
                marginLeft: "auto",
                marginRight: "auto"
            }
        case END:
            return {
                marginLeft: "auto",
                marginRight: "unset"
            }

        default:
            break;
    }
}

export const handleForm = (form) => {
    switch (form) {
        case SQUARE:
            return "0px"
        case ROUND:
            return "8px"
        case PILL:
            return "50px"

        default:
            break;
    }
}

export const handleSeparatorForm = (form) => {
    switch (form) {
        case SQUARE:
            return "0px"
        case ROUND:
            return "25px"

        default:
            break;
    }
}



const CustomMenuButton = styled.div`
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(43, 58, 84, 0.2);
  padding: 10px;
  background-color: #fff;
  color: ${lightThemeColors.darkColor};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 5px;
  border: none;
  font-size: 18px;
  width: 100%;
  cursor: pointer;
  ${(props) =>
        props.selected
            ? `
    background:${lightThemeColors.darkColor};
    color:${lightThemeColors.background};
    `
            : ""}
`;

export const componentsList = [
    {
        id: uuidv4(),
        name: "text-btn",
        type: TEXT,
        content: <CustomMenuButton>{TEXT}</CustomMenuButton>,
    },
    {
        id: uuidv4(),
        name: "button-btn",
        type: BUTTON,
        content: <CustomMenuButton>{BUTTON}</CustomMenuButton>,
    },
    {
        id: uuidv4(),
        name: "image-btn",
        type: IMAGE,
        content: <CustomMenuButton>{IMAGE}</CustomMenuButton>,
    },
    {
        id: uuidv4(),
        name: "video-btn",
        type: VIDEO,
        content: <CustomMenuButton>{VIDEO}</CustomMenuButton>,
    },
    {
        id: uuidv4(),
        name: "separator-btn",
        type: SEPARATOR,
        content: <CustomMenuButton>{SEPARATOR}</CustomMenuButton>,
    },
];
