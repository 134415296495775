import React from 'react'
import styled from 'styled-components'
import { lightThemeColors } from '../../assets/styles/colors'
import { ReactComponent as RemoveIcon } from '../../assets/icons/generic/removeIcon.svg'

const DomainLongItem = ({ item, handleRemove, textWrapperStyle, container }) => {
    return (
        <Container style={container}>
            <TextWrapper style={textWrapperStyle}>
                <p> {item}</p>
            </TextWrapper>
            <RemoveBtn onClick={() => handleRemove(item)}>
                <RemoveIcon />
            </RemoveBtn>
        </Container>
    )
}

export default DomainLongItem

const Container = styled.div`
display:flex;
align-items:center;
gap:20px;
width:100%;
`
const TextWrapper = styled.div`
width:100%;
display:Flex;
align-items:center;
padding:8px 10px;
background:${lightThemeColors.darkColorFullFaded};
border-radius:5px;
p{
text-overflow:ellipsis;
overflow:hidden;
    color:${lightThemeColors.darkColorFaded};
font-size: 16px;
font-weight: 500;
line-height: 24px;
text-align: left;

}
`
const RemoveBtn = styled.button`
display:flex;
align-items:center;
border:none;
cursor:pointer;
background:none;
`