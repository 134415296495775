import React from 'react';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';

const CodeBlock = () => {
    const codeString = `
function sayHello() {
    console.log("Hello, world!"); // Highlight this line
}

sayHello();
    `;

    const getHighlightedCode = () => {
        return codeString.split('\n').map((line, index) => {
            const highlightedLine = line
                .replace(/console/g, '<span data-tip="JavaScript console" class="tooltip">console</span>')
                .replace(/function/g, '<span data-tip="Defines a function" class="tooltip">function</span>')
                .replace(/(\/\/ Highlight this line)/g, '<span class="comment">$1</span>');

            return <div key={index} dangerouslySetInnerHTML={{ __html: highlightedLine }} />;
        });
    };

    return (
        <Container>


            <div className="code-container">
                {getHighlightedCode()}
                {/* <ReactTooltip effect="solid" /> */}
            </div>
        </Container>
    );
};

export default CodeBlock;

const Container = styled.div`
.code-container {
    background-color: #f5f5f5;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    overflow: auto;
    font-family: monospace;
    white-space: pre;
}
.tooltip {
    color: #3498db; /* Blue color */
    cursor: pointer;
}
.comment {
    color: blue !important;
}
`